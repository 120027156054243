import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SignActionField } from './signactionfield';
import { PassphraseDialog } from './confirmdialog';
import { validatePassPhrase } from './validators';
import { setBackupDone, removeFromAllUsers } from '../login/storage';


import { BalancePriceIGNIS } from '../exchange/pricetracker';
import { NQTDIVIDER } from '../common/constants';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);


class BackupPassphrase extends Component {
  constructor(props){
    super(props);
    this.state={
      dialogOpen:false,
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''}     
    }
    this.showPassphrase = this.showPassphrase.bind(this);
    this.handleConfirmed = this.handleConfirmed.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
  }

  handleConfirmed(){
    setBackupDone(this.props.user.name);
    this.setState({dialogOpen:false})
  }
  
  handleToggle(){
    this.setState({dialogOpen:false})
  }

  handlePassphraseChange(event){
    let value = event;
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus,this.props.user.accountRs);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }

  showPassphrase(){
    console.log("showpassphrase");
    console.log(this.state);
    if (this.state.passPhraseStatus.invalid === false){
      this.setState({dialogOpen:true})
      //console.log(this.state.passPhrase);
    }    
  }

  render (){
    return (
      <div>
        <form onSubmit={(event)=>{event.preventDefault();this.showPassphrase()}}>
          <SignActionField  
                    user={this.props.user}
                    action={this.setDialogOpen}
                    label="Export Passphrase"
                    handlePassphraseChange={this.handlePassphraseChange}
          />
        </form>
        <PassphraseDialog open={this.state.dialogOpen}
                  passphrase={this.state.passPhrase}
                  handleConfirmed={this.handleConfirmed}
                  handleToggle={this.handleToggle}
        />
      </div>
    )
  }
    
}


class DeleteUser extends Component {
  constructor(props){
    super(props);
    this.state={
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''}     
    }
    this.deleteUser = this.deleteUser.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
  }

  handlePassphraseChange(event){
    let value = event;
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus,this.props.user.accountRs);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }

  deleteUser(){
    console.log("deleteuser");
    console.log(this.state);
    if (this.state.passPhraseStatus.invalid === false){
      //deeeeeleeeeeteee
      removeFromAllUsers(this.props.user.name);
      this.props.history.push("/logout");
    }    
  }

  render (){
    return (
      <div>
        <form onSubmit={(event)=>{event.preventDefault();this.deleteUser()}}>
          <SignActionField  
                    user={this.props.user}
                    action={this.setDialogOpen}
                    label="Delete Account"
                    handlePassphraseChange={this.handlePassphraseChange}
          />
        </form>
      </div>
    )
  }
}


export const UserDisplay = (props) => {

  const ignisAvailableBalance = Math.min(props.wallet.balanceNQT/NQTDIVIDER,props.wallet.unconfirmedBalanceNQT/NQTDIVIDER);

  return(
    <div style={{textAlign:"center", width:"90%", display:"inline-block",   justifyContent:'center', alignItems:'center', marginTop: 5,marginBottom: 5}} >

        <Grid container spacing={8}
            className="boxed"
            justify="center"
            alignItems="flex-start"
            direction="row"
            style={{marginTop: 5, marginBottom: 5, border:'1px solid', borderColor:'#ffffff10', backgroundColor: '#1D1D1D', boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
            <Grid item>

              <Grid container spacing={12}
                    justify="center"
                    alignItems="center"
                    direction="column"
                >
                  <Grid item style={{maxWidth:"400px",marginTop: 10}}>
                  <Typography variant="h5">DISCLAIMER</Typography>
                    <Typography> &nbsp;</Typography>
                    <Typography component="p">This is a blockchain based game and your user and password details are not stored on any Tarasca DAO server.</Typography>
                    <Typography component="p">Your user information is stored on the device you're using at the moment.</Typography>
                    <Typography component="p">If you ever lose this device, your cards and funds may be lost.</Typography>
                    <Typography component="p">If others use this device, this is a potential security risk.</Typography>
                    <Typography> &nbsp;</Typography>         <ColoredLine color="white" /> <Typography> &nbsp;</Typography>
                  	<Typography variant="h5">USER INFO</Typography>
            	    	<Typography> &nbsp;</Typography>
	           	      <Typography >User • {props.user.name}</Typography>
                     <Typography> &nbsp;</Typography>
             	 	    <Typography >Account • <span translate="no">{props.user.accountRs}</span></Typography>
                      <Typography> &nbsp;</Typography>
                		<Typography >IGNIS Balance • {ignisAvailableBalance} <BalancePriceIGNIS totalIgnis={ignisAvailableBalance} /></Typography>
                    <Typography> &nbsp;</Typography>         <ColoredLine color="white" /> <Typography> &nbsp;</Typography>
                   {/* 
                   <Typography variant="h5">Account Status</Typography>
            	    	<Typography> &nbsp;</Typography>
	           	      <Typography>
                       <PropertiesDisplay {...props}/>
                    </Typography>
  */}
                </Grid>

              </Grid>
            </Grid>
            <Grid item style={{maxWidth:"400px", marginBottom: 10}}>
                    <Typography> &nbsp;</Typography>       
                    <Typography variant="h5">PASSPHRASE BACKUP</Typography>
                    <Typography> &nbsp;</Typography>
                    <Typography>
                    Risk of loosing your funds and cards: You store your pass phrase on device. You should export the passphrase and store it somewhere safe.
                    </Typography>
                    <Typography>
                    The passphrase is stored encrypted, however you shouldnt use the game wallet for significant funds. If you ever give this device to somebody else, you should delete your information from it.
                    </Typography>
                    <BackupPassphrase {...props}/>
                    <Typography> &nbsp;</Typography>  


                    <ColoredLine color="white" /> 
                    <Typography> &nbsp;</Typography>
                    <Typography variant="h5">DELETE ACCOUNT FROM DEVICE</Typography>
                    <Typography> &nbsp;</Typography>
                    <Typography>
                      This deletes stored information from this device. Your account remains available on the network, use Backup Passphrase to save your private key for later use.
                    </Typography>
                    <DeleteUser {...props}/>
            </Grid>

        </Grid>
      </div>
  )
}