import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import  {CardLink} from '../common/common';
import {getIgnisBalance, getAccountAssets, getAskOrders, getBidOrders} from '../common/ardorinterface';
import { REFERRALASSET } from '../common/constants';
import { SubmitRefs } from '../referrals/submitrefs';
import {OrderBook} from '../exchange/orderbook';
import {AccountOrderBook} from '../exchange/accountorderbook';


import { GoLink } from "react-icons/go";

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);

class RefOrderBooks extends Component {
    constructor(props){
      super(props);
      this.state = {
        wallet: undefined,
        askOrders: undefined,
        bidOrders: undefined
      }
      this.refresh = this.refresh.bind(this);
    }
  
    refresh(){
      var self = this;
      getAskOrders(this.props.nodeurl,REFERRALASSET)
      .then((response) => {
        self.setState({askOrders:response.askOrders});
      });
      getBidOrders(this.props.nodeurl,REFERRALASSET)
      .then((response) => {
        self.setState({bidOrders:response.bidOrders});
      });
      
      getIgnisBalance(self.props.nodeurl,self.props.user.accountRs)
      .then(function(response){
          self.setState({wallet:response})
      });
    }
  
    componentDidMount(){
      this.refresh();
      this.timer = setInterval(this.refresh,10000);
    }
  
    componentWillUnmount(){
      clearInterval(this.timer);
    }
  
    render(){
      return (
        <Grid container 
                spacing={16}
                justify="center"
                alignItems="flex-start"
                style={{marginTop:10}}
          >
            <Grid item>
              <Typography variant="display1">Asks</Typography>
              <OrderBook orders={this.state.askOrders}/>
            </Grid>
            <Grid item>
              <Typography variant="display1">Bids</Typography>
              <OrderBook orders={this.state.bidOrders}/>
            </Grid>
        </Grid>
      );
    }
  }

/*
class RefMarket extends Component {
    constructor(props){
        super(props);
        this.state={
            askOrders:[],
            bidOrders:[]
        }
        this.refresh = this.refresh.bind(this);
    }

    refresh(){

    }

    componentDidMount() {
        console.log("RefMarket: start refresh");
        this.refresh()
        this.timer = setInterval(this.refresh, 12000)
      }
    
      componentWillUnmount(){
        console.log("RefMarket: stop refresh");
        clearInterval(this.timer);
      }
    
      render(){
        return(null);
      }
}
*/

export function RefBalance(props) {
        //let quantity = props.refs.quantityQNT ? props.refs.quantityQNT : 0;
        return(
         
            <Typography variant="overline" align="left">You own {props.refs.quantityQNT} REF</Typography>              
        )
}



export class TradeReferrals extends Component {
    constructor(props){
        super(props);
        this.state={
            refs:{quantityQNT:0,unconfirmedQuantityQNT:0}
        }
        this.refresh = this.refresh.bind(this);
    }

    refresh(){
        var self = this;
        getAccountAssets(self.props.nodeurl,self.props.user.accountRs,REFERRALASSET)
        .then(function(response){
          if (Object.keys(response).length===0) {//empty object -> asset not in account
            self.setState({refs:{quantityQNT:0,unconfirmedQuantityQNT:0}});
          }
          else {
              self.setState({
                  refs:{
                      quantityQNT:response.quantityQNT,
                      nconfirmedBalanceNQT:response.unconfirmedQuantityQNT
                    }
                });
          }
        });
    }

    componentDidMount() {
        this.refresh()
        console.log("TradeReferrals: start refresh");
        this.timer = setInterval(this.refresh, 12000)
      }
    
      componentWillUnmount(){
        console.log("TradeReferrals: stop refresh");
        clearInterval(this.timer);
      }

    render(){
    //console.log(this.props);
    const noRefs = this.state.refs.quantityQNT===0;
    return(
        <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:40}}>
         
        <Grid>
            <Grid item>
            <Typography variant="h3">REFERRALS</Typography>
            <ColoredLine color="white"/>

            <Typography variant="overline">Invite players through the referral system and you will receive 10% of all spending by that player during Season 1.</Typography>
            <Typography variant="overline">For more information please read the <a href="https://www.mythicalbeings.io/faq" target="_blank" rel="noreferrer"> FAQ</a>.</Typography>
            &nbsp;
          </Grid>
           </Grid>


           <Grid container spacing={24}
            className="boxed"
            justify="center"
            alignItems="center"
            direction="column"
            style={{marginTop: 5, marginBottom: 5, border:'1px solid', borderColor:'#ffffff10', backgroundColor: '#1D1D1D', boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
          
                <Grid item xs={12}>
                  
                    <Typography variant="h4">Invite a new player</Typography><br/>
                    <Typography variant="h6">and activate your referral payments</Typography>
                   
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" align="left">
                      <ol>
                        <li>Get a REF token.</li>
                        <li>Enter the account address to be invited below.</li>
                        <li>Activate your referral provision by submitting the form.</li>
                      </ol>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <GoLink style={{fontSize:'24', color:'white'}}/>
                    <RefBalance refs={this.state.refs}/>
                </Grid>
                <Grid item xs={12}>
                    <SubmitRefs {...this.props} {...this.state} />
                </Grid>
            </Grid>
            &nbsp;
            <Grid container spacing={8}
            className="boxed"
            justify="center"
            alignItems="center"
            direction="column"
            style={{marginTop: 5, marginBottom: 5, border:'1px solid', borderColor:'#ffffff10', backgroundColor: '#1D1D1D', boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
            
                <Grid item>
                &nbsp;
                    <Typography variant="h4">REF Market</Typography>
                &nbsp;
                </Grid>

                <Grid item>
                    <Grid container 
                        justify="flex-start"
                        alignItems="flex-start"
                        direction="row"
                        spacing={24}>
                            <Grid item xs={6}>
                                <Button variant="outlined" size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '12px', marginTop: '3px', maxWidth: '90px', minWidth: '90px', maxHeight: '35px', minHeight: '35px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/refs/bid/"+REFERRALASSET}><Typography>Buy</Typography></Link></Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined" size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '12px', marginTop: '3px', maxWidth: '90px', minWidth: '90px', maxHeight: '35px', minHeight: '35px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/refs/ask/"+REFERRALASSET}><Typography>Sell</Typography></Link></Button>
                            </Grid>                        
                    </Grid>
                    <Grid item>
                     
                        <RefOrderBooks {...this.props}/>    
                    </Grid>   
                </Grid>
                </Grid> 
            &nbsp;
            <Grid container spacing={8}
            className="boxed"
            justify="center"
            alignItems="center"
            direction="column"
            style={{marginTop: 5, marginBottom: 5, border:'1px solid', borderColor:'#ffffff10', backgroundColor: '#1D1D1D', boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
            
                <Grid item>
                    <Typography variant="h4">Your Orders</Typography>
                </Grid>     
                <Grid item>
                    <AccountOrderBook cards={[{name:"REF",asset:REFERRALASSET}]} {...this.props} collectionAssets={this.props.collectionCards}/>
                </Grid>                
            </Grid> 
            &nbsp;
            <Grid container spacing={8}
            className="boxed"
            justify="center"
            alignItems="flex-start"
            direction="row"
            style={{marginTop: 5, marginBottom: 5, border:'1px solid', borderColor:'#ffffff10', backgroundColor: '#1D1D1D', boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
            
                <Grid item>
                    <Typography variant="h4">Send REF</Typography>
                    &nbsp;
                    <Typography variant="body1">You can send REF to friends who can then invite others themselves. (This way the recipient is not invited and you send the token for free).</Typography>
                </Grid>
                
                <Grid item>
                  &nbsp;
                    <RefBalance refs={this.state.refs}/>
              
                    <Button variant="outlined" size="small" disabled={noRefs} style={{justifyContent: 'center', alignItems:'center', marginBottom: '12px', marginTop: '12px', maxWidth: '90px', minWidth: '90px', maxHeight: '35px', minHeight: '35px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><CardLink to={"/game/sendrefs"} disabled={noRefs}><Typography>Send REF</Typography></CardLink></Button> 
                </Grid>
            </Grid>
        </div>
    );
    }
}