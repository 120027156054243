import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {Link, withRouter} from 'react-router-dom';

import {initUser} from './storage';
import {secretPhraseToAccountId} from 'ardorjs';
import {generatePassphrase} from './generatePassphrase';
import { validatePin, validateUsername } from '../common/validators';


import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


class NewUser_ extends Component {
  constructor(props) {
    super(props);
    
    this.state={
      name:"",
      nameStatus:{invalid:false,error:""},
      accountRs:"",
      accountRsStatus:{invalid:false,error:""},
      passPhrase:"",
      usePin:true,
      Pin:"",
      PinStatus:{invalid:false,error:""},
      generateStatus:{invalid:false,error:""},
      hidden: true,
      formValid:false
    }
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  generateAccount = () => {
    let phrase = generatePassphrase();
    let accountRs = secretPhraseToAccountId(phrase,false);
    this.setState({passPhrase:phrase, accountRs:accountRs, generateStatus:{invalid:false,error:""}});
  }

  validateForm(){
    const PinValid = !this.state.PinStatus.invalid;
    const nameValid = !this.state.nameStatus.invalid;
    const ardorValid = !this.state.generateStatus.invalid;
    this.setState({formValid:(PinValid & nameValid & ardorValid)});
  }

  onClick = () => {
    // function will only be called if all fields are valid. 
    // Hence only checking for usePin == true to start encryption.
    let nameStatus = validateUsername(this.state.name,this.state.nameStatus,this.props.userList);
    let pinStatus = validatePin(this.state.Pin,this.state.PinStatus);
    let generateStatus = ((this.state.accountRs.length === 0) | (this.state.passPhrase.length===0)) 
                ? {invalid:true,error:"Please generate an Ardor wallet."}
                : {invalid:false,error:""};

    if (nameStatus.invalid | (this.state.usePin & pinStatus.invalid) | generateStatus.invalid){
      this.setState({nameStatus:nameStatus, PinStatus:pinStatus, generateStatus:generateStatus});
    }
    else {
      // let user = {
      //   name:this.state.name, 
      //   accountRs:this.state.accountRs,
      //   usePin:this.state.usePin,
      //   token: (this.state.usePin) 
      //               ? encrypt(this.state.passPhrase,this.state.Pin) : ""
      // } 
      let user = initUser(this.state.name,this.state.accountRs,this.state.usePin,this.state.passPhrase,this.state.Pin);
      this.props.registerNewUser(user);
      this.props.history.push("/login");
    }
  }
    


  
  render(){
    var self = this;
    //console.log(this.props);
    return (
      <form>
        <div style={{textAlign:"center", display:"inline-block"}}>
        <Grid container
            justify="center"
            alignItems="stretch"
            direction="column"
            spacing={24}
          >
            <Grid item>
              <Typography style={{textAlign:"right"}}><Link className="link" to="/login">Back to Login</Link>{' '} 
                  or <Link className="link" to="/login/returning">Restore User</Link></Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">Create a new Account</Typography><br></br>
              <Typography variant="caption">Account name and password are not saved on any server, only on your own device.</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-full-width"
                label="Name"
                placeholder="Enter your name"
                helperText="used to identify you on this device"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e)=>{
                  let value = e.target.value;
                  self.setState({name:value}, ()=>{
                    self.setState({nameStatus:validateUsername(value,self.state.nameStatus,self.props.userList)}, 
                    self.validateForm)
                })}}
                value={this.state.name}
                error={this.state.nameStatus.invalid}
              />
            </Grid>
            <Grid item>
              <Button fullWidth variant="outlined" onClick={() => this.generateAccount()}>
                  Generate Wallet
              </Button>
            </Grid>
            <Grid item>
              <TextField disabled
                id="account"
                label="Account"
                helperText="Ardor Account ID"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  readOnly: true,

                }}
                value={this.state.accountRs}
                error={this.state.generateStatus.invalid}
              />

              <TextField disabled multiline fullWidth
                id="passphrase"
                label=""
                rows="4"
                defaultValue="Your Passphrase"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  readOnly: true,
                }}
                value={this.state.passPhrase}
                error={this.state.generateStatus.invalid}
              />
                <Typography align="left" color="error" variant="caption">
                 Write down your passphrase (see box above) and store it somewhere safe. 
                 <br></br>It cannot be restored once lost.
                </Typography>
            </Grid>
            
            {
              this.state.usePin ? (
                <Grid item>
                  <TextField 
                    fullWidth
                    id="pin"
                    label="Password"
                    placeholder="Password"
                    value={this.state.Pin}
                    onChange={(e)=>{
                      let value = e.target.value;
                      self.setState(
                        {Pin:value},
                        ()=>{
                          self.setState({PinStatus:validatePin(value,self.state.PinStatus)}, self.validateForm)
                        })}}
                    onChangeText={(password) => this.setState({ password })}
                    type={this.state.hidden ? 'password' : 'text'} 
                    className="numeric-password"
                    InputLabelProps={{
                      type:"tel",
                      shrink: true,
                   
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton aria-label="toggle password visibility"  onClick={this.toggleShow} style={{outline:'none'}}>
                          {this.state.hidden ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    margin="normal"
                    variant="outlined"
                    error={this.state.PinStatus.invalid}
                    autoComplete="off"
                  />    
                </Grid>
                ) : null
            }
            <Grid item>
              <Button fullWidth variant="outlined" onClick={()=>this.onClick()}>
                Register       
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>  
    );
  }
}

export const NewUser = withRouter(NewUser_);

