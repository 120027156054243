import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import { fetchAllCards } from '../common/common';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { Thumb, ThumbMobile } from './thumb';
import {CardDisplay} from './carddisplay';
import {RarityFilter} from './filter';

import { COLLECTIONACCOUNT, TARASCACARDACCOUNT } from '../common/constants';
import { Fragment } from 'react';


/*
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);
*/

/*
function ThumbsList({cards, slider}){
  if(cards == null) {
    return null;
  }
  else {
    return (
      <Grid container
        justify="center"
        direction="row"
        alignItems="flex-start"
        spacing={8}
      >
      {cards.map(
        (card, index) => (
          <Grid item key={index} xs={6} sm={4} md={2} lg={1}>
          <Thumb card={card} index={index} width="80px"
            onClick={slider ? slider.slickGoTo:undefined}/>
          </Grid>
        )
      )}
      </Grid>
    );
  }
}

function ThumbsListMobile({cards, history}){
  if(cards == null) {
    return null;
  }
  else {
    return (
      <Grid container
        justify="center"
        direction="row"
        alignItems="flex-start"
        spacing={8}
      >
      {cards.map(
        (card, index) => (
          <Grid item key={index} xs={6} sm={4} md={2} lg={1}>
          <ThumbMobile card={card} index={index} 
            onClick={()=>history.push('/game/card/'+card.asset)}/>
          </Grid>
        )
      )}
      </Grid>
    );
  }
}
*/

export class CardDeck extends Component {
  constructor(props){
    super(props);
    this.state = {
      cards:[],
      selectedChannel:"all",
      selectedRarity:"all",
      slideIndex:0
    }
    this.refresh = this.refresh.bind(this);    
  }

  /* refresh(){
    var self = this;
    console.log("CardDeck: refresh: fetching cards");
    Promise.all([
      fetchCards(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT),
      fetchCard(this.props.nodeurl,this.props.user.accountRs,TARASCACARDASSET,false)
    ])
    .then(function([cards,tCard]){
      const allCards = tCard ? ([...cards,tCard]) : (cards);
      self.setState({cards:allCards});
    })
    .catch(function(error) {console.log(error)});
  } */

  refresh(){
    var self = this;
    console.log("CardDeck: refresh: fetching cards");
    fetchAllCards(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT,TARASCACARDACCOUNT,false)
    .then(function(cards){
      //console.log(cards);
      //const allCards = tCard ? ([...cards,tCard]) : (cards);
      self.setState({cards:cards});
    })
    .catch(function(error) {console.log(error)});
  }

  componentDidMount(){
    this.refresh()
    this.timer = setInterval(this.refresh, 16000)
  }
  
  componentWillUnmount(){
    console.log("CardDeck: stop refresh");
    clearInterval(this.timer);
  }

  render() {
    //let channels = new Set(this.state.cards.map((card) => {return card.channel}))
    let rarity = new Set(this.state.cards.map((card) => {return card.rarity}))
    let cards = []
  
    if (this.state.selectedChannel !== "all") {
      cards = this.state.cards.filter((card) => (card.channel === this.state.selectedChannel) & (this.props.showAllCards | (card.quantityQNT > 0)));
    }
    else {
      cards = this.state.cards.filter((card) => (this.props.showAllCards | (card.quantityQNT > 0)));
    } 

    if (this.state.selectedRarity !== "all") {
      cards = this.state.cards.filter((card) => (card.rarity === this.state.selectedRarity) & (this.props.showAllCards | (card.quantityQNT > 0)));
    }
    else {
      cards = this.state.cards.filter((card) => (this.props.showAllCards | (card.quantityQNT > 0)));
    } 
    

    return (      
        <Fragment>
          <RarityFilter rarity={[...rarity]} onClick={(rare)=>this.setState({selectedRarity:rare})} />
          <div style={{display:"flex", textAlign:"center", width:"100%", padding:20}}>            
            <div style={{width:"95%",margin:"auto"}}>
              <Grid container
                  justify="center"
                  direction="column"
                  alignItems="center"
                  spacing={0}
                >
                <Grid container
                  justify="center"
                  direction="row"
                  alignItems="flex-start"
                  spacing={8}
                >
                {cards.map(
                  (card, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                      <CardDisplay card={card} key={index} {...this.props}/>
                    </Grid>
                  )
                )}
                </Grid>
              </Grid>
            </div>
          </div> 
        </Fragment>
    );
  }  
}

