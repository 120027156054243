import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import { validateEthAddress, validateQuantity } from "../common/validators";
import { decrypt } from "../login/storage";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';

import { transferAsset } from '../common/ardorinterface';
import Spinner from 'react-bootstrap/Spinner';
import { CardImageNoButton, CardInfo } from '../common/cardinfo';


const CardsRadioList = ({selectedCard, cards, handleChange}) => {    
  //console.log(selectedCard);
  return(
    <FormControl component="fieldset" style={{width:'240px', textAlign:"left"}}>
      <InputLabel id="demo-simple-select-label" fullWidth>Card to export</InputLabel>
      <Select
        aria-label="gender"
        defaultValue="female"
        name="radio-buttons-group" 
        onChange={handleChange}
        value={selectedCard.asset}
      >
        {
          cards.map((card,index)=>{
            if (card.quantityQNT > 0){
              const label = card.name + " (" + card.quantityQNT + " available)";
              return (
                <MenuItem key={index} value={card.asset}>{label}</MenuItem>
              );
            }
            else return null
          })    
        }
      </Select>
    </FormControl>
  );
}

export const BridgeToPolygonDisplay = ({nodeurl, user, ardorBlockedAccount, cards}) => {

  const [quantity, setQuantity] = useState("1");
  const [quantityInvalid, setQuantityInvalid] = useState({ invalid: false, error: "" });
  const [card, setCard] = useState({name:"",asset:"",quantityQNT:0});
  const [ethAddress, setEthAddress] = useState("");
  const [ethAddressInvalid, setEthAddressInvalid] = useState({ invalid: false, error: "" });
  const [pin, setPin] = useState("");
  const [pinInvalid, setPinInvalid] = useState({ invalid: false, error: "" });
  const [sending, setSending] = useState({ inProgress: false, error: "" });
  const [sendingDone, setDone] = useState(false);

  const submit = async () => {
    console.log("1, set sending..");
    setSending({ inProgress: true, error: "" });
    console.log("2, sending with params:");
    console.log(nodeurl,user,ardorBlockedAccount,pin,card.asset,quantity,ethAddress);

    try {
      const passPhrase = decrypt(user.token,pin);
      await transferAsset(nodeurl, card.asset, quantity, ardorBlockedAccount,passPhrase,ethAddress,true,361,"HIGH")
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 3000)
      );
      setDone(true);
    }
    catch (exception) {
      console.log(exception);
      setPinInvalid({invalid:true,error:exception.message});
      setSending({ inProgress: false, error: "error submitting your request" });
      return false;
    }
    console.log("3, submit done");
    setSending({ inProgress: false, error: "" });
  };

  useEffect(() => {
    setQuantityInvalid(validateQuantity(quantity,card.quantityQNT,1,{}));    
  },[quantity,card]);

  useEffect(() => {
    setEthAddressInvalid(validateEthAddress(ethAddress));    
  },[ethAddress]);
  //console.log(sending);
  
  return(
    <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:40}}>
        <Grid container
          justify="center"
          alignItems="stretch"
          direction="column"
          spacing={24}
          className="boxed"
          style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'0px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >
          <Grid item>
            <Typography variant="h5">Export Cards to Opensea on Polygon</Typography>
          </Grid>

          <Grid item>             
            <Typography variant="h6" align="left">1. Select card to swap</Typography>
            <CardsRadioList selectedCard={card} cards={cards} handleChange={(event)=>{
              //console.log(event.target.value);
              const card = cards.find((card)=>card.asset===event.target.value);
              //console.log(card);
              setCard(card);
              }} />
          </Grid>
          
          <Grid item alignItems="center">            
            {card.asset !== "" && <CardImageNoButton card={card}/>}
            {card.asset !== "" && <CardInfo card={card}/>}
          </Grid>
          
          <Grid item>
            <TextField
              fullWidth
              id="outlined-number"
              label="Number of assets to send"
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
              type="quantity"
              InputLabelProps={{
              shrink: true,
              }}
              margin="normal"
              variant="outlined"
              error={quantityInvalid.invalid}
              helperText={quantityInvalid.error}
            />            
          </Grid>
          
          <Grid item>
            <Typography variant="h6" align="left">2. Enter your polygon wallet address</Typography>

            <TextField
              fullWidth
              id="polygon"
              label="Polygon Address"
              value={ethAddress}
              onChange={(event) => setEthAddress(event.target.value)}
              //helperText=""
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                readOnly: true,
                shrink: true,
              }}      
              error={ethAddressInvalid.invalid}
              helperText={ethAddressInvalid.error}    
            />
          </Grid>
          
          <Grid item>
            <Typography variant="h6" align="left">3. Provide password and submit</Typography>

            <TextField
              fullWidth
              id="outlined-number"
              label="Password"
              value={pin}
              onChange={(event) => setPin(event.target.value)}
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              error={pinInvalid.invalid}
              helperText={pinInvalid.error}  
            />
          {sending.inProgress && 
          (<>
            <Spinner animation="border" role="status" variant="light">
              <span className="sr-only">Transferring..</span>
            </Spinner>
            <Typography variant="body2">Transferring assets</Typography>
          </>)
          }
          {sendingDone && 
          (<>
            <Typography> &nbsp; </Typography>
            <Typography variant="body2">Transfer initiated. <Link to="/game/history">Check out History</Link></Typography>
            <Typography> &nbsp; </Typography>
          </>)
          }

            <Button fullWidth variant="outlined" 
                disabled={sending.inProgress || sendingDone} 
                onClick={(event)=>submit(event)}
            > 
              Send Assets to Polygon
            </Button>
            {sending.error !== "" && <span style={{'color':'red'}} >{sending.error}</span>}
          </Grid>
        </Grid> 
    </div>
  );
}
