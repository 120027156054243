import React, {Fragment} from 'react';
import {CardInfoSlimJackpot} from '../common/cardinfo';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ThumbPlain } from '../carddeck/thumb';
import { ImCheckmark, ImCross, ImTrophy } from "react-icons/im";
import { RiPhoneLockFill } from "react-icons/ri";
import { BsInfoSquareFill } from "react-icons/bs";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import {LOWIGNIS} from '../common/constants';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%',
      }}
  />
);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "orange",
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export function CheckProgress(props) {
  const num_completed = "You have "+(props.totalNum-props.missing.length)+" out of "+props.totalNum+" cards.";
  const completed = props.complete ? 
        "Your collection is complete!" : num_completed;
        
  const missing = props.missing.length === 0 ? 
        null : props.missing.length +" cards missing:";
  /*
  const blocked = props.blocked.length === 0 ? 
        null : props.blocked.length+" blocked cards";
  const blocked_explain = props.blocked.length === 0 ? 
        null : "Do you have offered your cards? Cancel open orders to claim the prize.";
  */
  const participated = props.participationConfirmed > 0 ? true:false;
  const participatedOnce = "You've participated once for this round!";
  const participatedMult = "You've participated "+props.participationConfirmed+" times for this round!";
  
  //console.log(props);
  const IgnisLowMsg = "At least " + LOWIGNIS + " Ignis required to cover the submission fees";
  
  let partPrint
  if (props.participationConfirmed === 1) {
    partPrint = participatedOnce;
  }
  else if(props.participationConfirmed > 1) {
    partPrint = participatedMult;
  }

  return(
    <Grid container spacing={8}
              justify="center"
              alignItems="stretch"
              direction="row"
              className="boxed"
              style={{border:'0px solid', borderColor:'#ffffff3b'}}
            >


      <Grid item>
        <Typography variant="h5">Progress:</Typography>
        {participated ? 
            (<Fragment>        
              <ColoredLine color="white" />
              <Typography variant="h4">
                <ImTrophy style={{verticalAlign: 'middle', color:'#458B00'}}/> &nbsp; {partPrint}
              </Typography>
            </Fragment>)
            :
            null
          }
        <ColoredLine color="white" />
        <Typography variant="h4">
          {props.complete ? 
            (<ImCheckmark style={{verticalAlign: 'middle', color:'#458B00'}}/>)
            :
            (<ImCross style={{verticalAlign: 'middle', color:'red'}}/>)
          }
           &nbsp; {completed}</Typography>

        <Typography variant="h4">
          {props.IgnisIsLow ? (
            <Fragment><ImCross style={{verticalAlign: 'middle', color:'red'}}/> &nbsp; {IgnisLowMsg}</Fragment>
          ) : null}
        </Typography>
           
        {props.blocked === true ? 
          (<Fragment>
            <Typography>&nbsp;</Typography>
            <ColoredLine color="white" />
            <Typography variant="h4">
              <RiPhoneLockFill style={{verticalAlign: 'middle', color:'orange'}}/>&nbsp; Locked cards detected &nbsp;
                        </Typography>
            <Typography>&nbsp;</Typography>
            <Typography variant="h6">Ask orders or slow transactions may cause the issue.</Typography> 
            <Typography variant="overline"> Close ask orders and wait for transactions to complete.</Typography> 
            <Typography variant="overline"> • </Typography>
            
            <Typography variant="overline">
              1) To identify affected cards, please check your card inventory for this icon:    &nbsp; 
              <LightTooltip style={{color:'orange'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this card. At least one of these cards is locked for all actions (e.g. crafting, jackpot, sending) until you cancel your Ask order.">
                <BsInfoSquareFill style={{fontSize:'14',verticalAlign: 'middle', color:'orange'}} />
              </LightTooltip >
            </Typography>
            <Typography variant="overline"> 2) Go to the market and check your ASK orders</Typography> 
            <Typography variant="overline"> 3) Cancel any ASK orders that are locking cards for the Jackpot</Typography>
            
          </Fragment>
          
          ) : null
        }
        <ColoredLine color="white" />
      </Grid>
      
      {props.missing.length > 0 ? 
        (<Fragment>  
            <Grid container spacing={8}
              justify="space-between"
              alignItems="center"
              direction="column">
              <Typography variant="h4">{missing}</Typography>
            </Grid>  
            <Grid container spacing={8}
              justify="space-between"
              alignItems="center"
              direction="column"
            ><br></br>
              {props.missing.map((card,index)=>

              
                <Grid xs item key={index} style={{margin:5, backgroundColor:'rgb(29 29 29)', borderRadius:10, border:'1px solid', borderColor:'#ffffff3b'}} className="boxed">
                  <ThumbPlain card={card} index={index} width="125px" />
                  <CardInfoSlimJackpot card={card} />
                </Grid>
                
              )}
            </Grid>
          </Fragment>
        ) : null
      }
            
    </Grid>
  )
}
