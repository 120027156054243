import React, {Component} from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {getAccountLedger} from '../common/ardorinterface';
import { NQTDIVIDER } from '../common/constants';
import { TimeStampSimple } from '../common/common';


function LedgerDividendEntry(props){
    //console.log(props);
    let ledgerEntry="";
    if (props.entry.holdingType==="UNCONFIRMED_ASSET_BALANCE"){
        ledgerEntry = (props.entry.change/NQTDIVIDER).toFixed(2)+" GEM";
    }
    else {
        ledgerEntry =  (props.entry.change/NQTDIVIDER).toFixed(2)+" IGNIS";
    }
    return(
        <Grid container 
            justify="flex-start" 
            direction="row" 
            alignItems="stretch">
                <Grid item xs="4"><TimeStampSimple timestamp={props.entry.timestamp} eb={props.epoch_beginning} /></Grid>
                <Grid item xs="3"><Typography>{props.entry.height}</Typography></Grid>
                <Grid item xs="3"><Typography>{ledgerEntry}</Typography></Grid>                
        </Grid>
    )
}

function LedgerDisplay(props){
    let output = null;
    //console.log(props);
    if (props.ledger){
        output = props.ledger.map((ledg, index)=>{
            return (<LedgerDividendEntry key={index} entry={ledg} epoch_beginning={props.epoch_beginning}/>);
        })
    }
    return(
        <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block"}}>
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={24}
            >
                <Grid item><Typography variant={'h6'}>Your dividend history</Typography></Grid>
                {output}
            </Grid>
        </div>

    )
}


export class Ledger extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ledger:[]
        }
        this.refresh = this.refresh.bind(this); 
    }

    componentDidMount(){
        this.refresh()
        this.timer = setInterval(this.refresh, 20000)
    }
  
    componentWillUnmount(){
        console.log("Ledger: stop refresh");
        clearInterval(this.timer);    
    }

    refresh () {
        var self = this;
        console.log("Ledger: refresh");
        getAccountLedger(self.props.nodeurl, self.props.user.accountRs,0,99,"ASSET_DIVIDEND_PAYMENT")
        .then((response)=>{
            //console.log(response);
            self.setState({ledger:response.entries});
        })
    }

    render(){
        return(
            <LedgerDisplay ledger={this.state.ledger} epoch_beginning={this.props.epoch_beginning} />
        )
    }
}