import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';


export class JackpotPriceIGNIS extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    loading: true,
    price: null
  };



  async componentDidMount() {
    const url = "https://api.coingecko.com/api/v3/simple/price?ids=ignis&vs_currencies=usd";
    const response = await fetch(url);

    const data = await response.json();
    this.setState({ price: data.ignis.usd, loading: false });

  }

  render() {
    if (this.state.loading) {
      return <div>loading...</div>;
    }

    if (!this.state.price) {
      return <div>didn't get a price</div>;
    }

    return (
      <div>
        <Typography >({(this.props.totalIgnis * this.state.price).toFixed(2)} USD)</Typography>
      </div>
    );
  }
}

export class BalancePriceIGNIS extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    loading: true,
    price: null
  };



  async componentDidMount() {
    const url = "https://api.coingecko.com/api/v3/simple/price?ids=ignis&vs_currencies=usd";
    const response = await fetch(url);

    const data = await response.json();
    this.setState({ price: data.ignis.usd, loading: false });

  }

  render() {
    if (this.state.loading) {
      return <div>loading...</div>;
    }

    if (!this.state.price) {
      return <div>didn't get a price</div>;
    }

    return (
      <div>
        <Typography >({(this.props.totalIgnis * this.state.price).toFixed(2)} USD)</Typography>
      </div>
    );
  }
}