import React from 'react';
import {Switch,Route,Link} from 'react-router-dom';
import { useClearCacheCtx } from 'react-clear-cache';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { MySnackbarContentNoClose } from '../common/snackbar';


import {getAllUsers, getVersion} from './storage';
import {NewUser} from './new';
import {ReturningUser} from './returning';
import LoginForm from './loginform';
import {ClaimVoucher} from './claimvoucher';

import logo from './images/mythicalbeings-transparent-800px.png';
import { fakeAuth } from './auth';
import {FaQrcode} from 'react-icons/fa';


export const Login = (props) => {
  const { isLatestVersion } = useClearCacheCtx();
  console.log("mounting app login, mounted latest version: " + isLatestVersion + ", local version key: " + getVersion());
  const users = getAllUsers();
  if (fakeAuth.isAuthenticated) console.log("user is authenticated");
  return (
    <div>
      {
        (users.length > 0) ? (
          <LoginForm {...props}/>
        ) : (
          <Landing/>
        )
      }
    </div>
    );
}

export const LoginRoutes = (props) => {
  console.log(props.blockchainStatus.isTestnet);
  //const users = getAllUsers();
      
  let testnetAlert = "";
  if (props.blockchainStatus.isTestnet){
    testnetAlert = (
          <MySnackbarContentNoClose 
            variant="warning"
            message="You're connected to Testnet"
          />
      
    )
  }
  
  return (
    <div className="App" style={{display:"inline-block", width:"100%"}}>
      <div className="boxed" style={{textAlign:"center", padding:20, width:"90%", maxWidth:"440px", display:"inline-block", backgroundColor:'rgb(29 29 29)', border:'1px solid', borderColor:'#ffffff3b', marginTop:40, marginBottom:40}}>
        <Grid container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={24}
          
        >
          <Grid item>
            <img src={logo} alt="Logo" style={{width:"65%",maxWidth:"440px"}}/>  
          </Grid>
          <Grid item>
            <Switch>
              <Route exact path="/login" render={() => (<Login {...props}/>)}/>  
              <Route exact path="/login/new" render={() => <NewUser {...props} />}/>  
              <Route exact path="/login/returning" render={() => <ReturningUser {...props} />}/>            
              <Route exact path="/login/claimvoucher" render={() => <ClaimVoucher {...props} />}/>     
            </Switch>
          </Grid>
          <Grid item>
            {testnetAlert}
          </Grid>
          <Grid item>
            <div style={{textAlign:"right"}}>
              <Typography variant="caption">build {getVersion()}</Typography>
            </div>
          </Grid>
        </Grid>        
      </div>
    </div>
    );
}

          
export const Landing = () => (
  <div style={{textAlign:"center", padding:20, display:"inline-block"}}> 
    <Grid container
        justify="center"
        alignItems="center"
        direction="column"
        spacing={24}>
      <Grid item>
        <Typography variant="h5">Welcome to Mythical Beings</Typography>
      </Grid>
      <Grid item>
        <Link to="/login/new">
          <FormGroup>
            <Button variant="outlined">New User</Button>
          </FormGroup>
        </Link>
        <Typography variant="h6">or</Typography>
        <Link to="/login/returning">
          <FormGroup>
            <Button variant="outlined">
              Returning User?
            </Button>
          </FormGroup>
        </Link>
      </Grid>   
      <Grid item>
        <Typography variant="body2">
          Coming from Kickstarter?
        </Typography>
        <Link to="/login/claimvoucher">
          <FormGroup>
            <Button variant="outlined">
              Claim Your &nbsp; <FaQrcode/> &nbsp; Voucher
            </Button>
          </FormGroup>
        </Link>        
      </Grid>   
    </Grid>  
  </div>
);

