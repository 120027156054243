import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMade';


export function Thumb({card,index,onClick,width}) {
    return(
        <div key={index} style={{width:width,float:"left"}}>
            <div>
            <img src={card.cardThumbUrl} alt="card img"
                width="100%"
                onClick={()=>onClick(index)}
                style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>                      
            </div>
            <div>
                <Typography>{card.name}</Typography>
            </div>
        </div>
    )
}

export function ThumbPlain({card,index,width}) {
    return(
        <div key={index} style={{width:width,float:"left"}}>
            <div>
            <img src={card.cardThumbUrl} alt="card img" width="120%" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
               
              }}/>                   
            </div>

        </div>
    )
}

export function ThumbMobile({card,index,onClick,width}) {
    return (
        <div key={index} style={{width:width,float:"left"}}>
            <img src={card.cardThumbUrl} alt="card img" width="100%" 
              style={{
                borderStyle: 'solid',
                borderWidth: 2,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
               
              opacity:card.quantityQNT > 0 ? 1 : 0.1,
              }}/>                      
            <Badge badgeContent={card.quantityQNT} color="secondary">
                <Typography variant="body1">
                    {card.name}
                </Typography>
            </Badge>
        </div>
    )
}

export function ThumbExchange({card,width}) {
    return (
        <div style={{width:width,float:"center"}}>
            <img src={card.cardThumbUrl} alt="card img" width="100%" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
               
              opacity:card.quantityQNT > 0 ? 1 : 0.1,
            }}/>                       
                <Typography variant="body1">
                    {card.name} ({card.quantityQNT}) 
                </Typography>            
        </div>
    )
}

export function ThumbExchangeRow({card,width}) {
    return (
        <Grid container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={8}>
            <Grid item>
            <img src={card.cardThumbUrl} alt="card img" width="80px" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
               
              opacity:card.quantityQNT > 0 ? 1 : 0.1,
            }}/>  
            </Grid>
        </Grid>
        
    )
}

export function ImgInventoryRow({card,width}) {
    return (
        <Grid container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={8}>
            <Grid item>
            <img src={card.cardImgUrl} alt="card img" width="250" 
              style={{
              borderRadius: 5,
              //borderColor: card.rarity === 'very rare' ? 'gold' : card.rarity === 'rare' ? '#009ad1' : 'grey',
              //borderStyle: 'solid',
              marginBottom: 7,
              marginTop: 6,
              boxShadow: '4px 4px 3px rgba(5, 5, 5, 0.32)',
              opacity:card.quantityQNT > 0 ? 1 : 0.1,
            }}/>  
            </Grid>
        </Grid>
        
    )
}

export function ImgSend({card,width}) {
    return (
        <Grid container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={8}>
            <Grid item>
            <img src={card.cardImgUrl} alt="card img" width="260" 
              style={{
              borderRadius: 5,
              //borderColor: card.rarity === 'very rare' ? 'gold' : card.rarity === 'rare' ? '#009ad1' : 'grey',
              //borderStyle: 'solid',
              marginBottom: 7,
              marginTop: 6,
              boxShadow: '4px 4px 3px rgba(5, 5, 5, 0.32)',
              opacity:card.quantityQNT > 0 ? 1 : 0.1,
            }}/>  
            </Grid>
        </Grid>
        
    )
}

export function TxThumb(card) {
    //console.log(card);
    return (
        <Fragment>
            <img src={card.cardThumbUrl} alt="card img"
                width="80px"
                style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>      
        </Fragment>
    )
}

export function TxThumbOut({card, timestamp, recipient}) {
    return (
        <Grid container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={8}>
            <Grid item>
                <img src={card.cardThumbUrl} alt="card img"
                    width="100%"
                    style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>
            </Grid>
            <Grid item>
                <Grid container
                    justify="center"
                    direction="column"
                    spacing={8}>
                        <Grid item></Grid>
                            <Typography variant="h6">
                                {card.name}  
                            </Typography>
                        <Grid item>
                            <Typography variant="body1">
                                ({card.channel})
                            </Typography>
                        </Grid>        
                </Grid>
            </Grid>
            <Grid item>
                <CallMadeOutlinedIcon/>
            </Grid>
            <Grid item><Typography variant="h6">{recipient}</Typography></Grid>
        </Grid>        
    )
}

export function TxThumbIn({card, timestamp, sender}) {
    return (
        <Grid container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={8}>
            <Grid item>
                <img src={card.cardThumbUrl} alt="card img"
                    width="100%"
                    style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>
            </Grid>
            <Grid item>
                <Grid container
                    justify="center"
                    direction="column"
                    spacing={8}>
                        <Grid item></Grid>
                            <Typography variant="h6">
                                {card.name}  
                            </Typography>
                        <Grid item>
                            <Typography variant="body1">
                                ({card.channel})
                            </Typography>
                        </Grid>        
                </Grid>
            </Grid>
            <Grid item>
                <CallMadeOutlinedIcon/>
            </Grid>
            <Grid item><Typography variant="h6">{sender}</Typography></Grid>
        </Grid>        
    )
}