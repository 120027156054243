import React, { Component } from "react";
class Chat extends Component {
    async componentDidMount() {
        let result = await import('@widgetbot/crate')
        const Crate = await result.cdn();
        new Crate({
            server: '809857155401646151',
            channel: '809857472751861771',
            location: ['bottom', 'right'],
            shard: 'https://emerald.widgetbot.io',
            indicator: true
        })
        // crate.on('message', ({ message, channel }) => {
        //     console.log(`New message in ${channel}`, message)
        // })
    }
    render() {
        return <div></div>
    }
}

export default Chat;