import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';

//Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import MenuIcon from '@material-ui/icons/Menu';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Star from '@material-ui/icons/Star';
import MoneyIcon from '@material-ui/icons/Money';
import Collections from '@material-ui/icons/Collections';
import SwapCalls from '@material-ui/icons/SwapCalls';
import SaveAlt from '@material-ui/icons/SaveAlt';
import History from '@material-ui/icons/History';
import logo from '../login/images/mythicalbeings-transparent-160px.png';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

import { NQTDIVIDER } from'./constants';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Badge } from '@material-ui/core';

import {round} from '../common/common';
import { BackButton } from './backbutton';

import { ImGift } from "react-icons/im";
import Gem from './images/gems.svg';

import { GiTwoCoins } from "react-icons/gi";
import { IoSwapVertical } from "react-icons/io5";


const drawerWidth = 280;

const styles = theme => ({
  root: {
    flexGrow:10,
    marginTop:60
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    flexGrow:1,
    position:"fixed" 
  },
  link: {
    textDecoration: 'none',
    
  }
  
});



class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        anchorEl:null,
        currentName:"ignis_balance",
        receiveModal:false,
        buyModal:false,
        navOpen:false,
        userOpen:false,
        drawerOpen:false
    }
    
  }

  handleClick = event => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget, currentName:event.currentTarget.name });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    //console.log(this.props);
    const {wallet, walletCurrency,walletGem, classes} = this.props;
    const {anchorEl, drawerOpen, currentName} = this.state;
    const path = this.props.location.pathname
  
    const drawer = (
      <div>
        <div/>
        <Divider />
        <List>



          <Link className={classes.link} to="/game">
            <ListItem button key="Wallet" onClick={()=>this.setState({drawerOpen:false})}>
              <ListItemIcon><Collections/></ListItemIcon>
              <ListItemText primary="Inventory" />
            </ListItem>
          </Link>
          
          <Link className={classes.link} to="/game/history">
              <ListItem button key="History" onClick={()=>this.setState({drawerOpen:false})}>
                <ListItemIcon><History /></ListItemIcon>
                {this.props.lastUpdate.newTx !== 0 ? (
                    <Badge badgeContent={this.props.lastUpdate.newTx} color="secondary"> 
                      <ListItemText primary="History" />
                    </Badge>
                  ):(
                    <ListItemText primary="History" />                  
                  )
                }
            </ListItem>
          </Link>

          <Link className={classes.link} to="/game/exchange">
              <ListItem button key="Exchange" onClick={()=>this.setState({drawerOpen:false})}>
                <ListItemIcon><SwapCalls /></ListItemIcon>
                {this.props.lastUpdate.newTrades !== 0 ? (
                    <Badge badgeContent={this.props.lastUpdate.newTrades} color="secondary"> 
                      <ListItemText primary="Market" />
                    </Badge>
                  ):(
                    <ListItemText primary="Market" />                  
                  )
                }
              </ListItem>
          </Link>

          <Link className={classes.link} to="/game/jackpot">
              <ListItem button key="Progress" onClick={()=>this.setState({drawerOpen:false})}>
              <ListItemIcon><Star /></ListItemIcon>
              <ListItemText primary="Jackpot" />
            </ListItem>
          </Link>

          <Link className={classes.link} to="/game/buypack">
            <ListItem button key="Buy Pack" onClick={()=>this.setState({drawerOpen:false})}>              
                <ListItemIcon><ViewCarouselIcon /></ListItemIcon>
                <ListItemText primary="Buy Pack" />              
            </ListItem>
          </Link>

          
          <Link className={classes.link} to="/game/tarasca">
            <ListItem button key="Dividends" onClick={()=>this.setState({drawerOpen:false})}>              
                <ListItemIcon><GiTwoCoins style={{fontSize:'24'}}/></ListItemIcon>
                <ListItemText primary="Dividends" />              
            </ListItem>
          </Link>
          
        </List>
        <Divider />
        <List>
          <Link className={classes.link} to="/game/receive">
            <ListItem button key="Receive Card" onClick={()=>this.setState({drawerOpen:false})}>              
                <ListItemIcon><SaveAlt /></ListItemIcon>
                <ListItemText primary="Receive Card" />              
            </ListItem>
          </Link>
        </List>
       
        <Divider />
        <List>
          <Link className={classes.link} to="/game/fundaccount">
            <ListItem button key="Fund Account" onClick={()=>this.setState({drawerOpen:false})}>
              <ListItemIcon><MoneyIcon /></ListItemIcon>
              <ListItemText primary="Fund Account" />
            </ListItem>
          </Link>  
          <Link className={classes.link} to="/game/settings">
            <ListItem button key="Settings" onClick={()=>this.setState({drawerOpen:false})}>
              <ListItemIcon><Settings /></ListItemIcon>
              <ListItemText primary="User Info" />
            </ListItem>
          </Link>   
          <Link className={classes.link} to="/game/bridge">
            <ListItem button key="Settings" onClick={()=>this.setState({drawerOpen:false})}>
              <ListItemIcon><IoSwapVertical/></ListItemIcon>
              <ListItemText primary="Polygon Bridge" />
            </ListItem>
          </Link> 
          <Link className={classes.link} to="/logout">
            <ListItem button key="Logout">
              <ListItemIcon><ExitToApp /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </Link>  
        </List>
      </div>
    );

    const ignisAvailableBalance = round(Math.min(wallet.balanceNQT/NQTDIVIDER,wallet.unconfirmedBalanceNQT/NQTDIVIDER),2);
    const fundsLocked = wallet.balanceNQT !== wallet.unconfirmedBalanceNQT;
    const ignisTotalBalance = round(wallet.balanceNQT/NQTDIVIDER,2)
    const ignisTotalBalanceStr = (<Fragment>({ignisTotalBalance})</Fragment>)
    const gammaAvailableBalance = Math.min(walletCurrency.unitsQNT,walletCurrency.unconfirmedUnitsQNT)
    const gammaLocked = walletCurrency.unitsQNT !== walletCurrency.unconfirmedUnitsQNT;
    const gammaTotalBalanceStr = (<Fragment>({walletCurrency.unitsQNT})</Fragment>)

    const gemAvailableBalance = round(Math.min(walletGem.quantityQNT/NQTDIVIDER,walletGem.unconfirmedQuantityQNT/NQTDIVIDER),2)
    const gemLocked = walletGem.quantityQNT !== walletGem.unconfirmedQuantityQNT;
    const gemTotalBalanceStr = (<Fragment>({round(Math.min(walletGem.quantityQNT/NQTDIVIDER),2)})</Fragment>)



    // this makes the backbutton show for every page except /game (exact) and game/exchange.
    // make a function out of it if more exceptions are required.
    const showMenuButton = this.props.match.isExact | this.props.location.pathname === "/game/exchange";
    
    return (
        <div className={classes.root} style={{marginTop:110}}>
            <AppBar position="fixed" className={classes.appBar} >
                <Toolbar>
                  <Hidden mdUp>
                    {showMenuButton ? (
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={()=>this.setState({drawerOpen:!drawerOpen})}
                      style={{marginLeft: 'auto'}}
                    >
                      <MenuIcon />
                      </IconButton>
                    ) : (
                      <BackButton/>
                    )}
                  </Hidden>                   
                  <Hidden smDown>
                    <img src={logo} alt="Logo" style={{height:80}}/>
                    <Link className={classes.link}  to="/game" style={path === '/game' ? {backgroundColor:'transparent#'} : {backgroundColor:'transparent'}}>
                      <ListItem button key="Wallet" onClick={()=>this.setState({drawerOpen:false})}>
                        <ListItemText primary="Inventory" />
                      </ListItem>
                    </Link>
                    <Link className={classes.link} to="/game/history" style={path === '/game/history' ? {backgroundColor:'transparent'} : {backgroundColor:'transparent'}}>
                        <ListItem button key="History" onClick={()=>this.setState({drawerOpen:false})}>
                          {this.props.lastUpdate.newTx !== 0 ? (
                              <Badge badgeContent={this.props.lastUpdate.newTx} color="secondary"> 
                                <ListItemText primary="History" />
                              </Badge>
                            ):(
                              <ListItemText primary="History" />                  
                            )
                          }
                      </ListItem>
                    </Link>
                    <Link className={classes.link} to="/game/exchange" style={path === '/game/exchange' ? {backgroundColor:'transparent'} : {backgroundColor:'transparent'}}>
                        <ListItem button key="Exchange" onClick={()=>this.setState({drawerOpen:false})}>
                          {this.props.lastUpdate.newTrades !== 0 ? (
                              <Badge badgeContent={this.props.lastUpdate.newTrades} color="secondary"> 
                                <ListItemText primary="Market" />
                              </Badge>
                            ):(
                              <ListItemText primary="Market" />                  
                            )
                          }
                        </ListItem>
                    </Link>

                    <Link className={classes.link} to="/game/jackpot" style={path === '/game/jackpot' ? {backgroundColor:'#'} : {backgroundColor:'#'}}>
                        <ListItem button key="Progress" onClick={()=>this.setState({drawerOpen:false})}>
                        <ListItemText primary="Jackpot" />
                      </ListItem>
                    </Link>

                    <Link className={classes.link} to="/game/buypack" style={path === '/game/buypack' ? {backgroundColor:'#'} : {backgroundColor:'#'}}>
                        <ListItem button key="Buypack" onClick={()=>this.setState({drawerOpen:false})}>
                        <ListItemText primary="Buy Pack" />
                      </ListItem>
                    </Link>
                  </Hidden>

                    <Typography  variant="h6" color="inherit" className={classes.grow}>
                        {' '}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch name="showAll" checked={this.props.showAllCards}
                                onChange={this.props.toggleShowAllCards}/>
                        }
                      label="Show All Cards" 
                    />
                  
                    <Button variant="outlined"  title ="IGNIS Balance" aria-label="ignis balance" name="ignis_balance" onClick={this.handleClick} size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '1px', marginTop: '1px', maxWidth: '110', minWidth: '110', maxHeight: '30', minHeight: '30', color:"#fff", boxShadow: "3px 3px 2px rgba(5, 5, 5, 0.32)"}}>
                      <Typography variant="caption">
                        IGNIS: {ignisAvailableBalance} {fundsLocked ? (ignisTotalBalanceStr) : null} </Typography>
                    </Button>
                      <Typography>&nbsp;&nbsp;</Typography>
                    <Button variant="outlined" title ="GIFTZ Balance" aria-label="currency balance" name="currency_balance" onClick={this.handleClick} size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '1px', marginTop: '1px', maxWidth: '110', minWidth: '110', maxHeight: '30', minHeight: '30', color:"#fff", boxShadow: "3px 3px 2px rgba(5, 5, 5, 0.32)"}}>
                      <Typography variant="caption"> <ImGift fontSize="medium"/> &nbsp; &nbsp; {gammaAvailableBalance} {gammaLocked ? (gammaTotalBalanceStr) : null} </Typography>
                    </Button>
                    <Typography>&nbsp;&nbsp;</Typography>
                    <Button variant="outlined" title ="GEM Balance" aria-label="gem balance" name="gem_balance" onClick={this.handleClick} size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '1px', marginTop: '1px', maxWidth: '110', minWidth: '110', maxHeight: '30', minHeight: '30', color:"#fff", boxShadow: "3px 3px 2px rgba(5, 5, 5, 0.32)"}}>
                      <Typography variant="caption"> <img src={Gem} alt="Gem" width="18px" /> &nbsp; &nbsp; {gemAvailableBalance} {gemLocked ? (gemTotalBalanceStr) : null} </Typography>
                    </Button>


                    <Hidden smDown> 
                    <Typography>&nbsp;</Typography>
                    <Typography>&nbsp;</Typography>
                      <Link to="/game/tarasca">
                        <IconButton style={{outline:'none'}} title ="Dividends">
                          <GiTwoCoins />
                        </IconButton>
                        {/* <ListItem button key="Tarasca" onClick={()=>this.setState({drawerOpen:false})}>
                          <ListItemIcon><Title /></ListItemIcon>
                          <ListItemText primary="Tarasca Card" />
                        </ListItem> */}
                      </Link>
                      {/* <Link to="/game/settings">
                        <IconButton>
                            <Settings />
                        </IconButton>
                      </Link> */}
                      
                        <IconButton style={{outline:'none'}} title ="Settings" name="settings" onClick={this.handleClick}>
                            <Settings />
                        </IconButton>
           
                      <Link to="/logout">
                        <IconButton style={{outline:'none'}} title ="Logout">
                            <ExitToApp />
                        </IconButton>
                      </Link>
                    </Hidden>
                </Toolbar>
            </AppBar>
            
            {currentName==="settings" ?
              (<Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <Link className={classes.link} to="/game/fundaccount" >
                  <ListItem button key="Fund Account" onClick={this.handleClose}>
                    <ListItemText primary="Fund Account" />
                  </ListItem>
                </Link>
                <Link className={classes.link} to="/game/receive">
                  <ListItem button key="Receive Card" onClick={this.handleClose}>
                      <ListItemText primary="Receive Card" />              
                  </ListItem>
                </Link>
                <Link className={classes.link} to="/game/settings">
                  <ListItem button key="Settings" onClick={this.handleClose}>
                    <ListItemText primary="User Info" />
                  </ListItem>
                </Link>  
                <Link className={classes.link} to="/game/bridge">
                    <MenuItem onClick={this.handleClose}>Polygon Bridge</MenuItem>
                </Link>
              </Menu>)
            :
              currentName==="ignis_balance"? (
              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                  <Link className={classes.link} 
                        to={(wallet.balanceNQT==="0") ? "#" : "/game/sendignis"}>
                    <MenuItem onClick={this.handleClose}
                      disabled={wallet.balanceNQT==="0"}
                    >
                      Send
                    </MenuItem>
                  </Link>
                  <Link className={classes.link} 
                        to={(wallet.balanceNQT==="0") ? "#" : "/game/buypack"}>
                    <MenuItem onClick={this.handleClose}
                      disabled={wallet.balanceNQT==="0" && walletCurrency.unitsQNT==="0"}
                    >
                      Buy Cards
                    </MenuItem>
                  </Link>
                  <Link className={classes.link} to="/game/fundaccount">
                    <MenuItem onClick={this.handleClose}>Get more</MenuItem>
                  </Link>
                </Menu>
              )
            :
              currentName==="currency_balance" ? (
                <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
                  <Link className={classes.link} 
                        to={(walletCurrency.unitsQNT===0) ? "#" : "/game/sendcurrency"}>
                    <MenuItem onClick={this.handleClose}
                      disabled={walletCurrency.unitsQNT===0}
                    >
                      Send
                    </MenuItem>
                  </Link>
                  <Link className={classes.link} 
                        to={(walletCurrency.unitsQNT===0) ? "#" : "/game/buypackcur"}>
                    <MenuItem onClick={this.handleClose}
                      disabled={walletCurrency.unitsQNT===0}
                    >
                      Buy Cards
                    </MenuItem>
                  </Link>
                  <Link className={classes.link} to="/game/buygiftz">
                    <MenuItem onClick={this.handleClose}>Get more</MenuItem>
                  </Link>
                </Menu>
              ) : (
                <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                    <Link className={classes.link} 
                          to={(gemAvailableBalance===0) ? "#" : "/game/sendgem"}>
                      <MenuItem onClick={this.handleClose}
                         disabled={gemAvailableBalance===0}

                      >
                        Send GEM
                      </MenuItem>
                    </Link>
                    <Link className={classes.link} to="/game/exchange">
                      <MenuItem onClick={this.handleClose}>Get more</MenuItem>
                    </Link>
                  </Menu>
              )
            }
            
            <nav className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={'left'}
                open={drawerOpen}
                onClose={()=>this.setState({drawerOpen:!drawerOpen})} 
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      );
    }
}

export default withStyles(styles, {withTheme:true})(NavBar);

