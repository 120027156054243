import React, { Component } from 'react';
import {secretPhraseToPublicKey,signTransactionBytes} from 'ardorjs';
import Grid from '@material-ui/core/Grid';
import {validatePrice, validatePassPhrase, validateQuantity} from '../common/validators';
import axios from 'axios';
import qs from 'qs';

import TextField from '@material-ui/core/TextField';
import {getAskOrders, getBidOrders, getIgnisBalance } from '../common/ardorinterface';
import {SignActionField} from '../common/signactionfield';
import { fetchCard, DangerWarning } from '../common/common';
import { CurrencyInfo } from '../common/cardinfo';
import { Typography } from '@material-ui/core';

import { NQTDIVIDER } from '../common/constants';
import { TxSuccess } from '../common/txsuccess';
import {OrderBooksCurrency} from './orderbook';
import { round } from '../common/common';
import Gem from './images/gems.svg';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);


class PlaceAskOrderForm extends Component {
  constructor (props){
    super(props);
    this.state = {
      bought:false,
      priceNQTPerShare:1.00,
      priceNQTPerShareStatus:{invalid:undefined,error:''},

      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''},

      tradeQuantityQNT:1,
      tradeQuantityQNTStatus:{invalid:false,error:''},

      maxPrice:undefined,
      submitStatus:{invalid:false,error:""},
      formValid:false

    };
    this.placeOrder = this.placeOrder.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.validateForm = this.validateForm.bind(this);    
  }

  placeOrder(event){
    const ORDERTYPE = "placeAskOrder";
    var self = this;

    if(!self.state.formValid){
      self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
      return;
    }
    else {
      self.setState({submitStatus:{invalid:false, error:""}});
    }

    let phraseValidated = validatePassPhrase(self.state.passPhrase,self.state.passPhraseStatus,self.props.user.accountRs);
    if  (phraseValidated.invalid){
      console.log("placeOrder(): passphrase invalid, exiting.")
      self.setState({passPhraseStatus:phraseValidated},this.validateForm);
      return;
    }
    console.log("placeOrder(): passphrase ok.")
    console.log('postOrder(): type:'+ORDERTYPE);
    const publicKey = secretPhraseToPublicKey(self.state.passPhrase);
    const passPhrase = self.state.passPhrase;
    const priceNQT = Math.floor(self.state.priceNQTPerShare*NQTDIVIDER);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    var query = {
      requestType:ORDERTYPE,
      asset:self.props.card.asset,
      priceNQTPerShare:priceNQT,
      publicKey:publicKey,
      chain:2,
      quantityQNT:self.state.tradeQuantityQNT*NQTDIVIDER,
      feeNQT:-1,
      feeRateNQTPerFXT: -1,
      deadline:15,
      broadcast:false,
    }
    console.log(query,self.state.priceNQTPerShare);
    console.log('get minimumFee');
    const url_postOrder = self.props.nodeurl+'?requestType='+ORDERTYPE;
    const url_broadcast = self.props.nodeurl+'?requestType=broadcastTransaction';
    axios.post(url_postOrder, qs.stringify(query), config)
          .then(function(response) {
            //query.feeNQT = JSON.parse(data).minimumFeeFQT;response.data.minimumFeeFQT *response.data.bundlerRateNQTPerFXT*0.00000001;
            let fee = 0;
            fee = response.data.minimumFeeFQT*response.data.bundlerRateNQTPerFXT*0.00000001;
            
            query.feeNQT = Math.ceil(fee);
            console.log("fee from node: "+fee+", set to:"+query.feeNQT);
  
            query.broadcast = false;
            console.log('get transactionBytes');
            axios.post(url_postOrder, qs.stringify(query), config)
              .then(function(response){
                const signed = signTransactionBytes(response.data.unsignedTransactionBytes, passPhrase);
                let txdata = {transactionBytes:signed};
                console.log("sending signed transaction");
                axios.post(url_broadcast, qs.stringify(txdata), config)
                    .then(function(response){
                      console.log(response);
                      self.setState({response:response,responseTime:response.data.requestProcessingTime,bought:true,status:"success"});
                    })
              })
          })
          .catch(function (error) {
            console.log('ohje refresh state:');
            console.log(error.message);
            self.setState({status:"ERROR"})
          });
  }


  validateForm() {
    //console.log(this.state);
    this.setState({formValid: (this.state.tradeQuantityQNTStatus.invalid===false) && (this.state.priceNQTPerShareStatus.invalid===false)});
  }

  
  handlePriceChange(event){
    //const valueNQT = Math.floor(event.target.value*NQTDIVIDER);
    //const value = valueNQT/NQTDIVIDER;
    const value = event.target.value;
    //const max=this.props.orderType==="placeBidOrder" ? this.state.maxPrice : undefined;
    const max = undefined;
    const min = 0;
    this.setState(
      {priceNQTPerShare:value},
      ()=> {const fieldStatus = validatePrice(value,max,min,this.state.priceNQTPerShareStatus);
            this.setState({priceNQTPerShareStatus:fieldStatus},this.validateForm);}
    );
  }


  handlePassphraseChange(value){
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }


  handleQuantityChange(event){
    let value = event.target.value;
    let max=this.props.card.quantityQNT; // for ask order
    let min=1;
    this.setState(
      {tradeQuantityQNT:value},
      ()=>{let fieldStatus = validateQuantity(value,max,min,this.state.tradeQuantityQNTStatus);
            this.setState({tradeQuantityQNTStatus:fieldStatus},this.validateForm);}
    );
  }


  componentDidUpdate(){
    let newMax = (this.props.wallet.balanceNQT/this.props.NQTdivider)/this.state.tradeQuantityQNT;
    if (isNaN(newMax) === false & newMax !== this.state.maxPrice) {
      this.setState({maxPrice:newMax});
    }
  }


  render(){
    //console.log(this.state);
    //console.log(this.props);
    if (!this.state.bought){
      if (this.props.card.quantityQNT<1){
        return(
          <Typography variant="body1">
            No GEM, unable to create an ask order.
          </Typography>
        )
      }
      return (
        <form onSubmit={(event)=>{event.preventDefault();this.placeOrder()}}>
          <Grid container spacing={24} 
                  justify="center"
                  alignItems="center"
                  direction="column">
            <Grid item>
                <TextField invalid={this.state.tradeQuantityQNTStatus.invalid} 
                    type="number" 
                    name="tradeQuantityQNT"
                    label={"Amount of GEM (max: "+round(Math.min(this.props.card.quantityQNT/NQTDIVIDER),2)+")"}
                    error={this.state.tradeQuantityQNTStatus.invalid}
                    helperText={this.state.tradeQuantityQNTStatus.error}
                    variant="outlined"
                    InputLabelProps={{
                      type:"number",
                      shrink: true
                    }}
                    id="priceNQTPerShare" onChange={(event) => this.handleQuantityChange(event)}
                    value={this.state.tradeQuantityQNT}
                    placeholder="Enter price" />
              </Grid>
              <Grid item>
                <TextField invalid={this.state.priceNQTPerShareStatus.invalid} 
                    type="number" 
                    name="priceNQTPerShare"
                    label={"IGNIS per GEM"}
                    variant="outlined"
                    InputLabelProps={{
                      type:"number",
                      shrink: true
                    }}
                    id="priceNQTPerShare" onChange={(event) => this.handlePriceChange(event)}
                    value={this.state.priceNQTPerShare}
                    error={this.state.priceNQTPerShareStatus.invalid} 
                    helperText={this.state.priceNQTPerShareStatus.error}
                    placeholder="Enter price (IGNIS)" />
              </Grid>
                <Grid item>
                  <DangerWarning active={this.state.submitStatus.invalid}>
                    {this.state.submitStatus.error}
                  </DangerWarning>
                </Grid>
              <Grid item>
                <SignActionField  {...this.props} {...this.state} gameaction="trade" 
                    handlePassphraseChange={this.handlePassphraseChange}
                    action={this.placeOrder}/>
              </Grid>
          </Grid>
        </form>
      )
    }
    else {
       return (<TxSuccess response={this.state.response}/>)
    }
  }
}


export class PlaceAskOrderGEM extends Component {
  constructor (props){
    super(props);
    this.state={
      card:{},
      askOrders:[],
      bidOrders:[],
      wallet: {unconfirmedBalanceNQT:0, balanceNQT:0},
      response:{errorCode:undefined,error:""}
    }
    this.refresh = this.refresh.bind(this);
  }


  refresh(){
    console.log("cardAskOrder refresh()");
    var self = this;

    fetchCard(this.props.nodeurl,this.props.user.accountRs,this.props.match.params.asset)
    .then((response)=>{
      self.setState({card:response});
      getAskOrders(this.props.nodeurl,response.asset)
      .then((response) => {
        self.setState({askOrders:response.askOrders});
      });
      getBidOrders(this.props.nodeurl,response.asset)
      .then((response) => {
        //console.log(response);
        self.setState({bidOrders:response.bidOrders});
      });
    })
    .catch((err)=>{console.log(err)});

    getIgnisBalance(self.props.nodeurl,self.props.user.accountRs)
    .then(function(response){
        self.setState({wallet:response})
    });
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }


  render() {
    return (
      <div style={{textAlign:"center", padding:10, width:"90%", display:"inline-block"}}>
      <Grid container spacing={8}
          className="boxed"
          justify="center"
          alignItems="flex-start"
          direction="row"
          style={{ marginTop: 5, border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)", backgroundColor: '#1D1D1D',}}>
            
          <Grid item>

            <Grid container spacing={12}
                  justify="center"
                  alignItems="center"
                  direction="column"
                  
              >
                <Grid item>
       

                <Grid item style={{ marginTop: 10}}>
                  <img src={Gem} alt="Gem" width="50px" />
                  <Typography>&nbsp;</Typography>
                  <CurrencyInfo card={this.state.card} />
                  <Typography> &nbsp;</Typography> <ColoredLine color="white" /> <Typography> &nbsp;</Typography>
                  <Typography variant="h4"> ASK FOR GEM </Typography>
                  <Typography> &nbsp;</Typography>
                  <PlaceAskOrderForm {...this.state} {...this.props} />
                  <Typography> &nbsp;</Typography> <ColoredLine color="white" /> <Typography> &nbsp;</Typography>
                 </Grid> 

                 </Grid> 
                 
            </Grid>
          </Grid>
          <Grid item style={{maxWidth:"600px"}}>
                  <OrderBooksCurrency {...this.props}/>
          </Grid>

      </Grid>
    </div>
    );
  }
}

