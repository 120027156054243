import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {Link} from 'react-router-dom';
import { GEMASSET } from '../common/constants';
                          

export function AccountOrderTable(props){
    var orders;
    //var modals;
    // console.log(props.orders);
    if (props.orders === undefined || props.orders.length === 0){
      if(props.type === 'bid') {
        orders = (<TableRow><TableCell></TableCell><TableCell>No Bids</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>);
      } else {
        orders = (<TableRow><TableCell></TableCell><TableCell>No Orders</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>);
      }
    }
    else {
      orders = props.orders.map((order) =>{
        //console.log(props);
        //const card = cardGenerator(order,props.collectionAssets,props.nodeurl);
        const card = props.cards.find((card)=>(card.asset ===order.asset))

        if (card !== undefined) {
          //const orderType = (order.type) === "ask" ? "cancelAskOrder" : "cancelBidOrder";
          let link = "/game/cancel/"+props.type+"/"+order.order;
          return (
          
   
                  <TableRow key={order.order}>
                    <TableCell>{card.name === 'Kăk-whăn’-û-ghăt Kǐg-û-lu’-nǐk' ? 'Kăk-whăn’' : card.name}</TableCell>
                    <TableCell style={{textAlign:"center"}}>{order.priceNQTPerShare/props.NQTdivider}</TableCell>
                    <TableCell style={{textAlign:"center"}}>{order.quantityQNT}</TableCell>
                    <TableCell style={{textAlign:"center"}}>
                      <Link to={link}>
                        <IconButton> 
                            <DeleteForever/>
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
  
                  );
          
          }
        else {
          return (null);
        }
      });
    }
    return (

            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell>Asset</TableCell>
                  <TableCell style={{textAlign:"center"}}>Price</TableCell>
                  <TableCell style={{textAlign:"center"}}>Amount</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  { orders }
              </TableBody>
            </Table>
 
            )
};

export function AccountOrderTableCurrency(props){
  var orders;
  //var modals;
  // console.log(props.orders);
  if (props.orders === undefined || props.orders.length === 0){
    if(props.type === 'bid') {
      orders = (<TableRow><TableCell></TableCell><TableCell>No Bids</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>);
    } else {
      orders = (<TableRow><TableCell></TableCell><TableCell>No Orders</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>);
    }
  }
  else {
    orders = props.orders.map((order) =>{


      if (order.asset === GEMASSET) {
        //const orderType = (order.type) === "ask" ? "cancelAskOrder" : "cancelBidOrder";
        let link = "/game/cancelcurrency/"+props.type+"/"+order.order;
        return (
        
 
                <TableRow key={order.order}>
                  <TableCell>GEM</TableCell>
                  <TableCell style={{textAlign:"center"}}>{order.priceNQTPerShare/props.NQTdivider}</TableCell>
                  <TableCell style={{textAlign:"center"}}>{order.quantityQNT/props.NQTdivider}</TableCell>
                  <TableCell style={{textAlign:"center"}}>
                    <Link to={link}>
                      <IconButton> 
                          <DeleteForever/>
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>

                );
        
        }


      else {
        return (null);
      }
    });
  }
  return (

          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Currency</TableCell>
                <TableCell style={{textAlign:"center"}}>Price</TableCell>
                <TableCell style={{textAlign:"center"}}>Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                { orders }
            </TableBody>
          </Table>

          )
}


