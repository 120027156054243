import React, { Fragment, Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

import { FiSend } from "react-icons/fi";
import { GiStoneCrafting } from "react-icons/gi";
import { GiRollingEnergy } from "react-icons/gi";

import { withStyles } from '@material-ui/core/styles';
import { getAskOrders, getBidOrders } from '../common/ardorinterface';
import {CardLink} from '../common/common';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

import Tooltip from '@material-ui/core/Tooltip';
import { BsInfoSquareFill, BsStarFill } from "react-icons/bs";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "orange",
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);


const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: grey,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  image: {
    height:0,
    paddingTop: '142%', 
    marginTop:'30',
    display:"block",
    zIndex:-1,
  },
  container:{
    position:"relative",
    maxWidth:'100%',
    height:'100%',
    width:'100%'
  },
  overlay: {
    opacity: 0.9,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    position:"absolute",
    zIndex:1000,
    backgroundColor:"white",
    padding: "150px 0"
  },
  media: {
    height: 450,
    width:300,
  },
  card: {
    maxWidth:300,
  }, 
  badge: {
    top:20,
    right: 20
  },
  shugobutton : {
    marginBottom: '3px', 
    marginTop: '3px', 
    maxWidth: '120px', 
    minWidth: '120px', 
    color:"#fff", 
    boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}
});


class CardDisplay_ extends Component {
  constructor(props) {
    super(props);
    this.state={
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[],
      anchorEl:null,
      currentName:"ignis_balance",
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, currentName:event.currentTarget.name });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleSend() {
    this.setState({modalSend:!this.state.modalSend})
  }

  toggleTrade = () => (this.setState({modalTrade:!this.state.modalTrade}))

  componentDidMount(){
    var self = this;
    getAskOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({bidOrders:response.bidOrders});
    });
  }
              
  render() {
    const posess = this.props.card.quantityQNT > 0;
    //const quant = this.props.card.quantityQNT;
    const {card, classes} = this.props;
    //const {anchorEl, currentName} = this.state;
    //const cannotCraft = (this.props.card.rarity === 'very rare' || !posess) || ((quant/5) < 1);
    const rarity = this.props.card.rarity === "very rare" ? "epic" : this.props.card.rarity;
    let avatarcolor = "Grey";
    if (this.props.card.rarity === "very rare") {
      avatarcolor = "Khaki";
    }
    else if (this.props.card.rarity === "rare") {
      avatarcolor = "CornflowerBlue";
    }
    else if (this.props.card.rarity === "special") {
      avatarcolor = "#53AF53";
    }
    else {
      avatarcolor = "Grey";
    }
    

    // create the string for the locked-cards tooltip. it only concats the strings if necessary.
    const tooltiptext_ = " card(s) locked for all actions (e.g. crafting, jackpot, sending). Check for open Ask orders to unlock."
    const cardsLocked =  this.props.card.unconfirmedQuantityQNT<this.props.card.quantityQNT;
    const tooltiptext =  cardsLocked ? (this.props.card.quantityQNT - this.props.card.unconfirmedQuantityQNT) + tooltiptext_ : tooltiptext_;

    const specialMarkText = "This is a special card. You can't buy it in booster packs. Click to learn more."
    const specialMark = (rarity === "special") ?
      (
        <LightTooltip 
          style={{color:'orange'}} 
          disableFocusListener disableTouchListener 
          title={specialMarkText}
          >
          <Link to={"/game/tarasca"}><BsStarFill /></Link>
        </LightTooltip > 
      ) : (
          null
      );
    
    //if (rarity === "special") {console.log(specialMark)};
    const craftdisabled = (!posess || rarity === "special" || rarity==="very rare" || rarity==="epic");
    const morphdisabled = (!posess || rarity === "special");
    return (
      <Card className={classes.card} style={{ marginBottom:20, backgroundColor:'rgb(40 40 40)', border:'1px solid', borderColor:'#ffffff3b'}}>
        
        { posess ? (
                <CardMedia className={classes.image} image={card.cardImgUrl} title={card.name} style={styles.image} onClick={()=>{this.props.history.push("/game/learnmore/"+card.asset)}}/>
            
          ):(
            <div className={classes.container}>
              <CardMedia className={classes.image} image={card.cardImgUrl} title={card.name}/>
              <div className={classes.overlay} style={{backgroundColor: '#333333'}}>
                <Typography variant='h4' style={{paddingBottom:10}}>Missing Card</Typography>
                <Link to={"/game/place/bid/"+card.asset} style={{textDecoration:'none'}}>
                  <Button variant="outlined">Look for Offers</Button>
                </Link>
              </div>
            </div>
          )
        }
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar} style={{backgroundColor:avatarcolor}}>
              {card.quantityQNT}
            </Avatar>
          }

          title={
              <Fragment> 
                {card.name} &nbsp;
                <LightTooltip 
                  style={{color:'orange', display: cardsLocked ? '' : 'none'}} 
                    disableFocusListener disableTouchListener 
                    title={tooltiptext}
                    >
                    <BsInfoSquareFill />
                </LightTooltip > 
              </Fragment>
          }
          subheader={
              <Fragment>
                {card.channel} &middot; {rarity} &nbsp; {specialMark}
              </Fragment>
          }
          titleTypographyProps={{variant:'h6'}}
          style={{textAlign:'left'}}
        />

        <CardActions style={{justifyContent: 'center', marginBottom:10, marginTop:10}}>
          <CardLink to={"/game/send/"+this.props.card.asset} disabled={!posess}>
            <Button variant="outlined" size="small" style={styles.shugobutton} disabled={!posess}>
                <FiSend/> &nbsp; SEND
            </Button> &nbsp;
          </CardLink>
          <CardLink to={"/game/crafting/"+this.props.card.asset} disabled={craftdisabled}>
            <Button variant="outlined" size="small" style={styles.shugobutton} disabled={craftdisabled}>
              <GiStoneCrafting/> &nbsp; CRAFT 
            </Button> &nbsp;
          </CardLink>
          <CardLink to={"/game/morphing/"+this.props.card.asset} disabled={morphdisabled}>
            <Button variant="outlined" size="small" style={styles.shugobutton} disabled={morphdisabled}>
              <GiRollingEnergy/> &nbsp; MORPH
            </Button>
          </CardLink>
        </CardActions> 
      </Card>
    );
  }
}

export const CardDisplay = withStyles(styles)(CardDisplay_);