import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import axios from 'axios';
import qs from 'qs';

import { getCurrencySellOffers } from '../common/ardorinterface';
import { NQTDIVIDER , CURRENCY, CURRENCYSELLER, CURRENCYOFFER, EXCHANGERATE} from '../common/constants';
import { validatePassPhrase, validateQuantity } from "../common/validators";
import TextField from "@material-ui/core/TextField";
import { SignActionField } from "../common/signactionfield";
import { OfferUnavailable, DangerWarning } from '../common/common';
import {secretPhraseToPublicKey,signTransactionBytes} from 'ardorjs';
import { TxSuccess } from '../common/txsuccess';

/*
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);
*/

export class BuyGiftz extends Component {
    constructor(props){
        super(props);
        this.state={
            sellOffers:[],
            buyOffers:[],
            offerAvailable:false,
            numGiftz:1,
            passPhraseStatus:{invalid:false,error:null},
            numGiftzStatus:{invalid:false,error:null},
            submitStatus:{invalid:false,error:""},
            formValid:false,
            bought:false
        }
        this.refresh = this.refresh.bind(this);
        this.buyGiftz = this.buyGiftz.bind(this);
        this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
        this.handleNumGiftzChange = this.handleNumGiftzChange.bind(this);
        this.validateForm = this.validateForm.bind(this);        
    }

    refresh(){
        var self = this;
        getCurrencySellOffers(this.props.nodeurl,CURRENCY, CURRENCYSELLER)
        .then(function(response){
            console.log(response);
            const tarascaOffer = response.offers.find((offer)=>(offer.offer === CURRENCYOFFER));
            const offerAvailable = tarascaOffer ? true : false;
            console.log(offerAvailable);
            self.setState({sellOffers:response.offers, offerAvailable:offerAvailable});
        })
        .catch(function(error) {console.log(error)});
      }

    componentDidMount(){
        this.refresh()
        console.log("BuyGiftz: start refresh");
        this.timer = setInterval(this.refresh, 12000)
      }
  
    componentWillUnmount(){
        console.log("BuyGiftz: stop refresh");
        clearInterval(this.timer);    
      }

    handleNumGiftzChange(event) {
        let value = event.target.value;
        let availableFunds = Math.min(
          this.props.wallet.balanceNQT,
          this.props.wallet.unconfirmedBalanceNQT
        );
            
        let max = Math.floor((availableFunds / NQTDIVIDER)/EXCHANGERATE);
        let min = 1;
        if (max >= 1.0) {
        this.setState({ numGiftz: value }, () => {
            let fieldStatus = validateQuantity(
            value,
            max,
            min,
            this.state.numGiftzStatus
            );
            //debugger;
            this.setState({ numGiftzStatus: fieldStatus }, this.validateForm);
        });
        } else {
        this.setState({ numGiftz: value }, () => {
            let fieldStatus = {
            invalid: true,
            error: "Not enough funds to buy a GIFTZ",
            };
            this.setState({ numGiftzStatus: fieldStatus }, this.validateForm);
        });
        }
        this.setState({ numGiftz: event.target.value });
         
      }

    validateForm() {
      this.setState({
        formValid:
          (this.state.numGiftzStatus.invalid === false)
      });
    }

    handlePassphraseChange(event) {
      let value = event;
      this.setState(
        { passPhrase: value },
        () => {
          let fieldStatus = validatePassPhrase(value, this.state.passPhraseStatus);
          this.setState({ passPhraseStatus: fieldStatus }, this.validateForm);
        }
      );
    }

    buyGiftz() {
        var self = this;

        if(!self.state.formValid){
          self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
          return;
        }
        else {
          self.setState({submitStatus:{invalid:false, error:""}});
        }
        
        let phraseValidated = validatePassPhrase(
          self.state.passPhrase,
          self.state.passPhraseStatus,
          self.props.user.accountRs
        );
        if (phraseValidated.invalid) {
          console.log("buyGiftz(): passphrase invalid, exiting.");
          self.setState({ passPhraseStatus: phraseValidated }, this.validateForm);
          return;
        }
        console.log("buyGiftz(): passphrase ok.");
        //console.log(this.state);
        const message = JSON.stringify({ contract: "IgnisAssetLottery" });
        //let amountNQT = this.state.noPacks * self.packPrice * NQTDIVIDER;


        // from ardorinterface - start
        const publicKey = secretPhraseToPublicKey(self.state.passPhrase);
        var query = {
          chain:2,
          currency:CURRENCY,
          rateNQTPerUnit:EXCHANGERATE*NQTDIVIDER,
          unitsQNT:self.state.numGiftz,
          feeNQT:-1,
          deadline:15,
          broadcast:false,
          publicKey:publicKey
        };
        console.log("query: ",query);
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };
        console.log('get minimumFee');
        const url_sendmoney = this.props.nodeurl+'?requestType=currencyBuy';
        const url_broadcast = this.props.nodeurl+'?requestType=broadcastTransaction';
        return axios.post(url_sendmoney, qs.stringify(query), config)
                .then(function(response) {
                  console.log(response);
                  query.feeNQT = response.data.minimumFeeFQT *response.data.bundlerRateNQTPerFXT*0.00000001;
                  query.broadcast = false;
                  console.log('get transactionBytes');
                  return axios.post(url_sendmoney, qs.stringify(query), config)
                      .then(function(response){
                        console.log(response,self.state.passPhrase);
                        const signed = signTransactionBytes(response.data.unsignedTransactionBytes, self.state.passPhrase);
                        var txdata;
                        if (message !==""){
                          let txattachment = JSON.stringify(response.data.transactionJSON.attachment);
                          txdata = {transactionBytes:signed, prunableAttachmentJSON:txattachment};
                        }
                        else {
                          txdata = {transactionBytes:signed};
                        }
                        console.log("sending signed transaction");
                        return axios.post(url_broadcast, qs.stringify(txdata), config)
                              .then(function(response){
                                self.setState({bought:true});
                                return response;
                              })
                      })
                });
      }

    render(){
        const xyz=this.state.numGiftz*EXCHANGERATE;
        const numGiftz = this.state.numGiftz;
        const numGiftzStatus = this.state.numGiftzStatus;
        const offerAvailable = this.state.offerAvailable;
        
        const form = (
            <form
                onSubmit={(event) => {
                event.preventDefault();
                this.buyGiftz();
                }}
            >
                <Grid container
                    justify="center"
                    alignItems="stretch"
                    direction="column"
                    spacing={0}
                >
                <Grid item style={{ textAlign: "left" }}>
                    <Typography variant="display2">Buy GIFTZ</Typography>
                </Grid>
                <Grid item>
                    <TextField
                    fullWidth
                    id="outlined-number"
                    label="Number of GIFTZ"
                    value={numGiftz}
                    onChange={(event) => this.handleNumGiftzChange(event)}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    error={numGiftzStatus.invalid}
                    helperText={numGiftzStatus.error}
                    />
                </Grid>
                <Grid item style={{ textAlign: "left" }}>
                    <Typography variant="body1">
                    Total: {xyz} IGNIS
                    </Typography>
                </Grid>
                <Grid item>
                  <DangerWarning active={this.state.submitStatus.invalid}>
                    {this.state.submitStatus.error}
                  </DangerWarning>
                </Grid>
                <Grid item>
                    <SignActionField
                    {...this.props}
                    {...this.state}
                    gameaction="buypack" 
                    handlePassphraseChange={this.handlePassphraseChange}
                    action={this.buyPack}
                    />
                </Grid>
                </Grid>
            </form>
        )

        return(
            <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:40}}>
                <Grid container
                    justify="center"
                    alignItems="stretch"
                    direction="column"
                    spacing={24}
                    className="boxed"
                    style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'0px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
                >
                    <Grid item>
                        <Typography variant="h5">Vouchers</Typography>
                        <Typography variant="body1">Treat a friend and send a few GIFTZ their way!</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">Buy 1 GIFTZ for {EXCHANGERATE} IGNIS</Typography>         
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">On-chain offer ID: {CURRENCYOFFER}</Typography>
                      <Typography variant="caption">On-chain currency ID: {CURRENCY}</Typography>
                    </Grid>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>
                      {this.state.bought ? (
                        <TxSuccess/>
                      ):(
                        offerAvailable ? (
                            form
                                ):(
                            <OfferUnavailable offerUnavailable={!offerAvailable}/>
                        )
                      )}
                    </Grid>
                </Grid>
            </div>
            )
    }
}


  
