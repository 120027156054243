
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { transferAsset } from '../common/ardorinterface';
import { validateAddress, validatePassPhrase, validateQuantity } from '../common/validators';
import { sendIgnis } from '../common/ardorinterface';
import { NQTDIVIDER, BUYPACKACCOUNT, CRAFTINGCOMMON, CRAFTINGRARE } from '../common/constants';
import { SignActionField } from '../common/signactionfield';
import { fetchCard } from '../common/common';
import { CardInfoSlim } from '../common/cardinfo';
import { ThumbPlain } from '../carddeck/thumb';
import { Typography } from '@material-ui/core';
import { TxSuccess } from '../common/txsuccess';
import { round } from '../common/common';

import InputAdornment from '@material-ui/core/InputAdornment';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';


const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 0.1,
      opacity: "30%"
    }}
  />
);




export function CraftForm(props) {
  //const ignisAvailableBalance = Math.min(props.wallet.balanceNQT, props.wallet.unconfirmedBalanceNQT) / NQTDIVIDER;
  const MaxIgnisCraft = Math.floor((Math.min(props.wallet.balanceNQT, props.wallet.unconfirmedBalanceNQT) / NQTDIVIDER) / (props.card.rarity==="common" ? 50: 150));
  const MaxCardQNTCraft = (Math.floor((props.card.quantityQNT / 5)));
  const arrayMin = [Math.floor(MaxIgnisCraft), Math.floor(MaxCardQNTCraft)];
  const level = (props.card.rarity==="common") ? "Craft Common to Rare for "+CRAFTINGCOMMON+" Ignis" : "Craft Rare to Epic for "+ CRAFTINGRARE +" Ignis"
  // console.log("min Craft possible: "+Math.min(...arrayMin)+"   IGNIS: "+MaxIgnisCraft+"   CARDS: "+MaxCardQNTCraft);
  // console.log(...arrayMin);


  return (

    <form onSubmit={(event) => { event.preventDefault(); props.handleSendCard() }}>
      <Grid container
        justify="center"
        alignItems="stretch"
        direction="column"
        spacing={24}
      >




        <Grid item>
          <Typography variant="h4">Crafting Cube </Typography>
          <ColoredLine color="white" />
          <Typography>Explanation:</Typography>
          <Typography>
          For every 5 identical cards you sacrifice <br></br> 
          you will be rewarded with a card of higher rarity.<br></br> 
          The crafting costs depend on the sacrificed card's rarity.
          </Typography>
          <ColoredLine color="white" />
          <ThumbPlain card={props.card} index={props.index} width="80px" />
          <CardInfoSlim card={props.card} />
          <ColoredLine color="white" />
        </Grid>

        <Grid item>
          <Typography bold>{level}</Typography>
        </Grid>

        <Grid item>
          <ButtonGroup>
            <Button
              variant="outline-light"
              size="sm"
              onClick={props.decrement}>
              -
          </Button>

            <TextField
              invalid={props.noCardsStatus.invalid}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              name="noCrafts"
              label={"Craft card(s) (max: " + Math.min(...arrayMin) + ")"}
              variant="outlined"
              InputLabelProps={{
                type: "number",
                shrink: true
              }}
              value={props.noCrafts}
              placeholder="No. cards to craft"

            />
            <Button
              variant="outline-light"
              size="sm"
              onClick={props.increment}>
              +
          </Button>

          </ButtonGroup>

        </Grid>


        <Grid item>
          <TextField fullWidth
            invalid={props.noAmountStatus.invalid}
            margin="dense"
            size="small"
            name="noCards"
            label={"Cards to sacrifice"}
            variant="outlined"
            InputLabelProps={{
              type: "number",
              shrink: true
            }}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">{props.card.name}</InputAdornment>
            }}
            id="noCards" onChange={(event) => props.handleNoCardsChange(event)}
            value={props.noCrafts * 5}
            error={props.noCardsStatus.error}
            placeholder="No. cards to craft" />

          <Typography variant="overline" display="block" gutterBottom style={{ color: '#E44D35' }}>{props.noCardsStatus.error}</Typography>
        </Grid>

        <Grid item>
          <TextField fullWidth
            invalid={props.amountNQTStatus.invalid}
            margin="dense"
            name="amountNQT"
            label={"Crafting costs"}
            variant="outlined"
            InputLabelProps={{
              type: "number",
              shrink: true
            }}
            InputProps={{
              readOnly: true,
              startAdornment: <InputAdornment position="start">IGNIS</InputAdornment>
            }}
            id="priceNQTPerShare" onChange={(event) => props.handleAmountChange(event)}
            value={(props.noCrafts * props.amountNQT)}
            error={props.amountNQTStatus.error}
            placeholder="Enter amount to send" />
          <Typography variant="overline" display="block" gutterBottom style={{ color: '#E44D35' }}>{props.amountNQTStatus.error}</Typography>
        </Grid>
        <Grid item>
          <SignActionField  {...props} gameaction="buypack" 
            action={props.handleSendCard}
          />
        </Grid>
      </Grid>
    </form>
  )
}



export class Crafting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: '{}',
      noCards: 5,
      noCrafts: 0,
      amountNQT: CRAFTINGCOMMON,
      amountNQTStatus: { invalid: false, error: "" },
      noCardsStatus: { invalid: false, error: '' },
      noAmountStatus: { invalid: false, error: '' },
      passPhrase: "",
      passPhraseStatus: { invalid: undefined, error: '' },
      receiverRS: BUYPACKACCOUNT,
      receiverRsStatus: { invalid: undefined, error: '' },
      message: "not yet implemented",
      displayQrReader: false
    };



    this.sendCard = this.sendCard.bind(this);
    this.sendCoin = this.sendCoin.bind(this);
    this.refresh = this.refresh.bind(this);

    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);


  }

  checkerFunc = (incr) => {
    // console.log()
    debugger
    let response = { cards: { valid: false, error: "" }, ignis: { valid: false, error: "" } }
    const ignisAvailableBalance = round(Math.min(Number(this.props.wallet.balanceNQT) / NQTDIVIDER, Number(this.props.wallet.unconfirmedBalanceNQT) / NQTDIVIDER), 2);
    const cardAvailableBalance = Math.min(Number(this.state.card.quantityQNT), Number(this.state.card.unconfirmedQuantityQNT));

    if (((this.state.noCrafts + (incr ? incr.inc : 0)) * 5) <= cardAvailableBalance) {
      response.cards.valid = true
      response.cards.error = ""
    } else {
      response.cards.valid = false
      response.cards.error = "not enough cards to craft"
    }
    if (((this.state.noCrafts + (incr ? incr.inc : 0)) * this.state.amountNQT) <= ignisAvailableBalance) {
      response.ignis.valid = true
      response.ignis.error = ""
    } else {
      response.ignis.valid = false
      response.ignis.error = "not enough ignis to craft"
    }
    return response
  }
  handleCardsSend = (incr) => {
    let response = this.checkerFunc(incr)
    if (response.cards.valid && response.ignis.valid) {
      this.sendCoin();
      this.sendCard();
    } else if (!response.cards.valid) {
      this.setState({ noCardsStatus: { invalid: true, error: response.cards.error } })
    }
    else {
      this.setState({ amountNQTStatus: { invalid: true, error: response.ignis.error } })
    }
  }

  increment() {
    let response = this.checkerFunc({ inc: 1 })
    if (response.cards.valid && response.ignis.valid) {
      this.setState(prevState => {
        const noCrafts = prevState.noCrafts + 1
        return {
          noCrafts
        };
      });
    } else if (!response.cards.valid) {
      this.setState({ noCardsStatus: { invalid: true, error: response.cards.error } })
    }
    else {
      this.setState({ amountNQTStatus: { invalid: true, error: response.ignis.error } })
    }
    setTimeout(() => {
      this.setState({ amountNQTStatus: { invalid: false, error: '' }, noCardsStatus: { invalid: false, error: "" } })
    }, 5000);
  }

  decrement() {
    this.setState(prevState => {
      const noCrafts = prevState.noCrafts > 1 ? prevState.noCrafts - 1 : 0

      return {
        noCrafts
      };
    });
  }

  refresh() {
    var self = this;
    fetchCard(this.props.nodeurl, this.props.user.accountRs, this.props.match.params.asset)
      .then((response) => {
        self.setState({ card: response, amountNQT: response.rarity === 'common' ? CRAFTINGCOMMON : CRAFTINGRARE });
      })
      .catch((err) => { console.log(err) });
  }

  componentDidMount() {
    this.refresh();
    this.timer = setInterval(this.refresh, 9000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }



  sendCard(event) {
    var self = this;
    let phraseValidated = validatePassPhrase(self.state.passPhrase, self.state.passPhraseStatus, self.props.user.accountRs);
    if (phraseValidated.invalid) {
      // console.log("sendCard(): passphrase invalid, exiting.")
      self.setState({ passPhraseStatus: phraseValidated }, this.validateForm);
      return;
    }
    // console.log("sendCard(): passphrase ok.")
    // console.log('send '+self.state.noCards+' cards.');
    // console.log('get publicKey');
    const message = JSON.stringify({ contract: "TarascaDAOCardCraft" });
    transferAsset(self.props.nodeurl, self.state.card.asset, self.state.noCards * self.state.noCrafts, self.state.receiverRS, self.state.passPhrase, message, true, 1440, "HIGH")
      .then(function (response) {
        self.setState({ response: response, responseTime: response.data.requestProcessingTime, bought: true, status: "success" });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  sendCoin(event) {
    var self = this;
    let phraseValidated = validatePassPhrase(self.state.passPhrase, self.state.passPhraseStatus, self.props.user.accountRs);
    if (phraseValidated.invalid) {
      // console.log("sendCoin(): passphrase invalid, exiting.")
      self.setState({ passPhraseStatus: phraseValidated }, this.validateForm);
      return;
    }
    // console.log('sendCoin(): passphrase OK, sendIgnis');
    let amountNQT = self.state.amountNQT * NQTDIVIDER;
    const message = JSON.stringify({ contract: "TarascaDAOCardCraft" });

    sendIgnis(this.props.nodeurl, amountNQT * self.state.noCrafts, self.state.receiverRS, this.state.passPhrase, message, true,1440, "HIGH")
      .then(function (response) {
        console.log(response);
        self.setState({ response: response, responseTime: response.data.requestProcessingTime, bought: true, status: "success" });
      })
      .catch(function (error) {
        console.log('ohje sendIgnis (from buyPack Dialog):');
        console.log(error.message);
        self.setState({ status: "ERROR" })
      });

  }


  validateForm() {
    this.setState({ formValid: (this.state.passPhraseStatus.invalid === false) && (this.state.noCardsStatus.invalid === false) && (this.state.noAmountStatus.invalid === false) && (this.state.receiverRsStatus.invalid === false) });
  }

  handlePassphraseChange(event) {
    let value = event;
    this.setState(
      { passPhrase: value },
      () => {
        let fieldStatus = validatePassPhrase(value, this.state.passPhraseStatus);
        this.setState({ passPhraseStatus: fieldStatus }, this.validateForm);
      }
    );
  }

  handleNoCardsChange(event) {
    let value = event.target.value;
    let max = Math.min(this.state.card.quantityQNT, this.state.card.unconfirmedQuantityQNT);
    let min = 5;
    this.setState(
      { noCards: value },
      () => {
        let fieldStatus = validateQuantity(value, max, min, this.state.noCardsStatus);
        this.setState({ noCardsStatus: fieldStatus }, this.validateForm);
      }
    );
  }

  // handlePassphraseChange(event){
  //     this.setState({passPhrase:event.target.value})
  // }

  handleReceiverRsChange(value) {
    //let value = event.target.value;
    this.setState(
      { receiverRS: value },
      () => {
        let fieldStatus = validateAddress(value, this.state.receiverRsStatus);
        this.setState({ receiverRsStatus: fieldStatus }, this.validateForm);
      }
    );
  }


  handleAmountChange(event) {
    let value = event.target.value;
    let max = Math.min(this.props.wallet.balanceNQT, this.props.wallet.unconfirmedBalanceNQT) / NQTDIVIDER;
    let min = 100;
    this.setState(
      { amountNQT: value },
      () => {
        let fieldStatus = validateQuantity(value, max, min, this.state.amountNQTStatus);
        this.setState({ amountNQTStatus: fieldStatus }, this.validateForm);
      }
    );
  }


  toggler(props) {
    this.setState({ bought: false });
    this.props.toggle(!this.props.modalOpen);
  }

  render() {
    console.log(this.state);
    return (
      <div style={{ textAlign: "center", padding: 20, width: "90%", display: "inline-block" }}>

        <Grid container
          justify="center"
          alignItems="stretch"
          direction="row"
          spacing={24}
        >

          <Grid item className="boxed" style={{ marginTop: 10, marginBottom: 10, backgroundColor: 'rgb(29 29 29 )', border: '1px solid', borderColor: '#ffffff3b' }}>
            {this.state.bought ? (
              <TxSuccess />
            ) : (
                <CraftForm {...this.state}
                  {...this.props}
                  handleNoCardsChange={(event) => this.handleNoCardsChange(event)}
                  handleAmountChange={(event) => this.handleAmountChange(event)}
                  handlePassphraseChange={(event) => this.handlePassphraseChange(event)}
                  handleReceiverRsChange={(event) => this.handleReceiverRsChange(event)}
                  handleSendCard={this.handleCardsSend}
                  openQRCamera={this.openQRCamera}
                  handleToggle={this.toggler}
                  formValid={this.state.formValid}
                  increment={this.increment}
                  decrement={this.decrement}
                />

              )
            }
          </Grid>
        </Grid>

      </div>
    )
  }
}

