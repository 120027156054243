import React from 'react';
import { getPegAddresses, getEthDepositAddress } from '../common/gameinterface';

import Typography from '@material-ui/core/Typography';
import {Tabs, Tab} from '@material-ui/core';

import {fetchAllCards} from '../common/common';
import { COLLECTIONACCOUNT, TARASCACARDACCOUNT } from '../common/constants';
import { BridgeToPolygonDisplay } from './topolygon';
import { BridgeToArdorDisplay } from './toardor';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (    
    (value === index) ? (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}  
      </div>
    ) : (
      null
    )   
  );
}

const BridgeMainDisplay = ({nodeurl, user, ethDepositAddress, 
                              cards, ardorBlockedAccount }) => {
  //console.log(props);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:40}}>
      <div>
        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label={<Typography variant="body2">Export to Opensea</Typography>} {...a11yProps(0)}></Tab>
          <Tab label={<Typography variant="body2">Import to Mythical Beings</Typography>} {...a11yProps(1)} ></Tab>
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <BridgeToPolygonDisplay 
              {...{nodeurl, user, ardorBlockedAccount, cards}}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BridgeToArdorDisplay {...{user,ethDepositAddress}}/>
      </TabPanel>
    </div>
  );
}


export class BridgeMain extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ethDepositAddress:"",
      ethBlockedAccount:"",
      ardorBlockedAccount:"",
      cards:[],
      pin:""
    }

    this.refresh = this.refresh.bind(this);

    //this.validateForm = this.validateForm.bind(this);
    //this.handlePinChange = this.handlePinChange.bind(this);
  }

  refresh(){
    var self = this;
    fetchAllCards(self.props.nodeurl,self.props.user.accountRs,COLLECTIONACCOUNT,TARASCACARDACCOUNT,false)
    .then(function(cards){
      self.setState({cards:cards});
    })
    .catch(function(error) {console.log(error)});
  }

  componentDidMount(){
    getEthDepositAddress(this.props.user.accountRs)
    .then((response)=>{
      //console.log(response);
      this.setState({ethDepositAddress:response.depositAddress});
    })

    getPegAddresses()
    .then((response)=>{
      //console.log(response);
      this.setState({ethBlockedAccount:response.ethereumBlockedAccount, ardorBlockedAccount:response.ardorBlockedAccount});
    })
    console.log("Bridge Main mounted: starting refresh");
    this.refresh()
    this.timer = setInterval(this.refresh, 9000);
  }

  componentWillUnmount(){
    console.log("Bridge Main: stop refresh");
    clearInterval(this.timer);
  }

  handlePinChange(value){
    this.setState({pin:value});
  }

  render(){
    //const numColumnStyle = { width: 12};
    //let channels = new Set(this.state.cards.map((card) => {return card.channel}))
    let rarity = new Set(this.state.cards.map((card) => {return card.rarity}))
    rarity.add("special");  // Tarasca is coming!
    /*
    let cards = []
    if (this.state.selectedChannel !== "all") {
      cards = this.state.cards.filter((card) => (card.channel === this.state.selectedChannel));
    }
    else {
      cards = this.state.cards;
    } 

    if (this.state.selectedRarity !== "all") {
      cards = this.state.cards.filter((card) => (card.rarity === this.state.selectedRarity) & (this.props.showAllCards | (card.quantityQNT > 0)));
    }
    else {
      cards = this.state.cards.filter((card) => (this.props.showAllCards | (card.quantityQNT > 0)));
    } 
    */
    //cards.push(this.state.tarascaCard);
    //const max = cards.length;

    return(<BridgeMainDisplay nodeurl={this.props.nodeurl} user={this.props.user} {...this.state} />);        
  }
}