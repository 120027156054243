import React, { Component, Fragment } from "react";
import {Alert} from 'reactstrap';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { decrypt } from "../login/storage";

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { VIEW_ONLY_DISABLES } from "./constants";
import Typography from "@material-ui/core/Typography";


function ViewOnlyDisplay({viewonly_reason="This function is currently disabled"}){
  return (
    <Grid container
        justify="center"
        alignItems="stretch"
        direction="column"
        spacing={24}>
      <Grid item>
        <Alert color="warning">{viewonly_reason}</Alert>
      </Grid>
      <Grid item>
        <Typography variant="body1">Please check the <a href="https://mythicalbeings.io/faq" target="_blank" rel="noreferrer">FAQ</a>, or our <a href="https://mythicalbeings.io/blog" target="_blank" rel="noreferrer">Blog</a> to learn how to be invited!</Typography>
      </Grid>
      <Grid item>
        <Button fullWidth type="submit" variant="outlined" disabled>
            Disabled
        </Button>
      </Grid>
    </Grid>
  );
}

export function PinField({pin,status,onChange,onClick,onClickEye,label,hidden,disabled=false}){
  return (
    <Grid container
        justify="center"
        alignItems="stretch"
        direction="column"
        spacing={24}>
      <Grid item>
        <TextField 
          fullWidth
          id="pin"
          label="Password"
          placeholder="Password"
          value={pin}
          onChange={(event)=>onChange(event.target.value)}
          type={hidden ? 'password' : 'text'} 
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton aria-label="toggle password visibility"  onClick={onClickEye} style={{outline:'none'}}>
                {hidden ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          margin="normal"
          variant="outlined"
          error={status.invalid} 
          helperText={status.error}
          autoComplete="off"           
        />  
      </Grid>
      <Grid item>
        <Button fullWidth type="submit" variant="outlined" onClick={disabled? null : onClick} disabled={disabled}>
            {label}
        </Button>
      </Grid>
    </Grid>
  );
}

export function PassPhraseField({passPhrase,status,onChange,onClick,label}){
  return (
    <Fragment>
        <TextField multiline
          id="passphrase"
          label="Pass Phrase"
          rows="4"
          defaultValue="your pass phrase"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            readOnly: true,
            shrink: true,
          }}
          value={passPhrase}
          onChange={event => onChange(event.target.value)}
          error={status.invalid}
          helperText={status.error} 
          autoComplete="off"
        />
        <Button type="submit" variant="outlined" onClick={onClick}>
            {label}
        </Button>
    </Fragment>
  );
}

// this component is used for every action requiring the passphrase to sign tx
// some of these functions can be disabled, some not.

export class SignActionField extends Component {
  constructor(props){
    super(props);
    this.state = {
      pin:"",
      pinStatus:{invalid:false,error:""},
      hidden:true
    }
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }  

  submit = ()=>{
    if (this.props.user.usePin) {
      console.log("submit() called");
      let passPhrase = "";
      try {
        passPhrase = decrypt(this.props.user.token,this.state.pin);
        this.props.handlePassphraseChange(passPhrase);
      }
      catch(exception) {
        this.setState({pinStatus:{invalid:true,error:exception.message}});
        return false;
      }
    }      
  } 

  render(){
    //TODO onChange and state for pin need to be handled locally.
    const pin = this.props.user.usePin;
    const gameaction = this.props.gameaction;
    const viewonly_reason = this.props.viewonlyReason ? this.props.viewonlyReason : "";
    const label = this.props.label ? this.props.label : "Submit";
    const viewonly = this.props.viewonly  && VIEW_ONLY_DISABLES.find((ga)=>ga===gameaction);
    //console.log(this.props);
    //console.log("sign field set to viewonly",viewonly,gameaction);
    //const viewonly = true;
    // gameaction = trade, erlaubt, find-> true. viewonly-> true    -> submit anzeigen
    // gameaction = craft, nicht erlaubt, find-> false. viewonly-> true    -> submit NICHT anzeigen
    if (viewonly) {
      return(
        <ViewOnlyDisplay viewonly_reason={viewonly_reason} />
      );
    }
    else if (pin) {
      return (
        <PinField pin={this.state.pin} 
                  status={this.state.pinStatus}
                  onChange={(value)=>this.setState({pin:value})}
                  onClick={()=>this.submit()}
                  label={label} 
                  onClickEye={value=>this.toggleShow()} 
                  hidden={this.state.hidden} 
                  disabled={this.props.disabled} 
                  />
      );
    }
    else {
      return (
        <PassPhraseField passPhrase={this.props.passPhrase} 
                      status={this.props.passPhraseStatus}
                      onChange={this.props.handlePassphraseChange}
                      onClick={this.props.action}
                      label={label}
                      />
      )
    }    
  }
}