//txhistory
import React, { Fragment } from 'react';
import {CSSTransitionGroup} from 'react-transition-group';

import { BUYPACKACCOUNT, COLLECTIONACCOUNT, JACKPOTACCOUNT, BRIDGEACCOUNT, NQTDIVIDER, GEMASSET, TARASCACARDACCOUNT, GEMASSETACCOUNT } from './constants';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import './txhistory.css';
import Coins from './images/coins.svg';
import { ImGift } from "react-icons/im";
import { FcUpload } from 'react-icons/fc';
import { FcDownload } from 'react-icons/fc';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import {BiMessageRoundedCheck } from 'react-icons/bi';
import {GiTwoCoins} from 'react-icons/gi';
import Spinner from 'react-bootstrap/Spinner';
import {round} from '../common/common';

import Gem from './images/gems.svg';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);

export function parseSender(tx,contacts=[
      {accountRs:COLLECTIONACCOUNT,name:"Mythical Beings"},
      {accountRs:BRIDGEACCOUNT,name:"Mythical Beings ERC1155 Bridge"},
      {accountRs:BUYPACKACCOUNT,name:"Mythical Beings"},
      {accountRs:TARASCACARDACCOUNT,name:"Mythical Beings"},
      {accountRs:GEMASSETACCOUNT,name:"Mythical Beings"},
      {accountRs:JACKPOTACCOUNT,name:"Mythical Beings"}]) {
  
  const account = tx.senderRS;
  const found = contacts.find((contact)=>{ return contact.accountRs === account});

  //console.log(account,found);
  if (found === undefined) {
    return account;
  }
  else {
    let nameSuffix = "";
    if (tx.attachment.message) {
      //console.log(found,tx.attachment.message);
      const msg = JSON.parse(tx.attachment.message.replace(/\bNaN\b/g, "null"));
      if (msg.submittedBy === "Jackpot" && msg.source === "BLOCK"){
        nameSuffix = " Jackpot"
      }
      else if (msg.submittedBy === "IgnisAssetLottery" && msg.source === "TRANSACTION"){
        nameSuffix = " Shop"
      }
      else if (msg.submittedBy === "TarascaDAOCardCraft"){
        nameSuffix = " Crafting"
      }
      else if (msg.submittedBy === "AssetDistributor"){
        nameSuffix = " Creator"
      }
      else if (msg.submittedBy === "TarascaDaoOmno"){
        nameSuffix = " Morph"
      }
      
    }        
    return found.name+nameSuffix;
  }
}

export function parseRecipient(tx,contacts=[
  {accountRs:COLLECTIONACCOUNT,name:"Mythical Beings"},
  {accountRs:BRIDGEACCOUNT,name:"Mythical Beings ERC1155 Bridge"},
  {accountRs:BUYPACKACCOUNT,name:"Mythical Beings"},
  {accountRs:TARASCACARDACCOUNT,name:"Mythical Beings"},
  {accountRs:GEMASSETACCOUNT,name:"Mythical Beings"},
  {accountRs:JACKPOTACCOUNT,name:"Mythical Beings"}]) {
  const account = tx.recipientRS;
  const found = contacts.find((contact)=>{ return contact.accountRs === account});
  if (found === undefined) {
    return account;
  }
  else if (found.accountRs === BRIDGEACCOUNT) { // fix to handle message to bridge contract
    return found.name;
  }
  else {
    let nameSuffix = "";
    if (tx.attachment.message) {
      
      let msg = null;
      try {
        msg = JSON.parse(tx.attachment.message.replace(/\bNaN\b/g, "null"));
      } catch (error) {
        console.log("🚀 ~ file: txhistory.js:99 ~ error", error)
      }

      if(!msg) return;

      if (msg.contract === "Jackpot"){
        nameSuffix = " Jackpot"
      }
      else if (msg.contract === "IgnisAssetLottery"){
        nameSuffix = " Shop"
      }
      else if (msg.contract === "TarascaDAOCardCraft"){
        nameSuffix = " Crafting"
      }
      else if (msg.contract === "AssetDistributor"){
        nameSuffix = " Creator"
      }
      else if (msg.contract === "TarascaDaoOmno"){
        nameSuffix = " Morph"
      }

    }       
    return found.name+nameSuffix;
  }
    
}

export function parseAccount(account,contacts=[
    {accountRs:COLLECTIONACCOUNT,name:"Mythical Beings"},
    {accountRs:BRIDGEACCOUNT,name:"Mythical Beings"},
    {accountRs:BUYPACKACCOUNT,name:"Mythical Beings"},
    {accountRs:GEMASSETACCOUNT,name:"Mythical Beings"},
    {accountRs:JACKPOTACCOUNT,name:"Mythical Beings"}]) {

  const found = contacts.find((contact)=>{ return contact.accountRs === account});
  if (found === undefined) {
  return account;
  }
  else {        
    return found.name;
  }
}


export function TimeStamp({tx,eb}){
  //console.log(eb);
  var txstamp = new Date(eb.getTime());
  //console.log(txstamp);
  txstamp.setSeconds(+txstamp.getSeconds()+tx.timestamp);
  //console.log(txstamp);
  let status = "unconfirmed";
  if (tx.confirmations === 1){
    status = "just confirmed";
  }
  else if (tx.confirmations > 1){
    status = "confirmed"
  }
  const Month = txstamp.getMonth()+1;
  const datestring = txstamp.getFullYear().toString()+"-"+ Month.toString().padStart(2,"0") + "-"+txstamp.getDate().toString().padStart(2,"0");
  const timestring = txstamp.getHours().toString().padStart(2,"0") + ":" + txstamp.getMinutes().toString().padStart(2,"0")+ ":" + txstamp.getSeconds().toString().padStart(2,"0");

  return(
    <Fragment>
      <Typography>
        
        {datestring} {timestring} ({status})
        
        </Typography>
    </Fragment>
  )
}


function AssetExchange({tx,card,eb,order,type,badge}){
  //console.log(card);
  const card_print = card ? card : {name:"",channel:"",rarity:""};
  const rarity_print = (card_print.rarity === "very rare") ? "epic" : card_print.rarity;
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <CgArrowsExchangeAlt style={{fontSize:'28', color:'orange'}}/>
              {type}: {order} • {card_print.name}
              <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>

        <Grid item xs={4}>
              <img src={card_print.cardThumbUrl} alt="card img" width="100px" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
                
                display: type ==='Cancelled' ? 'none' : '',
              }}/>   
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <Typography>Quantity • {tx.attachment.quantityQNT}</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Rarity • {rarity_print}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Sender • You</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}
function CancelledOrder({tx, eb, type, order, badge}){
  //console.log(card);
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <CgArrowsExchangeAlt style={{fontSize:'28', color:'orange'}}/>
              {type} {order} 
              <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                                 
        </Grid>
        <Grid item xs={4}>
              &nbsp;  
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}> 
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>          
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}
function IncomingCardTransferMobile({tx,card,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcDownload/> {card.name} 
                <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>      
              <img src={card.cardThumbUrl} alt="card img" width="100px" 
              style={{         
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
                }}/>                  
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <Typography>Quantity • {tx.attachment.quantityQNT}</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Rarity • {card.rarity === 'very rare' ? 'epic' : card.rarity}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Sender • {parseSender(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingGem({tx,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcDownload/> GEM 
                <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>      
              <img src={Gem} alt="card img" width="72px"/>                  
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <Typography>Quantity • {round(tx.attachment.quantityQNT/NQTDIVIDER,2)}</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Sender • {parseSender(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}
/*
function IncomingCardTransfer({tx,card,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={16}>
        <Grid item xs={3}>
          <img src={card.cardThumbUrl} alt="card img"
            width="80px"  
            style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>                   
        </Grid>
        <Grid item xs={9} container direction="column" alignItems="flex-start" spacing={8}>
            <Grid item xs={12}>
                  <Typography variant="h6">
                    <FcDownload/> {card.name} 
                    <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
                  </Typography>
            </Grid>
            <Grid item xs={12} container direction="row">

              <Grid item xs={12} sm={6} container direction="column" alignItems="flex-start">
                <Grid item>
                  <Typography>Quantity: {tx.attachment.quantityQNT}</Typography>
                </Grid>
                <Grid item> 
                  <TimeStamp tx={tx} eb={eb}/>
                </Grid>
              </Grid>
            
              <Grid item xs={12} sm={6} container direction="column" alignItems="flex-start">                
                <Grid item>            
                  <Typography>Recipient: You</Typography>
                </Grid>
                <Grid item>
                  <Typography>Sender: {parseSender(tx)}</Typography>
                </Grid>
              </Grid>

          </Grid>
        </Grid>
        
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}


function OutgoingCardTransfer({tx,card,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={16}>
        <Grid item xs={3}>
          <img src={card.cardThumbUrl} alt="card img"
            width="80px"  
            style={{opacity:card.quantityQNT > 0 ? 1 : 0.1}}/>        
               
        </Grid>
        <Grid item xs={9} container direction="column" alignItems="flex-start" spacing={8}>
            <Grid item xs={12}>
                  <Typography variant="h6" align="left">
                    <FcUpload/> {card.name} 
                    <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
                  </Typography>
            </Grid>
            <Grid item xs={12} container direction="row">

              <Grid item xs={6} container direction="column" alignItems="flex-start">
                <Grid item>
                  <Typography>Amount: {tx.attachment.quantityQNT}</Typography>
                </Grid>
                <Grid item> 
                  <TimeStamp tx={tx} eb={eb}/>
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" alignItems="flex-start">
                <Grid item>
                  <Typography>Sender: You</Typography>
                </Grid>
                <Grid item>            
                  <Typography>Recipient: {parseRecipient(tx)}</Typography>
                </Grid>
              </Grid>

          </Grid>
        </Grid>
        
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}
*/
function OutgoingCardTransferMobile({tx,card,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcUpload/> {card.name} 
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>
          <img src={card.cardThumbUrl} alt="card img" width="100px" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1',
                
              }}/>   
                      
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <Typography>Quantity • {tx.attachment.quantityQNT}</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Rarity • {card.rarity === 'very rare' ? 'epic' : card.rarity}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Recipient • {parseRecipient(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
  )
}

function OutgoingGem({tx,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcUpload/> GEM 
                <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>      
              <img src={Gem} alt="card img" width="72px"/>                  
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <Typography>Quantity • {round(tx.attachment.quantityQNT/NQTDIVIDER,2)}</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Recipient • {parseRecipient(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function OutgoingCurrencyTransfer({tx,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcUpload/> {tx.attachment.unitsQNT} GIFTZ
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>       
        <Typography><ImGift style={{ fontSize: 60, marginBottom:5 }}/></Typography>
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Recipient • {parseRecipient(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingGiftzOrder({tx,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            {tx.attachment.unitsQNT} GIFTZ buy order
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>
              <Typography><ImGift style={{ fontSize: 60, marginBottom:5 }}/></Typography>
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Order created</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingCurrencyTransfer({tx,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcDownload/> {tx.attachment.unitsQNT} GIFTZ
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>                
        </Grid>
        <Grid item xs={4}>
              <Typography><ImGift style={{ fontSize: 60, marginBottom:5 }}/></Typography>
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item> 
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>            
            <Typography align="left">Sender • {parseSender(tx)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}


function OutgoingMoneyTransfer({tx,currency,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            <FcUpload/>  {(tx.amountNQT/NQTDIVIDER).toFixed(1)} IGNIS
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>
        </Grid>        
        <Grid item xs={4}>
          <img src={Coins} alt="Coins" width={"50px"}/> 
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
            <TimeStamp tx={tx} eb={eb}/>
          </Grid>
          <Grid item>
            <Typography>Recipient • {parseRecipient(tx)}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingMoneyTransfer({tx,currency,eb,badge}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
              <Typography variant="h6" align="left">
                <FcDownload/> {(tx.amountNQT/NQTDIVIDER).toFixed(1)} IGNIS 
                <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
              </Typography>
        </Grid>  
        <Grid item xs={4}>
          <img src={Coins} alt="Coins" width={"50px"}/> 
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
                <Grid item>
                  <TimeStamp tx={tx} eb={eb}/>
                </Grid>
                <Grid item>
                  <Typography>Sender • {parseSender(tx)}</Typography>
                </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingMoneyTransferReason({tx,currency,eb,badge,reason=""}){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
              <Typography variant="h6" align="left">
                <FcDownload/> {(tx.amountNQT/NQTDIVIDER).toFixed(1)} IGNIS {reason}
                <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
              </Typography>
        </Grid> 
        <Grid item xs={4}>
          <img src={Coins} alt="Coins" width={"50px"}/> 
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
                <Grid item>
                  <TimeStamp tx={tx} eb={eb}/>
                </Grid>
                <Grid item>
                  <Typography>Sender • {parseSender(tx)}</Typography>
                </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function IncomingMessage({tx,msgtype,eb,badge}){
  //console.log(tx);
  const PARTICIPATION = "Our Jackpot contract confirmed your participation.";
  const WONJACKPOT = "You won a share of the Jackpot!"

  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left"><FcDownload/> 
            Message 
            <Badge color="secondary" style={{textAlign:"left"}} invisible={!badge} variant="dot">&nbsp;</Badge>
          </Typography>
        </Grid>  
        <Grid item xs={4}>
          {(msgtype === "jackpot") ? (
              <GiTwoCoins size={38} style={{color:'white'}}/>
            ):(          
              <BiMessageRoundedCheck size={38} style={{color:'white'}}/>
            )}
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
                <Grid item>
                  <TimeStamp tx={tx} eb={eb}/>
                </Grid>
                <Grid item>
                  <Typography>{(msgtype === "jackpot") ? (WONJACKPOT) : (PARTICIPATION)}</Typography>
                </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

function UnconfirmedTransactions(){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={4}>
          <Spinner animation="border" role="status" variant="light">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" align="left">New transactions are being confirmed</Typography>
        </Grid>  
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
    </Grid>
  )
}

export function TxHistory (props) {
  
  const unconfirmed = (props && props.unconfirmedTransactions && props.unconfirmedTransactions.length > 0);
  
  //console.log(props.blockchainTransactions);
  let txs = null;
  if(props && props.blockchainTransactions){
    txs = props.blockchainTransactions.map((tx,index) => {
      const badge = tx.blockTimestamp > props.lastUpdate.timestamp;
      //console.log("type: "+tx.type+", subtype: "+tx.subtype);
      //console.log("current tx:"+tx.blockTimestamp+" storedTimestamp:"+props.lastUpdate.timestamp+" badge:"+badge);
      if (tx.type === 2 & tx.subtype === 1 & tx.recipientRS === props.user.accountRs){
        //Asset Transfer 
        //console.log(tx);
        if (tx.attachment.asset === GEMASSET){
          // here comes the gem.
          return (
            <div className="transaction" key={tx.fullHash}>            
                <IncomingGem tx={tx} eb={props.epoch_beginning} badge={badge}/>            
            </div>
          )
        }
        else {
          const card = props.collectionCardsStatic.find((card)=>(card.asset === tx.attachment.asset))
          if (card) {
            return (
              <div className="transaction" key={tx.fullHash}>
                  <IncomingCardTransferMobile tx={tx} card={card} eb={props.epoch_beginning} badge={badge}/>              
              </div>
            )
          }
          else return null;
        }
      }
      else if (tx.type === 2 & tx.subtype === 1 & tx.senderRS === props.user.accountRs){
        //Asset Transfer
        if (tx.attachment.asset === GEMASSET){
          return (
            <div className="transaction" key={tx.fullHash}>            
                <OutgoingGem tx={tx} eb={props.epoch_beginning} badge={badge}/>            
            </div>
          )
        }
        else {      
          const card = props.collectionCardsStatic.find((card)=>(card.asset === tx.attachment.asset))
          if (card) {
            return (
              <div className="transaction" key={tx.fullHash}>
                  <OutgoingCardTransferMobile tx={tx} card={card} eb={props.epoch_beginning} badge={badge}/>
              </div>
            )
          }
          else return null;
        }
      }
      else if (tx.type === 2 & tx.subtype === 2) {
        // place ask order
        //Asset Transfer
        const card = props.collectionCardsStatic.find((card)=>(card.asset === tx.attachment.asset))
        if (card) {
          return (
            <div className="transaction" key={tx.fullHash}>
              <AssetExchange tx={tx} card={card} eb={props.epoch_beginning} type="Placed" order="Ask Order" badge={badge}/>
            </div>
          )
        }
        else return null;
      }
      else if (tx.type === 2 & tx.subtype === 3) {
        // place bid order
        //Asset Transfer
        const card = props.collectionCardsStatic.find((card)=>(card.asset === tx.attachment.asset))
        if (card) {
          return(
            <div className="transaction" key={tx.fullHash}>
              <AssetExchange tx={tx} card={card} eb={props.epoch_beginning} type="Placed" order="Bid Order" badge={badge}/>
            </div>
          )
        }
        else return null;
      }
      else if (tx.type === 2 & tx.subtype === 4) {
        // cancel ask order
        //Asset Transfer
        return(
          <div className="transaction" key={tx.fullHash}>
            <CancelledOrder tx={tx} eb={props.epoch_beginning} type="Cancelled" order="Ask Order" badge={badge}/>
          </div>
        )
      }
      else if (tx.type === 2 & tx.subtype === 5) {
        // cancel bid order
        //Asset Transfer
        return(
          <div className="transaction" key={tx.fullHash}>
            <CancelledOrder tx={tx} eb={props.epoch_beginning} type="Cancelled" order="Bid Order" badge={badge}/>
          </div>
        )      
      }
      else if (tx.type === 5 & tx.subtype === 3){
        // Currency Transfer 
        if (tx.senderRS === props.user.accountRs){
          //outgoing
          return(
            <div className="transaction" key={tx.fullHash}>
              <OutgoingCurrencyTransfer tx={tx} eb={props.epoch_beginning} badge={badge}/>
            </div>
          )
        }
        else {
          // incoming
          return(
            <div className="transaction" key={tx.fullHash}>
              <IncomingCurrencyTransfer tx={tx} eb={props.epoch_beginning} badge={badge}/>
            </div>
          )
        }
      }
      else if (tx.type === 0 & tx.subtype === 0){
        //console.log(tx);
        // Ordinary Payment (IGNIS TX)
        if (tx.senderRS === props.user.accountRs){
          //outgoing
          return(
            <div className="transaction" key={tx.fullHash}>
              <OutgoingMoneyTransfer tx={tx} eb={props.epoch_beginning} badge={badge}/>
            </div>
          )
        }
        else if (tx.recipientRS === props.user.accountRs & tx.senderRS === JACKPOTACCOUNT) {
          //console.log(tx);
          // incoming from Jackpot, maybe the prize? Is there a message?
          if (tx.attachment.message) {
            const msg = JSON.parse(tx.attachment.message.replace(/\bNaN\b/g, "null"));
            let reason = "";
            if (msg.reason === "referral"){
              reason = "- Referral Pay";
            }
            else if(msg.reason === "confirmParticipation"){
              reason = "participation confirmed - should not occur with type 0 subtype 0";
            }
            else if(msg.reason === "sendPrize"){
              reason = "- Jackpot Prize";
            }
            if (msg.submittedBy === "Jackpot" && msg.source === "BLOCK"){
              return(
                <div className="transaction" key={tx.fullHash}>
                  <IncomingMoneyTransferReason tx={tx} eb={props.epoch_beginning} badge={badge}/>
                  <IncomingMessage tx={tx} eb={props.epoch_beginning} badge = {badge} msgtype={"jackpot"}/>  
                </div>
              )
            }
            else {            
              return(
                <div className="transaction" key={tx.fullHash}>
                  <IncomingMoneyTransferReason tx={tx} eb={props.epoch_beginning} badge={badge} reason={reason}/>                
                </div>);
              }
          }        
          else {
            // incoming
            return(
              <div className="transaction" key={tx.fullHash}>
                <IncomingMoneyTransfer tx={tx} eb={props.epoch_beginning} badge={badge}/>
              </div> );
          }  
        }
        else return (
          <div className="transaction" key={tx.fullHash}>
            <IncomingMoneyTransfer tx={tx} eb={props.epoch_beginning} badge={badge}/>
          </div>
        )    
      }
      else if (tx.type === 1 & tx.subtype === 0){
        // Message
        //console.log(tx);
        if (tx.recipientRS === props.user.accountRs & tx.senderRS === JACKPOTACCOUNT) {
          // message incoming from the jackpot contract
          const msg = JSON.parse(tx.attachment.message.replace(/\bNaN\b/g, "null"));
          if (msg.reason === "confirmParticipation") {
            //console.log(msg);
            return (
              <div className="transaction" key={tx.fullHash}>
                <IncomingMessage tx={tx} eb={props.epoch_beginning} badge = {badge} msgtype={"participation"}/>
              </div>
            )
          }
          else {
            console.log("txhistory.js:unhandled message received.");
            return null;
          }
        }
        else return null;
      }
      else if (tx.type === 5 && tx.subtype === 5 && tx.senderRS === props.user.accountRs){
        return(
          <div className="transaction" key={tx.fullHash}>
            <IncomingGiftzOrder tx={tx} eb={props.epoch_beginning} badge = {badge}/>
          </div>
          )
      }    
      else return null;
    })
  }

  return (
    <div style={{textAlign:"center", padding:5, width:"100%", display:"inline-block",   justifyContent:'center', alignItems:'center'}}>
      <Grid container
        justify="center"
        alignItems="stretch"
        direction="column"
        spacing={16}
      >
        <Grid item>
          <Typography variant="h3" style={{marginBottom:20}}>HISTORY</Typography>
          <ColoredLine color="white"/>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={()=>{props.resetNewTx()}} style={{marginBottom:20}}>mark all as read</Button>
          <Typography style={{marginBottom:20}}><FcUpload/> sent  &nbsp;&nbsp;&nbsp; <FcDownload/> received</Typography>
        </Grid>
        <Grid item style={{marginTop:20}}>
          <Grid container
            justify="center"
            alignItems="flex-start"
            direction="row"
            spacing={24}
          >  
            <Grid item className="boxed" style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
              <CSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
              >
                { unconfirmed ? (
                  <div className="transaction">
                    <UnconfirmedTransactions/>
                  </div>
                  ) : (
                    null
                  )
                }
                {txs}
              </CSSTransitionGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )    
}
