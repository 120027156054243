//sendmoney

import React, { Component } from 'react';
import {sendIgnis} from './ardorinterface';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {validateAddress,validatePassPhrase,validateQuantity} from './validators';

import {SignActionField} from './signactionfield';
import {QrAccountField} from './accountfield';
import { NQTDIVIDER } from './constants';
import {TxSuccess} from './txsuccess';
import { Typography } from '@material-ui/core';
import { round, DangerWarning } from './common';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);


function SendCoinsForm(props){
  const ignisAvailableBalance = Math.min(props.wallet.balanceNQT,props.wallet.unconfirmedBalanceNQT)/NQTDIVIDER;
  return(
    <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop: 40}}>
      <form onSubmit={(event)=>{event.preventDefault();props.handleSendCoin()}}>
        <Grid container
          justify="center"
          alignItems="stretch" 
          direction="column"
          spacing={24}           
          className="boxed"
          style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'1px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >
          <Grid item style={{textAlign:"center"}}>
            <Typography variant="h4">SEND IGNIS</Typography>      
            <ColoredLine color="white" />
            <Typography variant="caption" color="secondary">Please do not send any IGNIS to an exchange address within this wallet. </Typography>  
            <Typography variant="caption" color="secondary">Transactions to exchanges often require an additional message. Please use the official ARDOR Wallet for that.</Typography>     
            <Typography variant="h6"></Typography>     
          </Grid>
          <Grid item>
              <TextField fullWidth
                  invalid={props.amountNQTStatus.invalid} 
                  type="number" 
                  name="amountNQT"
                  label={"Amount to send (max:"+round(ignisAvailableBalance,2)+")"}
                  variant="outlined"
                  InputLabelProps={{
                    type:"number",
                    shrink: true
                  }}
                  id="priceNQTPerShare" onChange={(event) => props.handleAmountChange(event)}
                  value={props.amountNQT}
                  error={props.amountNQTStatus.error} 
                  helperText={props.amountNQTStatus.error} 
                  placeholder="Enter amount to send" />
            
          </Grid>
          <Grid item>
              <QrAccountField value={props.receiverRS} 
                  status={props.receiverRsStatus} 
                  onChange={props.handleReceiverRsChange}
                  >
                Recipient (ARDOR-XXXX-XXXX-XXXX-XXXXX)
              </QrAccountField> 
            
          </Grid>
        <Grid item>
          <DangerWarning active={props.submitStatus.invalid}>
            {props.submitStatus.error}
          </DangerWarning>
        </Grid>
          <Grid item>
            
              <SignActionField  {...props}  gameaction="send" 
                        action={props.handleSendCoin}
                      />
            
            </Grid>
          </Grid>
      </form>
    </div>
    )
}


export class SendIgnis extends Component {
  constructor (props){
    super(props);
    this.state = {
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''},
      receiverRS:"",
      receiverRsStatus:{invalid:undefined,error:''},
      amountNQT:1.00,
      amountNQTStatus:{invalid:false,error:""},
      message:"not yet implemented",
      bought:false,
      formValid: false,
      submitStatus:{invalid:false,error:""},
      displayQrReader:false
    };
    this.sendCoin = this.sendCoin.bind(this);
    //this.readQR = this.sendCard.bind(this);
    //this.openQRCamera = this.openQRCamera.bind(this);
    this.toggler = this.toggler.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.handleReceiverRsChange = this.handleReceiverRsChange.bind(this);
  }

  sendCoin(event) {
      var self = this;

      if(!self.state.formValid){
        self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
        return;
      }
      else {
        self.setState({submitStatus:{invalid:false, error:""}});
      }

      let phraseValidated = validatePassPhrase(self.state.passPhrase,self.state.passPhraseStatus,self.props.user.accountRs);
      if  (phraseValidated.invalid){
        console.log("sendCoin(): passphrase invalid, exiting.")
        self.setState({passPhraseStatus:phraseValidated},this.validateForm);
        return;
      }

      console.log('sendCoin(): passphrase OK, sendIgnis');
      let amount = self.state.amountNQT*NQTDIVIDER;
      sendIgnis(self.props.nodeurl, amount, self.state.receiverRS, self.state.passPhrase, "")
          .then(function(response){
            self.setState({response:response,responseTime:response.data.requestProcessingTime,bought:true,status:"success"});
          })
          .catch(function(error) {
            console.log("error caught!");
            console.log(error);
          });

  }

  validateForm() {
    this.setState({formValid:
      (this.state.amountNQTStatus.invalid===false) && 
      (this.state.receiverRsStatus.invalid===false)
    });
  }

  handlePassphraseChange(value){
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }

  handleAmountChange(event){
    let value = event.target.value;
    //console.log(value);
    let max=Math.min(this.props.wallet.balanceNQT,this.props.wallet.unconfirmedBalanceNQT)/NQTDIVIDER;
    let min=1;
    this.setState(
      {amountNQT:value},
      ()=>{let fieldStatus = validateQuantity(value,max,min,this.state.amountNQTStatus);
            this.setState({amountNQTStatus:fieldStatus},this.validateForm);}
    );
  }

  handleReceiverRsChange(value){
      this.setState(
          {receiverRS:value},
          ()=>{let fieldStatus = validateAddress(value,this.state.receiverRsStatus);
              this.setState({receiverRsStatus:fieldStatus},this.validateForm);}
      );
  }

  toggler(props){
    this.setState({bought:false});
    this.props.toggleModal();
  }

  render(){
    if (!this.state.bought){
      return (
          <SendCoinsForm amountNQT={this.state.amountNQT}
                    amountNQTStatus={this.state.amountNQTStatus}
                    passPhrase={this.state.passPhrase}
                    passPhraseStatus={this.state.passPhraseStatus}
                    receiverRS={this.state.receiverRS}
                    receiverRsStatus={this.state.receiverRsStatus}
                    handleAmountChange={(event) => this.handleAmountChange(event)}
                    handlePassphraseChange={(event)=> this.handlePassphraseChange(event)}
                    handleReceiverRsChange={(event)=> this.handleReceiverRsChange(event)}
                    handleSendCoin={()=>this.sendCoin()}
                    openQRCamera={this.openQRCamera}
                    handleToggle={this.toggler}
                    wallet={this.props.wallet}
                    NQTdivider={this.props.NQTdivider}
                    formValid={this.state.formValid} 
                    submitStatus={this.state.submitStatus} 
                    {...this.props}/>
      )
    }
    else {
      return (<TxSuccess responseTime={this.state.responseTime}/>)
    }
  }
}


