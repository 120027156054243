
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {transferAsset} from './ardorinterface';
import {validateAddress,validatePassPhrase,validateQuantity} from './validators';

import {SignActionField} from './signactionfield';
import {QrAccountField} from './accountfield';
import { fetchCard, DangerWarning } from './common';
import { Typography } from '@material-ui/core';
import {TxSuccess} from './txsuccess';
import { NQTDIVIDER, GEMASSET} from '../common/constants';
import { round } from '../common/common';
import Gem from './images/gems.svg';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);



export function SendGEMForm(props){
  
  return(
    
    <div style={{textAlign:"center", padding:20, width:"100%", maxWidth:"540px", display:"inline-block", paddingTop: 20}}>
    <form onSubmit={(event)=>{event.preventDefault();props.handleSendCard()}}>
    <Grid container
          justify="center"
          alignItems="stretch" 
          direction="column"
          spacing={24}           
          className="boxed"
          style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'1px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >
          <Grid item style={{textAlign:"center"}}>
          <br />
          <img src={Gem} alt="Gem" width="13%" />
          <br /><br />
          <Typography variant="h4">Send GEM</Typography>      
          <ColoredLine color="white" /> 
        </Grid>
        <Grid item>
          <TextField fullWidth 
                  invalid={props.noCardsStatus.invalid} 
                  type="number" 
                  name="noGEM"
                  label={"Amount to sent (max: "+round(Math.min(props.walletGem.quantityQNT/NQTDIVIDER,props.walletGem.unconfirmedQuantityQNT/NQTDIVIDER),2)+")"}
                  variant="outlined"
                  InputLabelProps={{
                    type:"number",
                    shrink: true
                  }}
                  id="noGEM" onChange={(event) => props.handleNoCardsChange(event)}
                  value={props.noCards} 
                  error={props.noCardsStatus.invalid} 
                  helperText={props.noCardsStatus.error} 
                  placeholder="Amount of GEM you want to send" />
          
        </Grid>
        <Grid item>
          <QrAccountField value={props.receiverRS} 
                          status={props.receiverRsStatus} 
                          onChange={props.handleReceiverRsChange}>
            Recipient (ARDOR-XXXX-XXXX-XXXX-XXXXX)
          </QrAccountField>             
        </Grid>
        <Grid item>
          <DangerWarning active={props.submitStatus.invalid}>
            {props.submitStatus.error}
          </DangerWarning>
        </Grid>
        <Grid item>
          <SignActionField  {...props} gameaction="sendCard"
                            action={props.handleSendCard}
                            />
        </Grid>      
      </Grid>
    </form>
    </div>
  )
}



export class SendGEM extends Component {
  
  constructor (props){
    console.log(props);
    super(props);
    this.state = {
      card:{GEMASSET},
      noCards:0,
      noCardsStatus:{invalid:false,error:''},
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''},
      receiverRS:"",
      receiverRsStatus:{invalid:undefined,error:''},
      message:"not yet implemented",
      displayQrReader:false,
      formValid: false,
      submitStatus:{invalid:false,error:""}
    };
    this.sendCard = this.sendCard.bind(this);
    this.refresh = this.refresh.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleNoCardsChange = this.handleNoCardsChange.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.handleReceiverRsChange = this.handleReceiverRsChange.bind(this);
  }

  refresh(){
    var self = this;
    fetchCard(this.props.nodeurl,this.props.user.accountRs,this.props.match.params.asset)
    .then((response)=>{
      self.setState({card:response});
    })
    .catch((err)=>{console.log(err)});
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }


  sendCard(event) {
      var self = this;
      //console.log(self);

      if(!self.state.formValid){
        self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
        return;
      }
      else {
        self.setState({submitStatus:{invalid:false, error:""}});
      }

      let phraseValidated = validatePassPhrase(self.state.passPhrase,self.state.passPhraseStatus,self.props.user.accountRs);
      if  (phraseValidated.invalid){
        console.log("sendCard(): passphrase invalid, exiting.")
        self.setState({passPhraseStatus:phraseValidated},this.validateForm);
        return;
      }

      console.log("sendCard(): passphrase ok.")
      console.log('send '+self.state.noCards+' cards.');
      console.log('get publicKey');
      
      transferAsset(self.props.nodeurl,GEMASSET,self.state.noCards* NQTDIVIDER,self.state.receiverRS,self.state.passPhrase)
      .then(function(response) {
        self.setState({response:response,responseTime:response.data.requestProcessingTime,bought:true,status:"success"});
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  validateForm() {
    this.setState({formValid: (
      (this.state.noCardsStatus.invalid===false) && 
      (this.state.receiverRsStatus.invalid===false)
      )
    });
  }

  handlePassphraseChange(event){
    let value = event;
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }

  handleNoCardsChange(event){

    const {walletGem} = this.props;
    const gemAvailableBalance = round(Math.min(walletGem.quantityQNT/NQTDIVIDER,walletGem.unconfirmedQuantityQNT/NQTDIVIDER),2);

    let value = event.target.value;
    let max= gemAvailableBalance;
    //console.log(max);
    let min=1;
    this.setState(
      {noCards:value},
      ()=>{let fieldStatus = validateQuantity(value,max,min,this.state.noCardsStatus);
            this.setState({noCardsStatus:fieldStatus},this.validateForm);}
    );
  }

  // handlePassphraseChange(event){
  //     this.setState({passPhrase:event.target.value})
  // }

  handleReceiverRsChange(value){
      //let value = event.target.value;
      this.setState(
          {receiverRS:value},
          ()=>{let fieldStatus = validateAddress(value,this.state.receiverRsStatus);
              this.setState({receiverRsStatus:fieldStatus},this.validateForm);}
      );
  }

  toggler(props){
    this.setState({bought:false});
    this.props.toggle(!this.props.modalOpen);
  }

  render(){
    //console.log(this.state);
    return(


        
          <Grid item>
          { this.state.bought ? (
              <TxSuccess/>
            ):(
              <SendGEMForm {...this.state}
                      {...this.props}
                      handleNoCardsChange={(event) => this.handleNoCardsChange(event)}
                      handlePassphraseChange={(event)=> this.handlePassphraseChange(event)}
                      handleReceiverRsChange={(event)=> this.handleReceiverRsChange(event)}
                      handleSendCard={()=>this.sendCard()}
                      openQRCamera={this.openQRCamera}
                      handleToggle={this.toggler}
                      formValid={this.state.formValid}
                      submitError={this.state.submitError}/>
            )
          }          
          </Grid>

     
    )
  }
}

