import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {Claim} from './claim';
import { getIgnisBalance } from '../common/ardorinterface';
import { getCurrentWinners } from '../common/gameinterface';
import {fetchJackpotState, fetchCard} from '../common/common';
import { CheckProgress } from './progress';
import { NQTDIVIDER, COLLECTIONACCOUNT, JACKPOTACCOUNT, JACKPOTHALF, LOWIGNIS } from '../common/constants';
import { CountDown } from './countdown';
import { JackpotPriceIGNIS } from '../exchange/pricetracker';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);


function JackpotJumbotron({Jackpot}) {

  const jackbalance = JACKPOTHALF ? 0.5*Jackpot.balanceNQT/NQTDIVIDER : Jackpot.balanceNQT/NQTDIVIDER;
  return(
    <Grid container spacing={8}
      justify="center"
      alignItems="center"
      direction="column"
      className="boxed"
      style={{border:'0px solid', borderColor:'#ffffff3b'}}
    >

    
      <Grid item >

        </Grid> 
      <Grid item> 

        <Typography variant="h4">JACKPOT</Typography>
        <ColoredLine color="white" />
        <Typography variant="h2">{(jackbalance).toFixed(2)} IGNIS</Typography>
        <JackpotPriceIGNIS totalIgnis={jackbalance} />
        <ColoredLine color="white" />
        <Typography variant="h5">in</Typography>

      </Grid>  
    </Grid>  
  )
}


export class Jackpot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Jackpot:{balanceNQT:"",unconfirmedBalanceNQT:""},
      blocked:[],
      missing:[],
      complete:false,
      price: null,
      participationConfirmed: 0,
      IgnisIsLow:false    
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    console.log("JackpotDisplay: refreshing state");
    fetchJackpotState(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT)
    .then(function(response){
      //console.log(response);
      var missingPromises = response.missingAssets.map((asset)=>{
        return fetchCard(self.props.nodeurl,self.props.user.accountRs,asset).then(card=>(card));
      })
      Promise.all(missingPromises).then((missingCards)=>self.setState({missing:missingCards}));

      var userPromises = response.userAssets.map((asset)=>{
        return fetchCard(self.props.nodeurl,self.props.user.accountRs,asset).then(card=>(card));
      })
      Promise.all(userPromises).then((userCards)=>{
        const checkBlockedFunction = (element) => element.quantityQNT > element.unconfirmedQuantityQNT && element.unconfirmedQuantityQNT === 0;
        let blocked = userCards.some(checkBlockedFunction);
        /*
         let blocked = userCards.map((userCard)=>{
          if (userCard.quantityQNT > userCard.unconfirmedQuantityQNT && userCard.unconfirmedQuantityQNT === 0) {
            console.log("jackpot.js: detected a blocked card");
            blocked = true;
          }
        })
        */
        self.setState({blocked: blocked})
      });
      self.setState({complete:response.complete,totalNum:response.totalNum});
    })
    .catch(function(error) {console.log(error)});

    getIgnisBalance(this.props.nodeurl,JACKPOTACCOUNT)
    .then(function(response){
        self.setState({Jackpot:response,loading:false});
    });

    getCurrentWinners()
    .then((response) => {
      if (response.data) {
        //console.log(response.data);
        // can be undefined, 0, or more
        const numParticipations = response.data[self.props.user.accountRs];  
        self.setState({participationConfirmed:numParticipations});
      }
    })

    
    const IgnisIsLow = Math.min(this.props.wallet.unconfirmedBalanceNQT,this.props.wallet.balanceNQT) < (LOWIGNIS*NQTDIVIDER) ? true : false;
    self.setState({IgnisIsLow:IgnisIsLow});
      
  }

  componentDidMount() {
    this.refresh()
    this.timer = setInterval(this.refresh, 12000)
  }

  componentWillUnmount(){
    console.log("Jackpot: stop refresh");
    clearInterval(this.timer);
  }

  render(){
    //const claimActive = (<Button color="alert" onClick={this.toggleClaim}>Claim the Price!</Button>)
    //const claimNotActive = (<Button color="secondary" onClick={this.toggleStatus}>
    //                        Check your progress</Button>)
    // const canClaim = (this.state.complete) & (this.state.missing.length == 0) & (this.state.blocked.length == 0)
    // displays the buttons to claim the pot. removed for now
    //const output =  canClaim ? claimActive : claimNotActive;
    const canclaim = this.state.complete & !this.state.blocked & !this.state.IgnisIsLow;
    return(
      <div style={{textAlign:"center", padding:5, width:"90%", display:"inline-block", marginBottom:20, justifyContent:'center', alignItems:'center'}}>

        <Grid container       
          justify="center"
          alignItems="flex-start"
          direction="row"
          spacing={24}
          style={{borderRadius:'10px', marginTop:10, backgroundColor: '#1D1D1D', border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >
          <Grid container
            justify="center"
            alignItems="stretch"
            direction="column"
            spacing={8}
            xs={12} lg={3}
            style={{marginTop:10}}

          >
              <Grid item style={{textAlign:"center"}}>

                <JackpotJumbotron Jackpot={this.state.Jackpot}/> 
              </Grid>
              <Grid item style={{textAlign:"center"}}>
                <CountDown {...this.props}/>

              </Grid>
          </Grid>
          
          <Grid container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={16}
            xs={12} lg={6}
            style={{marginTop:10, marginBottom:10}}
          >
              <Grid item>
                <CheckProgress {...this.props} {...this.state}/>        
              </Grid>
            {canclaim? (
              <Grid item className="boxed" style={{border:'px solid', borderColor:'#ffffff10'}}>
                <Claim {...this.props} {...this.state}/>
              </Grid>
            ) : (
              <Grid item className="boxed" style={{border:'0px solid', borderColor:'#ffffff3b'}}>
                <Typography>Complete the collection to claim the jackpot.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}