import React, { useState, useEffect  } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { RiAccountBoxFill } from "react-icons/ri";
import {Tabs, Tab} from '@material-ui/core';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: '80%'
      }}
  />
);

 

function SimplexCom () {
  useEffect(() => {
    window.simplex.createForm();
  }, []);
  return (
    <form id="simplex-form">
        <div id="checkout-element">
        </div>
    </form>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (    
    (value === index) ? (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}  
      </div>
    ) : (
      null
    )   
  );
}

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

  export const FundAccount = ({name,user}) => {
    //let iframe_string = '<iframe src="https://widget.changelly.com?from=*&to=ignis&amount=0.1&address=&fromDefault=btc&toDefault=ignis&merchant_id=kvTqEOaM-xFOcibh&payment_id=IGNIS%20MYTHICALBEINGS.IO&v=3" width="100%" height="600" class="changelly" scrolling="no" style="min-width: 100%; width: 100px; overflow-y: hidden; border: none">Cant load widget</iframe>'
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    }
  return(
    
    <div style={{textAlign:"center", padding:10, width:"100%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:20}}>
         <Typography variant="h4">FUND ACCOUNT</Typography>
        <ColoredLine color="white" />    
       
          <Grid container
            justify="center"
            alignItems="stretch"
            direction="column"
            spacing={24}
            className="boxed"
            style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'0px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >



 
    <Grid item xs>
                
                

                   <RiAccountBoxFill style={{verticalAlign: 'baseline', fontSize:'28', color:'#65CD7C'}}/>

                   <Typography variant="caption" style={{color:'#DD3258'}}>Your IGNIS account:</Typography>
                   <Typography variant="h5" style={{color:'#DD3258'}}>  {user.accountRs}</Typography>
                  <ColoredLine color="white" /> 
                
            </Grid>
            <Typography variant="h6" style={{verticalAlign: 'baseline', fontSize:'26', color:'#ffffff'}}>Please select below the desired funding method:</Typography>

      <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label={<Typography variant="body2">CRYPTO</Typography>}> </Tab>
        <Tab label={<Typography variant="body2">FIAT</Typography>}  ></Tab>
      </Tabs>
 

 
   
 
      <TabPanel value={value} index={0}>
             
               
              <Iframe iframe={'<iframe src="https://widget.changelly.com?from=*&to=ignis&amount=0.005&address=&fromDefault=BTC&toDefault=ignis&theme=default&merchant_id=5zk2vil3u4s8witr&payment_id=&v=2" width="100%" height="600" class="changelly" scrolling="no" style="min-width: 100%; width: 100px; overflow-y: hidden; border: none">Cant load widget</iframe>'}/>                   
             
      </TabPanel>
      <TabPanel value={value} index={1}>
       
      <SimplexCom />
         
      </TabPanel>

    </Grid>

  </div>
  );
}

