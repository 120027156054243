// game api interface
import axios from 'axios';
import {GAMEAPIURL, BRIDGEAPIURL} from './constants';



export function getCurrentWinners() {
return axios.get(GAMEAPIURL,{
    params: {
      action:"winners"
    }
  })
  .then(function (response) {
    return response;
  })
  .catch(function (error){
    // handle error
    console.log(error);
  });
}

export function getEthDepositAddress(accountRs) {
  return axios.get(BRIDGEAPIURL,{
    params: {
      action:"getUnwrapDepositAddress",
      account:accountRs
    }
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error){
    console.log(error);
  });
}
  

export function processUnwrapsForAccount(accountRs) {
  return axios.get(BRIDGEAPIURL,{
    params: {
      action:"processUnwrapsForAccount",
      account:accountRs
    }
  })
  .then(function (response) {
    return response;
  });
}



export function getPegAddresses() {
  return axios.get(BRIDGEAPIURL,{
    params: {
      action:"getPegAddresses"
    }
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error){
    console.log(error);
  });
}