import React, {Component} from 'react';
import { fetchAllCards, fetchGemCards } from '../common/common';
import { TARASCACARDACCOUNT, COLLECTIONACCOUNT, GEMASSETACCOUNT, GEMASSET } from '../common/constants';
import { CardRowDisplay, CardRowDisplayGEM, CardRowDisplayMobileMarket, CardRowDisplayMobileMarketGEM } from './cardrowdisplay';
import {AccountOrderBook, AccountOrderBookCurrency} from './accountorderbook';
import { RarityFilter} from '../carddeck/filter';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';



import { TradesHistory } from './trades';


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);

const ColoredLineTh = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 0.5,
          width: '20%'
      }}
  />
);

export class Exchange extends Component {
  constructor(props){
    super(props);
    this.state = {
      cards:[],
      gemCards:[],
      tarascaCard:{},
      selectedChannel:"all",
      selectedRarity:"all",
      slideIndex:0
    }
    this.refresh = this.refresh.bind(this); 
  }

  /* refresh(){
    var self = this;
    console.log("Exchange: refresh");
    Promise.all([
      fetchCards(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT),
      fetchCard(this.props.nodeurl,this.props.user.accountRs,TARASCACARDASSET,false)
    ]).then(function([cards,tCard]){
      const allCards = tCard ? ([...cards,tCard]) : (cards);
      self.setState({cards:allCards});


    }).catch(function(error) {console.log(error)});
  } */
  refresh(){
    var self = this;
    console.log("Exchange: refresh: fetching cards");
    fetchAllCards(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT,TARASCACARDACCOUNT,false)
    .then(function(cards){
      //console.log(cards);
      //const allCards = tCard ? ([...cards,tCard]) : (cards);
      self.setState({cards:cards});
    })
    .catch(function(error) {console.log(error)});

    fetchGemCards(this.props.nodeurl,this.props.user.accountRs,GEMASSETACCOUNT,false)
    .then(function(gemCards) {
      console.log("🚀 ~ file: exchange.js:84 ~ Exchange ~ .then ~ gemCards", gemCards)
      let test = gemCards.filter(g => g.asset === GEMASSET);
      console.log("🚀 ~ file: exchange.js:86 ~ Exchange ~ .then ~ test", test)
      self.setState({gemCards:gemCards.filter(g => g.asset === GEMASSET)});
    }).catch(function(error) {console.log(error)});

  }

  componentDidMount(){
    this.refresh()
    this.timer = setInterval(this.refresh, 12000)
  }

  componentWillUnmount(){
    console.log("Exchange: stop refresh");
    clearInterval(this.timer);
  }

  render() {
    const numColumnStyle = { width: 12};
    //let channels = new Set(this.state.cards.map((card) => {return card.channel}))
    let rarity = new Set(this.state.cards.map((card) => {return card.rarity}))
    rarity.add("special");  // Tarasca is coming!
    let cards = []
    if (this.state.selectedChannel !== "all") {
      cards = this.state.cards.filter((card) => (card.channel === this.state.selectedChannel));
    }
    else {
      cards = this.state.cards;
    } 

    if (this.state.selectedRarity !== "all") {
      cards = this.state.cards.filter((card) => (card.rarity === this.state.selectedRarity) & (this.props.showAllCards | (card.quantityQNT > 0)));
    }
    else {
      cards = this.state.cards.filter((card) => (this.props.showAllCards | (card.quantityQNT > 0)));
    } 
    //cards.push(this.state.tarascaCard);
    //const max = cards.length;
    //console.log(cards);

    let gemCards = this.state.gemCards;
    return (

   
      <div style={{textAlign:"center", padding:5, width:"100%", marginLeft:-20, display:"inline-block",  justifyContent:'start', alignItems:'center'}}>
       
       <Grid container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={24}
        >


          <Grid item>
            <Typography variant="h3">CURRENCIES</Typography>
            <ColoredLine color="white"/>
          </Grid>

        
             
                <Grid item className="boxed" style={{backgroundColor: '#1D1D1D', marginTop: 5, marginBottom: 10, border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
                  <Hidden xsDown>        
                    <Table padding="dense" >
                      <TableHead >
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Asset</TableCell>
                  
                          <TableCell>Amount</TableCell>

                          <TableCell style={numColumnStyle}>Lowest Ask </TableCell>
                          <TableCell style={numColumnStyle}>Highest Bid</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                       {
                          gemCards != null ? (
                            gemCards.map(
                              (card, index) => (
                                (this.props.showAllCards | (card.quantityQNT > 0)) ? (
                                  <CardRowDisplayGEM key={index} card={card} {...this.props}/>                                    
                                ): (null)
                              )
                            )
                          ): null
                        }
                      </TableBody>
                    </Table>
                  </Hidden>
                  <Hidden smUp>
                    <Grid item direction="row" style={{ width:'100%'}} >
                      {
                        gemCards  != null ? (
                          gemCards.map(
                            (card, index) => (
                              (this.props.showAllCards | (card.quantityQNT > 0 )) ? (
                                <CardRowDisplayMobileMarketGEM key={index} card={card} {...this.props}/>
                              ):(null)
                            )
                          )
                        ): null
                      }
                      
                    </Grid>
                  </Hidden>
             

          </Grid>

        
<br/><br/>


          <Grid item>
            <Typography variant="h3">CARDS</Typography>
            <ColoredLine color="white"/>
          </Grid>
          <Grid item>
          <RarityFilter rarity={[...rarity]} onClick={(rare)=>this.setState({selectedRarity:rare})} />
          </Grid>
          <Grid item style={{marginTop:20}}>
            <Grid container
              justify="space-evenly"
              alignItems="flex-start"
              direction="row"
              spacing={0}
              
            >
               <Grid item className="boxed" style={{backgroundColor: '#1D1D1D', marginTop: 5, marginBottom: 10, border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
    
                <Hidden xsDown>        
                  <Table padding="dense" >
                    <TableHead >
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Rarity</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Circulation</TableCell>
                        <TableCell style={numColumnStyle}>Lowest Ask </TableCell>
                        <TableCell style={numColumnStyle}>Highest Bid</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        cards != null ? (
                          cards.map(
                            (card, index) => (
                              (this.props.showAllCards | (card.quantityQNT > 0)) ? (
                                <CardRowDisplay key={index} card={card} {...this.props}/>                                    
                              ): (null)
                            )
                          )
                        ): null
                      }
                    </TableBody>
                  </Table>
                </Hidden>
                <Hidden smUp>
                  <Grid item direction="row" style={{ width:'100%'}} >
                    {
                      cards != null ? (
                        cards.map(
                          (card, index) => (
                            (this.props.showAllCards | (card.quantityQNT > 0 )) ? (
                              <CardRowDisplayMobileMarket key={index} card={card} {...this.props}/>
                            ):(null)
                          )
                        )
                      ): null
                    }
                  </Grid>
                </Hidden>
              </Grid>

              
              </Grid>    

              <Grid item>   
              <Typography>&nbsp;</Typography><Typography>&nbsp;</Typography>    
                <Typography variant="h3">YOUR ORDERS</Typography>
                <ColoredLine color="white"/>
              </Grid>

              
              <Grid container       
                justify="center"
                alignItems="flex-start"
                direction="row"
                spacing={24}
                style={{borderRadius:'10px', marginTop:10, marginBottom:15, backgroundColor: '#1D1D1D', border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
              >
                    <Grid container
                        justify="center"
                        alignItems="center"
                        direction="column"
                        spacing={8}
                        xs={12} lg={6}
                        style={{marginTop:10, marginBottom:10}}
                      >
               
                <Typography>&nbsp;</Typography>
                <Typography variant="h4">CURRENCIES</Typography>
                <ColoredLineTh color="white"/>  
         
                <AccountOrderBookCurrency cards={cards} {...this.props}/>
              </Grid>
                      <Grid container
                          justify="center"
                          alignItems="stretch"
                          direction="column"
                          spacing={8}
                          xs={12} lg={6}
                          style={{marginTop:10, marginBottom:10}}

                        >

                <Typography>&nbsp;</Typography>
                <Typography variant="h4">CARDS</Typography>
                <ColoredLineTh color="white"/>
          
                <AccountOrderBook cards={cards} {...this.props}/>
              </Grid>


              </Grid>
      
               <Grid item className="boxed" style={{ marginTop: 0, marginBottom: 10, border:'0px solid'}}>


                <TradesHistory {...this.props} />
              </Grid>
       
            </Grid>            
          </Grid>

      </div>
    );
  }  
}

