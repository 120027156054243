import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Alert} from 'reactstrap';

import {validatePassPhrase} from '../common/validators';
import {transferAsset} from '../common/ardorinterface';
import {TxSuccess} from '../common/txsuccess';
import {SignActionField} from '../common/signactionfield';
import { fetchCards } from '../common/common';

import { GiTrophyCup } from "react-icons/gi";
import { COLLECTIONACCOUNT, JACKPOTACCOUNT } from '../common/constants';


function CooldownSpinner(){
  return (
    <Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={4}>
          <Spinner animation="border" role="status" variant="light">
            <span className="sr-only">Transferring...</span>
          </Spinner>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" align="left">Transferring your Mythical Beings...</Typography>
        </Grid>  
    </Grid>
  )
}

export class Claim extends Component {
  constructor (props){
    super(props);
    this.state = {
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''},
      formValid:false,
      response:{data:{message:""}},
      cards:[],
      transferInProgress:false,
      bought:false
    };

    //this.confirm = this.confirm.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.claimThePot = this.claimThePot.bind(this);
    this.performCooldown = this.performCooldown.bind(this);
  }

  componentDidMount(){
    //console.log(this.props)
    var self = this;
    fetchCards(this.props.nodeurl,this.props.user.accountRs,COLLECTIONACCOUNT)
    .then(function(response){
      //console.log(response);
      self.setState({cards:response});
    })
    .catch(function(error) {console.log(error)});
  }

  handlePassphraseChange(value){
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }

  performCooldown(){
    this.setState({transferInProgress:true});
    this.timer = setInterval(this.setState({transferInProgress:false}), 5000);
  }

  claimThePot(){
    var self = this;
    //console.log(self.state);
    let phraseValidated = validatePassPhrase(self.state.passPhrase,self.state.passPhraseStatus,self.props.user.accountRs);
    let allgood = true;
    if  (phraseValidated.invalid){
      console.log("claimThePot(): passphrase invalid, exiting.")
      self.setState({passPhraseStatus:phraseValidated},self.validateForm);
      allgood = false;
      return false;
    }
    self.state.cards.forEach(function(card){
      if (card.quantityQNT === 0 | card.unconfirmedQuantityQNT === 0) {
        console.log("claimThePot(): card with (unconf)quantitity 0 cant be sent, exiting");
        allgood = false;
      }
    });
    if (allgood) {
      console.log("claimThePot(): transferring assets now")
      // self.setState({transferInProgress:true}).then(()=>{
      self.setState({transferInProgress:true},()=>{
        let promises = []
        self.state.cards.forEach(function(card){    
          const message = JSON.stringify({ contract: "Jackpot" });
          console.log("claimThePot(): call transferAsset now for: "+card.asset);
          promises.push(transferAsset(self.props.nodeurl,card.asset,1,JACKPOTACCOUNT,self.state.passPhrase,message,true,60,"HIGH"));                        
        });
        Promise.all(promises).then((responses) => {
          console.log("all transfers submitted");
          self.setState({bought:true});
        });
      });
    }    
  }

  validateForm() {
    this.setState({formValid: ((this.state.passPhraseStatus.invalid===false) & (this.props.complete === true))});
  }

  toggler(props){
    this.setState({bought:false});
    this.props.toggleModal();
  }

  render(){
    const output = (
      <form onSubmit={(event)=>{event.preventDefault();this.claimThePot()}}>
        <div style={{textAlign:"center", width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:10, paddingBottom:10}}>
        <Grid container spacing={8}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>

          <GiTrophyCup style={{verticalAlign: 'baseline', fontSize:'64', color:'#D4AF37'}}/>
          <Typography>&nbsp;</Typography>
            <Typography variant="h4">CLAIM THE JACKPOT</Typography>
          </Grid>
          <Grid item>
          <br></br>
          <Typography variant="button" color='textSecondary'>-- Please Read before claiming --</Typography><br></br>
          <Typography variant='subtitle2' align='justify' color='textSecondary'>
          <ol>
            <li><b>One of each card</b>  is <b><u>returned</u></b> to Mythical Beings</li><br/>
            <li><b>Get a <u>share</u></b>  of the jackpot (1 participation = 1 share) </li><br/>
            <li>Enter into a <b><u>drawing of 7</u> Special Cards </b> per cycle          </li>          
          </ol>
          </Typography>
          </Grid>
          <Grid item>
            <Alert color="warning">Claim can take a few minutes. Use at your own risk within the last hour.</Alert>
          </Grid>      
          <Grid item>
            {(this.state.transferInProgress) ? (
              <CooldownSpinner/>
            ):( 
              <SignActionField  {...this.state} {...this.props} gameaction="claim" 
                handlePassphraseChange={this.handlePassphraseChange}
                action={this.claimThePot} 
                disabled={this.state.transferInProgress || this.props.IgnisIsLow}
              /> 
            )}
          </Grid>
        </Grid>
        </div>
      </form>
      )
    const out_bought = (<TxSuccess />);
    return (this.state.bought ? out_bought : output)
  }
}
