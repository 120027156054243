import React, {Fragment} from 'react'
import {Alert} from 'reactstrap';
import { Link } from 'react-router-dom';
import { getAccountProperties, getAskOrders, getBidOrders} from './ardorinterface';
import {getAccountAssets, getAssetsByIssuer, getAsset} from '../common/ardorinterface';
import Axios from 'axios';
import {validateWinner} from './validators';
import { IMGURL, IMG_MD_PATH, IMG_THUMB_PATH, QUANT_RARE, QUANT_COMMON, QUANT_VERYRARE, QUANT_SPECIAL, WHITELIST, CURRENTSEASON, REFERRALCONTRACTACCOUNT, REFERRALASSET, SASQUATCHASSET, MARUXAINAASSETWRONG, CATOBLEPASASSETWRONG } from './constants';
import Typography from '@material-ui/core/Typography';

export const DangerWarning = ({active,children}) => {
  if (active){
    return (<Alert color="danger">{children}</Alert>)
  }
  return null;
}

export const TestnetWarning = ({isTestnet}) => {
  if (isTestnet){
    return (<Alert color="danger">TESTNET WARNING: This is a test network, do not use real accounts.</Alert>)
  }
  return null;
}


export const PrototypeWarning = ({isPrototype}) => {
  if (isPrototype){
    return (<Alert color="warning">PROTOTYPE WARNING: This is a prototype.</Alert>)
  }
  return null;
}

export const InvitedWarning = ({isInvited}) => {
  if (isInvited){
    return (<Alert color="warning">Account already invited. Referral not possible.</Alert>)
  }
  return null;
}

export const OfferUnavailable = ({offerUnavailable}) => {
  if (offerUnavailable){
    return (<Alert color="warning">Offer unavailable, please contact Tarasca</Alert>)
  }
  return null;
}

export const InvitePossible = ({isPossible}) => {
  if (isPossible){
    return (<Alert color="success">Account can be invited.</Alert>)
  }
  return null;
}

function getTarascaImage(name){
  const imgurl =  IMGURL+IMG_MD_PATH+name+".jpg";
  //console.log(imgurl);
  return imgurl;
}

function getThumbsImage(name){
  const imgurl =  IMGURL+IMG_THUMB_PATH+name+".jpg";
  //console.log(imgurl);
  return imgurl;
}

// returns true if user is white listed in WHITELIST.
export function checkWhitelist(account){
  return WHITELIST.find((ac)=>ac===account);
}

function checkCurrentSeason(nodeurl,account) {
  //stub for potential future API
  return CURRENTSEASON;
}

export function checkWhitelistFromProperty(nodeurl,account) {
  return getAccountProperties(nodeurl,account,REFERRALCONTRACTACCOUNT,"tdao.invite")
  .then(function(response) {
    console.log(response);
    if (response.properties) {
      const invited = response.properties.find((element) => 
        (element.property === "tdao.invite")
      );
      const currentSeason = checkCurrentSeason(nodeurl,account);
      if (invited){
        const value = JSON.parse(invited.value);
        if ((currentSeason === "closed") || (value.invitedFor === currentSeason)){
          // override if currently closed, otherwise check for the season.
          return {invited:true,season:"season01"};
        }        
        console.log("not whitelisted, season mismatch");
        return {invited:false,rejectionReason:"Account not invited to correct season: "+currentSeason};
      }
      else {
        return {invited:false,rejectionReason:"Account not invited, function disabled"};
      }
    }
    else {
      return {invited:false,rejectionReason:"Account not invited, function disabled"};
    }
  })
}


export function TimeStampSimple({timestamp,eb}){
  //console.log(eb);
  var stamp = new Date(eb.getTime());
  stamp.setSeconds(+stamp.getSeconds()+timestamp);
  
  const Month = stamp.getMonth()+1;
  const datestring = stamp.getFullYear().toString()+"-"+ Month.toString().padStart(2,"0") + "-"+stamp.getDate().toString().padStart(2,"0");
  const timestring = stamp.getHours().toString().padStart(2,"0") + ":" + stamp.getMinutes().toString().padStart(2,"0")+ ":" + stamp.getSeconds().toString().padStart(2,"0");

  return(
    <Fragment>
      <Typography>        
        {datestring} ({timestring})
      </Typography>
    </Fragment>
  )
}

// export class StatusDisplay extends Component {
//   constructor(props){
//     super(props);
//     this.state = {
//         BlockchainStatus:{}
//       };
//   }

//   render() {
//     return(<div>{this.state.tarasca}</div>);
//   }
// }


// export function getCard(asset,nodeurl){
//   const cardDetails = JSON.parse(asset.description);
//   //const cardimgurl = getImageURL(nodeurl,cardDetails.fullhash);
//   const cardimgurl = IMGURL+IMG_MD_PATH+asset.name+"_md.jpg";
//   console.log(cardimgurl);
//   return ({
//     assetname : asset.name,
//     description : asset.description,
//     fullhash:cardDetails.fullhash,
//     name:cardDetails.name,
//     cardImgUrl:cardimgurl,
//     channel:cardDetails.channel,
//     quantityQNT : 0,
//     unconfirmedQuantityQNT : 0,
//     asset : asset.asset,
//   })
// }


export function isJSON(str) {
  try {
      return (JSON.parse(str) && !!str);
  } catch (e) {
      return false;
  }
}


export function cardGeneratorGrey(asset,quantityQNT,unconfirmedQuantityQNT,nodeurl, fetchOrders=false){
  //console.log(asset.description);
  //const cardDetails = JSON.parse(asset.description);
  //console.log(asset.name);
  //console.log(asset.name+asset.description);
 // console.log(asset.name);

  const new_description = asset.description.replace(/\bNaN\b/g, "null").replace(/\t/g, "null");
  if (new_description){
    let cardDetails = new_description;
    if(isJSON(new_description)) {
      cardDetails = JSON.parse(new_description.replace(/\bNaN\b/g, "null").replace(/\t/g, "null"));
    }
    //if (asset.description){
      //const cardDetails = JSON.parse(asset.description.replace(/\bNaN\b/g, "null").replace(/\t/g, "null"));
      

    let askOrders = [];
    let bidOrders = [];
    if (fetchOrders) {
      getAskOrders(nodeurl,asset.asset)
        .then((response) => {
          askOrders = response.askOrders;
        });
      getBidOrders(nodeurl,asset.asset)
      .then((response) => {
        bidOrders = response.bidOrders;
      });
    }
  
    let totalQuantityQNT = 0;
    if (cardDetails.rarity === "special") {
      totalQuantityQNT = QUANT_SPECIAL;
    }
    else if (cardDetails.rarity === "very rare") {
      totalQuantityQNT = QUANT_VERYRARE;
    }
    else if (cardDetails.rarity === "rare") {
      totalQuantityQNT = QUANT_RARE;
    }
    else if (cardDetails.rarity === "common") {
      totalQuantityQNT = QUANT_COMMON;
    }
  
    let cardname = (cardDetails.name === 'Kăk-whăn’-û-ghăt Kǐg-û-lu’-nǐk') ? 'Kăk-whăn’ ...' : cardDetails.name;
    //console.log(cardname);
    return {
      asset:asset.asset,
      assetname:asset.name,
      name:cardname,
      channel:cardDetails.channel,
      rarity:cardDetails.rarity,
      quantityQNT:quantityQNT,
      totalQuantityQNT:totalQuantityQNT,
      unconfirmedQuantityQNT:unconfirmedQuantityQNT,
      totalQuantitiyQNT:asset.quantityQNT,
      //cardImgUrl:getImageURL(nodeurl,cardDetails.fullhash),
      cardImgUrl:getTarascaImage(asset.name),
      cardThumbUrl:getThumbsImage(asset.name),
      askOrders:askOrders,
      bidOrders:bidOrders
    };
  }
  else {
    return undefined;
  }
}


export function cardsGeneratorGrey(accountAssets,collectionAssets,nodeurl, fetchOrders=false){
var ret = [];
//console.log(accountAssets,collectionAssets);
collectionAssets.forEach(function(asset){
  const accountAsset = accountAssets.find((a)=>a.asset===asset.asset);
  const quantityQNT = accountAsset ? accountAsset.quantityQNT : 0;
  const unconfirmedQuantityQNT = accountAsset ? accountAsset.unconfirmedQuantityQNT : 0;
  if (asset.description) {
    let newAsset = cardGeneratorGrey(asset,quantityQNT,unconfirmedQuantityQNT,nodeurl,fetchOrders);
    if (newAsset!==undefined){
      ret.push(newAsset);
    }
  }
});
return ret;
}

export function fetchCard(nodeurl,accountRs,assetId, fetchOrders=false) {
  return Axios.all([
    getAccountAssets(nodeurl,accountRs),
    getAsset(nodeurl,assetId)
  ])
  .then(Axios.spread(function(account,Asset){
    const accountAsset = account.accountAssets.find((a)=>a.asset===Asset.asset);
    const quantityQNT = accountAsset ? accountAsset.quantityQNT : 0;
    const unconfirmedQuantityQNT = accountAsset ? accountAsset.unconfirmedQuantityQNT : 0;
    return cardGeneratorGrey(Asset,quantityQNT,unconfirmedQuantityQNT,nodeurl,fetchOrders);
  }));
}

export function fetchCards(nodeurl,accountRs,collectionRs, fetchOrders=false) {
  return Axios.all([
    getAccountAssets(nodeurl,accountRs),
    getAssetsByIssuer(nodeurl,collectionRs)
  ])
  .then(Axios.spread(function(account,collectionAssets){
   // console.log(collectionAssets);
    let collection = collectionAssets.filter((asset) => (asset.quantityQNT>0 && asset.asset !== CATOBLEPASASSETWRONG));
     // console.log(collection);
    return cardsGeneratorGrey(account.accountAssets,collection,nodeurl,fetchOrders);
  }));
}

export function fetchAllCards(nodeurl,accountRs,collectionRs,specialRs,fetchOrders=false) {
  return Axios.all([
    getAccountAssets(nodeurl,accountRs),
    getAssetsByIssuer(nodeurl,collectionRs),
    getAssetsByIssuer(nodeurl,specialRs)
  ])
  .then(Axios.spread(function(account,collectionAssets,specialAssets){
    //blacklisting the REF asset, its from the same account as the special cards

    let specialCards = specialAssets.filter((asset) => (asset.asset !== REFERRALASSET && asset.asset !== SASQUATCHASSET && asset.asset !== MARUXAINAASSETWRONG)); 
    
    let fullCollection = collectionAssets.concat(specialCards).filter((asset) => (asset.asset !== CATOBLEPASASSETWRONG));
    //let fullCollectionCorrect = collectionAssets.concat(specialCards).filter((asset) => (asset.asset !== CATOBLEPASASSETWRONG));
    //console.log(fullCollectionCorrect);
    //let cardsInPosession = collectionAssets.filter((asset) => asset.quantityQNT>0);
    //console.log(specialAssets,fullCollection,cardsInPosession);
    return cardsGeneratorGrey(account.accountAssets,fullCollection,nodeurl,fetchOrders);
  }));
}

export function fetchGemCards(nodeurl,accountRs,gemRs,fetchOrders=false) {
  return Axios.all([
    getAccountAssets(nodeurl,accountRs),
    getAssetsByIssuer(nodeurl,gemRs),
  ])
  .then(Axios.spread(function(account,gemAssets){

    return cardsGeneratorGrey(account.accountAssets,gemAssets,nodeurl,fetchOrders);
  }));
}

export function fetchJackpotState(nodeurl,accountRs,collectionRs) {
  return Axios.all([
    getAccountAssets(nodeurl,accountRs),
    getAssetsByIssuer(nodeurl,collectionRs)
  ])
  .then(Axios.spread(function(account,collectionAssets){
    let jackprogress = validateWinner(account.accountAssets,collectionAssets.filter((asset) => (asset.asset !== CATOBLEPASASSETWRONG)));
    return jackprogress;
  }));
}

export function round(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export function CardLink(props) {
  let output = null;
  if(props.disabled){
    output = (<span>{props.children}</span>);
  }
  else {
    output = (<Link to={props.to}>{props.children}</Link>);
  }
  return output;
}
