import React from 'react';
import {Link} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { TiArrowBack } from "react-icons/ti";


export function TxSuccess({response,returnLink}){
  const everythingOk =  response ? (response.data.errorCode === undefined) : true;
  const message = everythingOk ? "" : "An error occurred."
  const detail = response ? (response.data.errorDescription) : "";
  return(
    <div style={{textAlign:"center", padding:5}}>
      {
        everythingOk ? 
        (
          <Grid container
                justify="center"
                alignItems="center"
                direction="column"
                spacing={40}>
            <Grid item>
              <Typography variant='h4'>
                Transaction Sent
              </Typography>
              <Typography variant='h4'>
               • 
              </Typography>
              <Typography variant='caption' color='secondary'>
              It can sometimes take up to a few minutes for transactions to be fully confirmed.<br></br>
              This is completely normal for blockchain-based games.
              </Typography>
            </Grid>
            <Grid item>
              <Link style={{textDecoration: 'none'}} to="/game">
                  <Button size="small" variant="outlined" style={{marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '30px', minHeight: '30px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><TiArrowBack/>&nbsp;   INVENTORY</Button>
              </Link>      
              <Typography>
               • 
              </Typography>
              <Link style={{textDecoration: 'none'}} to="/game/exchange">
              <Button size="small" variant="outlined" style={{marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '30px', minHeight: '30px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><TiArrowBack/>&nbsp;   MARKET</Button>
              </Link>    
              <Typography>
               • 
              </Typography>
              <Link style={{textDecoration: 'none'}} to="/game/history">
              <Button size="small" variant="outlined" style={{marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '30px', minHeight: '30px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><TiArrowBack/>&nbsp;   HISTORY</Button>
              </Link>    
            </Grid>      
          </Grid>
        ) : (
          <Grid container
                justify="center"
                alignItems="center"
                direction="column"
                spacing={40}>
            <Grid item>
              <Typography>{message}</Typography>
            </Grid>
            <Grid item>
              <Typography>{detail}</Typography>
            </Grid>
          </Grid>
        )
      }
    </div>
  )
}