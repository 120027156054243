import React, {Component} from 'react';
import { getAskOrders, getBidOrders } from '../common/ardorinterface';
import { JACKPOTACCOUNT, GEMASSET, NQTDIVIDER } from '../common/constants';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { ThumbExchangeRow, ImgInventoryRow } from '../carddeck/thumb';
import { FaExchangeAlt } from "react-icons/fa";
import { BsInfoSquareFill } from "react-icons/bs";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { round } from '../common/common';
import Gem from './images/gems.svg';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "orange",
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


export class CardRowDisplay extends Component {
  constructor(props) {
    super(props);
    this.state={
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[],
      quantityContract:0,   
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAskOrders(self.props.nodeurl,self.props.card.asset)
    .then((response) => {
      //console.log(self.props.card);
      //console.log(response);
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(self.props.nodeurl,self.props.card.asset)
    .then((response) => {
      //console.log(self.props.card);
      //console.log(response);
      self.setState({bidOrders:response.bidOrders});
    });

    fetch(self.props.nodeurl+"?requestType=getAccountAssets&account="+ JACKPOTACCOUNT +"&asset="+ self.props.card.asset)
    .then(res => res.json())
    .then(
      (result) => {
        self.setState({
          isLoaded: true,
          quantityContract: (result.quantityQNT) ? result.quantityQNT : 0
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        self.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,4500);
  }



  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render() {
    const numColumnStyle = { width: 12};
// style={{padding:"8px", textAlign:"center"}}
// style={{padding:"8px"}}
    const ask = (!this.state.askOrders || this.state.askOrders.length === 0) ? "/" 
                          : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
    const bid = (!this.state.bidOrders || this.state.bidOrders.length === 0) ? "/" 
                          : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
    /*
    const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
                          : this.props.card.unconfirmedQuantityQNT;
    */
    const quantityCirc = this.props.card.totalQuantityQNT - this.state.quantityContract;
    //const assetCirculation = ((1-(this.state.quantityCirc/this.props.card.totalQuantityQNT))*100).toFixed(2);
    const assetCirculation = ((quantityCirc/this.props.card.totalQuantityQNT)*100).toFixed(2);
    
    //const cardAvailableBalance = Math.min(Number(this.props.card.quantityQNT), Number(this.props.card.unconfirmedQuantityQNT));

    const circulationString = (this.props.card.rarity === "special") ? ("???") : (quantityCirc + " (" + assetCirculation + "%)");

    return (
      <TableRow>
        <TableCell>
            <ThumbExchangeRow card={this.props.card}></ThumbExchangeRow>
        </TableCell>
        <TableCell ><Typography variant="h6" align="left">
          {this.props.card.name === 'Kăk-whăn’-û-ghăt Kǐg-û-lu’-nǐk' ? 'Kăk-whăn’' : this.props.card.name}</Typography> <Typography>{this.props.card.channel}</Typography></TableCell>
        <TableCell><Typography>{this.props.card.rarity === 'very rare' ? 'epic' : this.props.card.rarity}</Typography></TableCell>
        <TableCell>
          <Typography>{this.props.card.quantityQNT} ({this.props.card.unconfirmedQuantityQNT})&nbsp;
              <LightTooltip style={{color:'orange', display: this.props.card.unconfirmedQuantityQNT < this.props.card.quantityQNT ? '' : 'none'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this card. At least one of these cards is locked for all actions (e.g. crafting, jackpot, sending) until you cancel your Ask order.">
                 <BsInfoSquareFill/>
             </LightTooltip >
        </Typography>
        </TableCell>
        <TableCell><Typography>{circulationString}</Typography></TableCell>
        <TableCell style={numColumnStyle}><Link to={"/game/place/bid/"+this.props.card.asset}><Button variant="text">{ask}</Button></Link></TableCell>
        <TableCell style={numColumnStyle}><Link to={"/game/place/ask/"+this.props.card.asset}><Button variant="text">{bid}</Button></Link></TableCell>
        <TableCell><Button variant="outlined" size="small" style={{color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/place/bid/"+this.props.card.asset}><Typography>Trade</Typography></Link></Button></TableCell>
      </TableRow>
    );
  }
}



export class CardRowDisplayMobile extends Component {
  constructor(props) {
    super(props);
    this.state={
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[]
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAskOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({bidOrders:response.bidOrders});
    });
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render() {
    //const numColumnStyle = { width: 12};
    const ask = this.state.askOrders.length === 0 ? "/" 
                          : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
    const bid = this.state.bidOrders.length === 0 ? "/" 
                          : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
    const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
                          : this.props.card.unconfirmedQuantityQNT;
    const cardAvailableBalance = Math.min(Number(this.props.card.quantityQNT), Number(this.props.card.unconfirmedQuantityQNT));

    return (
      <div style={{textAlign:"center", width:"90%", display:"inline",   justifyContent:'center', alignItems:'center'}} >

      <TableRow >
        <TableCell align="center">
          <Link style={{textDecoration:"none"}} to={"/game/learnmore/"+this.props.card.asset}>
            <ImgInventoryRow card={this.props.card}></ImgInventoryRow>
          </Link>
    
          <Typography gutterBottom style={{textAlign: 'center', color: this.props.card.rarity === 'very rare' ? 'gold' : this.props.card.rarity === 'rare' ? '#009ad1' : 'grey'}}>
          
          </Typography>
          <Typography variant="h5" gutterBottom style={{textAlign: 'left'}}>   {this.props.card.name === 'Kăk-whăn’-û-ghăt Kǐg-û-lu’-nǐk' ? 'Kăk-whăn’' : this.props.card.name}</Typography>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>{this.props.card.channel} / {this.props.card.rarity === 'very rare' ? 'epic' : this.props.card.rarity}</Typography>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Quantity: {quant} ({cardAvailableBalance})&nbsp;
          
       
              <LightTooltip style={{color:'orange', display: this.props.card.unconfirmedQuantityQNT < this.props.card.quantityQNT ? '' : 'none'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this card. At least one of these cards is locked for all actions (e.g. crafting, jackpot, sending, morphing) until you cancel your Ask order.">
                 <BsInfoSquareFill/>
             </LightTooltip >
              </Typography>

          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Lowest Ask: {ask} &nbsp; •  &nbsp;Highest Bid: {bid}</Typography>
          <Button variant="outlined" size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '40px', minHeight: '40px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/place/bid/"+this.props.card.asset}><Typography><FaExchangeAlt/> &nbsp; TRADE</Typography></Link></Button>
          </TableCell>
      </TableRow>

    </div>
    );
  }
}

export class CardRowDisplayMobileMarket extends Component {
  constructor(props) {
    super(props);
    this.state={
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[]
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAskOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({bidOrders:response.bidOrders});
    });
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render() {
    //const numColumnStyle = { width: 12};
    const ask = this.state.askOrders.length === 0 ? "/" 
                          : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
    const bid = this.state.bidOrders.length === 0 ? "/" 
                          : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
    /*
    const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
                          : this.props.card.unconfirmedQuantityQNT;
    const cardAvailableBalance = Math.min(Number(this.props.card.quantityQNT), Number(this.props.card.unconfirmedQuantityQNT));
    */
    return (
      <div style={{textAlign:"center", width:"90%", display:"inline",   justifyContent:'center', alignItems:'center'}} >

      <TableRow >
        <TableCell align="center">
            <ImgInventoryRow card={this.props.card}></ImgInventoryRow>
    
          <Typography gutterBottom style={{textAlign: 'center', color: this.props.card.rarity === 'very rare' ? 'gold' : this.props.card.rarity === 'rare' ? '#009ad1' : 'grey'}}>
          
          </Typography>
          <Typography variant="h5" gutterBottom style={{textAlign: 'left'}}>   {this.props.card.name === 'Kăk-whăn’-û-ghăt Kǐg-û-lu’-nǐk' ? 'Kăk-whăn’' : this.props.card.name}</Typography>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>{this.props.card.channel} / {this.props.card.rarity === 'very rare' ? 'epic' : this.props.card.rarity}</Typography>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Quantity: {this.props.card.quantityQNT} ({this.props.card.unconfirmedQuantityQNT})&nbsp;
               <LightTooltip style={{color:'orange', display: this.props.card.unconfirmedQuantityQNT < this.props.card.quantityQNT ? '' : 'none'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this card. At least one of these cards is locked for all actions (e.g. crafting, jackpot, sending) until you cancel your Ask order.">
                <BsInfoSquareFill/>
            </LightTooltip >
          </Typography>
          
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Lowest Ask: {ask} &nbsp; •  &nbsp;Highest Bid: {bid}</Typography>
          <Button variant="outlined" size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '40px', minHeight: '40px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/place/bid/"+this.props.card.asset}><Typography><FaExchangeAlt/> &nbsp; TRADE</Typography></Link></Button>
          </TableCell>
      </TableRow>

    </div>
    );
  }
}


export class CardRowDisplayGEM extends Component {
  constructor(props) {
    super(props);
    this.state={
      card:GEMASSET,
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[],
      quantityContract:0,   
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAskOrders(self.props.nodeurl,self.props.card.asset)
    .then((response) => {
      //console.log(self.props.card);
      //console.log(response);
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(self.props.nodeurl,self.props.card.asset)
    .then((response) => {
      //console.log(self.props.card);
      //console.log(response);
      self.setState({bidOrders:response.bidOrders});
    });

    fetch(self.props.nodeurl+"?requestType=getAccountAssets&account="+ JACKPOTACCOUNT +"&asset="+ self.props.card.asset)
    .then(res => res.json())
    .then(
      (result) => {
        self.setState({
          isLoaded: true,
          quantityContract: (result.quantityQNT) ? result.quantityQNT : 0
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        self.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,4500);
  }



  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render() {
    const numColumnStyle = { width: 12};
// style={{padding:"8px", textAlign:"center"}}
// style={{padding:"8px"}}
    const ask = (!this.state.askOrders || this.state.askOrders.length === 0) ? "/" 
                          : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
    const bid = (!this.state.bidOrders || this.state.bidOrders.length === 0) ? "/" 
                          : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
    /*
    const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
                          : this.props.card.unconfirmedQuantityQNT;
    */
    //const quantityCirc = this.props.card.totalQuantityQNT - this.state.quantityContract;
    //const assetCirculation = ((1-(this.state.quantityCirc/this.props.card.totalQuantityQNT))*100).toFixed(2);
    /*
    const assetCirculation = ((quantityCirc/this.props.card.totalQuantityQNT)*100).toFixed(2);
    const cardAvailableBalance = Math.min(Number(this.props.card.quantityQNT), Number(this.props.card.unconfirmedQuantityQNT));
    const circulationString = (this.props.card.rarity === "special") ? ("???") : (quantityCirc + " (" + assetCirculation + "%)");
    */
    return (
      <TableRow>
        <TableCell>
        <img src={Gem} alt="Gem" width="50px" />
        </TableCell>
        <TableCell ><Typography variant="h6" align="left">
          GEM</Typography> <Typography>Currency</Typography></TableCell>
        
        <TableCell>
        <Typography variant="h6" align="left">{round(Math.min(this.props.card.unconfirmedQuantityQNT/NQTDIVIDER),2)} 
        <Typography> ({round(Math.min(this.props.card.quantityQNT/NQTDIVIDER),2)})&nbsp;
              <LightTooltip style={{color:'orange', display: this.props.card.unconfirmedQuantityQNT < this.props.card.quantityQNT ? '' : 'none'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this currency.">
                 <BsInfoSquareFill/>
             </LightTooltip >
             </Typography>
        </Typography>
        </TableCell>

        <TableCell style={numColumnStyle}><Link to={"/game/placegem/bid/"+this.props.card.asset}><Button variant="text"><Typography variant="h6">{ask}<Typography>IGNIS</Typography></Typography></Button></Link></TableCell>
        <TableCell style={numColumnStyle}><Link to={"/game/placegem/ask/"+this.props.card.asset}><Button variant="text"><Typography variant="h6">{bid}<Typography>IGNIS</Typography></Typography></Button></Link></TableCell>
        <TableCell><Button variant="outlined" size="small" style={{color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/placegem/bid/"+this.props.card.asset}><Typography>Trade</Typography></Link></Button></TableCell>
      </TableRow>
    );
  }
}

export class CardRowDisplayMobileMarketGEM extends Component {
  constructor(props) {
    super(props);
    this.state={
      modalSend:false,
      modalTrade:false,
      bidOrders:[],
      askOrders:[]
    }
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAskOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({askOrders:response.askOrders});
    });
    getBidOrders(this.props.nodeurl,this.props.card.asset)
    .then((response) => {
      self.setState({bidOrders:response.bidOrders});
    });
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render() {
    //const numColumnStyle = { width: 12};
    const ask = this.state.askOrders.length === 0 ? "/" 
                          : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
    const bid = this.state.bidOrders.length === 0 ? "/" 
                          : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
    /*
    const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
                          : this.props.card.unconfirmedQuantityQNT;
    const cardAvailableBalance = Math.min(Number(this.props.card.quantityQNT), Number(this.props.card.unconfirmedQuantityQNT));
    */
    return (
      <div style={{textAlign:"center", width:"90%", display:"inline",   justifyContent:'center', alignItems:'center'}} >

      <TableRow >
        <TableCell align="center">
        <img src={Gem} alt="Gem" width="50px" />
    
          <Typography gutterBottom style={{textAlign: 'center', color: this.props.card.rarity === 'very rare' ? 'gold' : this.props.card.rarity === 'rare' ? '#009ad1' : 'grey'}}>
          
          </Typography>
          <Typography variant="h5" gutterBottom style={{textAlign: 'left'}}>  GEM</Typography>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Asset</Typography>
          <Typography variant="h6" align="left">Amount: {round(Math.min(this.props.card.unconfirmedQuantityQNT/NQTDIVIDER),2)} 
        <Typography> ({round(Math.min(this.props.card.quantityQNT/NQTDIVIDER),2)})&nbsp;
              <LightTooltip style={{color:'orange', display: this.props.card.unconfirmedQuantityQNT < this.props.card.quantityQNT ? '' : 'none'}} disableFocusListener disableTouchListener title="You have at least one open Ask order on the market for this currency.">
                 <BsInfoSquareFill/>
             </LightTooltip >
             </Typography>
        </Typography>
          <br/>
          <Typography variant="body2" gutterBottom style={{textAlign: 'left'}}>Lowest Ask: {ask} IGNIS <br/> Highest Bid: {bid}</Typography>
          <Button variant="outlined" size="small" style={{justifyContent: 'center', alignItems:'center', marginBottom: '3px', marginTop: '3px', maxWidth: '120px', minWidth: '120px', maxHeight: '40px', minHeight: '40px', color:"#fff", boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)"}}><Link to={"/game/placegem/bid/"+this.props.card.asset}><Typography><FaExchangeAlt/> &nbsp; TRADE</Typography></Link></Button>
          </TableCell>
      </TableRow>

    </div>
    );
  }
}