import React, { Component } from "react";
import { validatePassPhrase, validateQuantity } from "../common/validators";
import {
  sendIgnis,
  transferCurrency,
  transferCurrencyZeroFee,
} from "../common/ardorinterface";
import { SignActionField } from "../common/signactionfield";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import {
  CURRENCY,
  NQTDIVIDER,
  MAXPACKS,
  PACKPRICEGIFTZ,
  PACKPRICE,
  BUYPACKACCOUNT,
} from "../common/constants";
import { TxSuccess } from "../common/txsuccess";
import Button from "@material-ui/core/Button";
import { ImGift } from "react-icons/im";
import Ignis from "../common/images/ignis.png";
import { DangerWarning } from "../common/common";

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1.5,
      width: "70%",
    }}
  />
);

export class BuyPack extends Component {
  constructor(props) {
    super(props);
    this.packPrice = PACKPRICE;
    this.packPriceGiftz = PACKPRICEGIFTZ;
    this.state = {
      selectedCurrency: "Ignis",
      noPacks: 1,
      noPacksStatus: { invalid: false, error: "" },
      passPhrase: "",
      passPhraseStatus: { invalid: undefined, error: "" },
      formValid: false,
      submitStatus:{invalid:false,error:""},
      bought: false,
      responseTime: 0,
    };
    this.buyPack = this.buyPack.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.handleNoPacksChange = this.handleNoPacksChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    let availableFunds = Math.min(
      this.props.wallet.balanceNQT,
      this.props.wallet.unconfirmedBalanceNQT
    );
    let max = Math.min(availableFunds / NQTDIVIDER / PACKPRICE, MAXPACKS);
    //let min = 1;
    if (max < 1.0) {
      this.setState({ noPacks: this.state.noPacks }, () => {
        let fieldStatus = {
          invalid: true,
          error: "Not enough funds to buy a pack",
        };
        this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
      });
    }
    
    this.validateForm();
  }

  buyPack() {
    var self = this;

    if(!self.state.formValid){
      self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
      return;
    }
    else {
      self.setState({submitStatus:{invalid:false, error:""}});
    }

    let phraseValidated = validatePassPhrase(
      self.state.passPhrase,
      self.state.passPhraseStatus,
      self.props.user.accountRs
    );
    if (phraseValidated.invalid) {
      console.log("buyPack(): passphrase invalid, exiting.");
      self.setState({ passPhraseStatus: phraseValidated }, this.validateForm);
      return;
    }

    //return;
    console.log("buyPack(): passphrase ok.");
    console.log(this.state);
    const message = JSON.stringify({ contract: "IgnisAssetLottery" });
    if (this.state.selectedCurrency === "Ignis") {
      let amountNQT = this.state.noPacks * self.packPrice * NQTDIVIDER;
      sendIgnis(
        this.props.nodeurl,
        amountNQT,
        BUYPACKACCOUNT,
        this.state.passPhrase,
        message,
        true
      )
        .then(function(response) {
          console.log(response);
          self.setState({
            response: response,
            responseTime: response.data.requestProcessingTime,
            bought: true,
            status: "success",
          });
        })
        .catch(function(error) {
          console.log("ohje sendIgnis (from buyPack Dialog):");
          console.log(error.message);
          self.setState({ status: "ERROR" });
        });
    } else if (this.state.selectedCurrency === "Giftz") {
      if (!this.validateCurrency(this.state.noPacks, "Giftz").invalid) {
        const message = JSON.stringify({ contract: "IgnisAssetLottery" });
        let unitsQNT = this.state.noPacks * self.packPriceGiftz;
        if (this.props.wallet.balanceNQT < 0.1 * NQTDIVIDER) {
          transferCurrencyZeroFee(
            this.props.nodeurl,
            CURRENCY,
            unitsQNT,
            BUYPACKACCOUNT,
            this.state.passPhrase,
            message,
            true
          )
            .then(function(response) {
              console.log(response);
              self.setState({
                response: response,
                responseTime: response.data.requestProcessingTime,
                bought: true,
                status: "success",
              });
            })
            .catch(function(error) {
              console.log(
                "ohje transferCurrencyZeroFee (from buyPack Dialog):"
              );
              console.log(error.message);
              self.setState({ status: "ERROR" });
            });
        } else {
          transferCurrency(
            this.props.nodeurl,
            CURRENCY,
            unitsQNT,
            BUYPACKACCOUNT,
            this.state.passPhrase,
            message,
            true
          )
            .then(function(response) {
              console.log(response);
              self.setState({
                response: response,
                responseTime: response.data.requestProcessingTime,
                bought: true,
                status: "success",
              });
            })
            .catch(function(error) {
              console.log("ohje transferCurrency (from buyPack Dialog):");
              console.log(error.message);
              self.setState({ status: "ERROR" });
            });
        }
      }
    }
  }

  validateForm() {
    this.setState({
      formValid:
        //this.state.passPhraseStatus.invalid === false &&
        this.state.noPacksStatus.invalid === false
    });
  }
  validateCurrency(value, currency) {
    if (currency === "Giftz") {
      let availableGamma = Math.min(
        this.props.walletCurrency.unitsQNT,
        this.props.walletCurrency.unconfirmedUnitsQNT
      );
      if (value > availableGamma) {
        let fieldStatus = {
          invalid: true,
          error: "Not enough GIFTZ",
        };
        return fieldStatus;
      } else {
        return { error: "", invalid: false };
      }
    } else if (currency === "Ignis") {
      let availableFunds = Math.min(
        this.props.wallet.balanceNQT,
        this.props.wallet.unconfirmedBalanceNQT
      );
      let max = Math.min(availableFunds / NQTDIVIDER / PACKPRICE, MAXPACKS);
      if (value > max) {
        let fieldStatus = {
          invalid: true,
          error: "Not enough Ignis",
        };
        return fieldStatus;
      } else {
        return { error: "", invalid: false };
      }
    }
  }
  handleNoPacksChange(event) {
    let value = event.target.value;
    let availableFunds = Math.min(
      this.props.wallet.balanceNQT,
      this.props.wallet.unconfirmedBalanceNQT
    );

    if (this.state.selectedCurrency === "Ignis") {
      let max = Math.min(availableFunds / NQTDIVIDER / PACKPRICE, MAXPACKS);
      let min = 1;
      if (max >= 1.0) {
        this.setState({ noPacks: value }, () => {
          let fieldStatus = validateQuantity(
            value,
            max,
            min,
            this.state.noPacksStatus
          );
          //debugger;
          this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
        });
      } else {
        this.setState({ noPacks: value }, () => {
          let fieldStatus = {
            invalid: true,
            error: "Not enough funds to buy a pack",
          };
          this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
        });
      }
      this.setState({ noPacks: event.target.value });
    } else if (this.state.selectedCurrency === "Giftz") {
      let fieldStatus = this.validateCurrency(value, "Giftz");
      this.setState({ noPacks: value }, () => {
        this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
      });
    }
  }

  handlePassphraseChange(event) {
    //let value = event.target.value;
    let value = event;
    this.setState({ passPhrase: value }, () => {
      let fieldStatus = validatePassPhrase(value, this.state.passPhraseStatus);
      this.setState({ passPhraseStatus: fieldStatus }, this.validateForm);
    });
  }

  toggler(props) {
    //this.setState({bought:false});
    this.props.toggle(!this.props.modalOpen);
  }

  handleCurrencyChange(value) {
    this.setState({
      selectedCurrency: value,
    });
    const fieldStatus = this.validateCurrency(this.state.noPacks, value);
    this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
  }
  render() {
    let totalPrice = this.state.noPacks * this.packPrice;
    if (this.state.bought) {
      return <TxSuccess />;
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            padding: 20,
            width: "90%",
            maxWidth: "600px",
            display: "inline-block",
            paddingTop: 2,
          }}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.buyPack();
            }}
          >
            <Grid
              container
              justify="center"
              alignItems="stretch"
              direction="column"
              spacing={24}
              className="boxed"
              style={{
                backgroundColor: "#1D1D1D",
                marginTop: 0,
                border: "1px solid",
                borderColor: "#ffffff10",
                boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)",
              }}
            >
              <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h4">BUY BOOSTER PACK</Typography>
                <Typography variant="caption">
                  (contains 3 random cards)
                </Typography>
                <ColoredLine color="white" />
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Typography>Pay with: </Typography>
                <div>
                  <Button
                    onClick={() => {
                      this.handleCurrencyChange("Ignis");
                    }}
                    variant="outlined"
                    size="small"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "3px",
                      marginTop: "3px",
                      maxWidth: "90px",
                      minWidth: "90px",
                      maxHeight: "30px",
                      minHeight: "30px",
                      color: "#fff",
                      boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)",
                    }}
                  >
                    <Typography>
                      <img src={Ignis} alt="Coins" width={"11"} /> &nbsp; IGNIS
                    </Typography>
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      this.handleCurrencyChange("Giftz");
                    }}
                    variant="outlined"
                    size="small"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "3px",
                      marginTop: "3px",
                      maxWidth: "90px",
                      minWidth: "90px",
                      maxHeight: "30px",
                      minHeight: "30px",
                      color: "#fff",
                      boxShadow: "4px 4px 2px rgba(5, 5, 5, 0.32)",
                    }}
                  >
                    <Typography>
                      <ImGift fontSize="medium" /> &nbsp; GIFTZ
                    </Typography>
                  </Button>{" "}
                </div>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Number of packs"
                  value={this.state.noPacks}
                  onChange={(event) => this.handleNoPacksChange(event)}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  error={this.state.noPacksStatus.invalid}
                  helperText={this.state.noPacksStatus.error}
                />
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography>
                  Total costs •{" "}
                  {this.state.selectedCurrency === "Ignis"
                    ? totalPrice
                    : this.state.noPacks * this.packPriceGiftz}{" "}
                  {this.state.selectedCurrency}
                </Typography>
              </Grid>
              <Grid item>
                <DangerWarning active={this.state.submitStatus.invalid}>
                  {this.state.submitStatus.error}
                </DangerWarning>
              </Grid>
              <Grid item>
                <SignActionField
                  {...this.props}
                  {...this.state}
                  gameaction="buypack" 
                  handlePassphraseChange={this.handlePassphraseChange}
                  action={this.buyPack}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      );
    }
  }
}

export class BuyPackCurrency extends Component {
  constructor(props) {
    super(props);
    this.packPrice = 26;
    this.packPriceGiftz = 1;
    this.state = {
      noPacks: 1,
      noPacksStatus: { invalid: false, error: "" },
      passPhrase: "",
      passPhraseStatus: { invalid: undefined, error: "" },
      formValid: false,
      submitStatus:{invalid:false,error:""},
      bought: false,
      responseTime: 0,
    };
    this.buyPack = this.buyPack.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.handleNoPacksChange = this.handleNoPacksChange.bind(this);
  }

  componentDidMount() {
    let availableGamma = Math.min(
      this.props.walletCurrency.unitsQNT,
      this.props.walletCurrency.unconfirmedUnitsQNT
    );
    let max = Math.min(availableGamma / PACKPRICEGIFTZ, MAXPACKS);
    //let min = 1;
    if (max < 1) {
      this.setState({ noPacks: this.state.noPacks }, () => {
        let fieldStatus = {
          invalid: true,
          error: "Not enough GIFTZ to buy a pack",
        };
        this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
      });
    }
    this.validateForm();
  }

  buyPack() {
    var self = this;

    if(!self.state.formValid){
      self.setState({submitStatus:{invalid:true, error:"Error submitting, please check your inputs"}});
      return;
    }
    else {
      self.setState({submitStatus:{invalid:false, error:""}});
    }

    let phraseValidated = validatePassPhrase(
      self.state.passPhrase,
      self.state.passPhraseStatus,
      self.props.user.accountRs
    );
    if (phraseValidated.invalid) {
      console.log("buyPack(): passphrase invalid, exiting.");
      self.setState({ passPhraseStatus: phraseValidated }, this.validateForm);
      return;
    }

    console.log("buyPack(): passphrase ok.");
    const message = JSON.stringify({ contract: "IgnisAssetLottery" });
    let unitsQNT = this.state.noPacks * self.packPriceGiftz;
    if (this.props.wallet.balanceNQT < 0.1 * NQTDIVIDER) {
      transferCurrencyZeroFee(
        this.props.nodeurl,
        CURRENCY,
        unitsQNT,
        BUYPACKACCOUNT,
        this.state.passPhrase,
        message,
        true
      )
        .then(function(response) {
          console.log(response);
          self.setState({
            response: response,
            responseTime: response.data.requestProcessingTime,
            bought: true,
            status: "success",
          });
        })
        .catch(function(error) {
          console.log("ohje transferCurrencyZeroFee (from buyPack Dialog):");
          console.log(error.message);
          self.setState({ status: "ERROR" });
        });
    } else {
      transferCurrency(
        this.props.nodeurl,
        CURRENCY,
        unitsQNT,
        BUYPACKACCOUNT,
        this.state.passPhrase,
        message,
        true
      )
        .then(function(response) {
          console.log(response);
          self.setState({
            response: response,
            responseTime: response.data.requestProcessingTime,
            bought: true,
            status: "success",
          });
        })
        .catch(function(error) {
          console.log("ohje transferCurrency (from buyPack Dialog):");
          console.log(error.message);
          self.setState({ status: "ERROR" });
        });
    }
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.noPacksStatus.invalid === false
    });
  }

  handleCurrencyChange(changeEvent) {
    this.setState({
      selectedCurrency: changeEvent.target.value,
    });
  }

  handleNoPacksChange(event) {
    let value = event.target.value;
    let availableGamma = Math.min(
      this.props.walletCurrency.unitsQNT,
      this.props.walletCurrency.unconfirmedUnitsQNT
    );
    let max = Math.min(availableGamma / PACKPRICEGIFTZ, MAXPACKS);
    let min = 1;
    if (max >= 1) {
      this.setState({ noPacks: value }, () => {
        let fieldStatus = validateQuantity(
          value,
          max,
          min,
          this.state.noPacksStatus
        );
        this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
      });
    } else {
      this.setState({ noPacks: value }, () => {
        let fieldStatus = {
          invalid: true,
          error: "Not enough GIFTZ to buy a pack",
        };
        this.setState({ noPacksStatus: fieldStatus }, this.validateForm);
      });
    }
    this.setState({ noPacks: event.target.value });
  }

  handlePassphraseChange(event) {
    //let value = event.target.value;
    let value = event;
    this.setState({ passPhrase: value }, () => {
      let fieldStatus = validatePassPhrase(value, this.state.passPhraseStatus);
      this.setState({ passPhraseStatus: fieldStatus }, this.validateForm);
    });
  }

  toggler(props) {
    //this.setState({bought:false});
    this.props.toggle(!this.props.modalOpen);
  }

  render() {
    let totalPrice = this.state.noPacks * this.packPriceGiftz;
    if (this.state.bought) {
      return <TxSuccess />;
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            padding: 5,
            width: "90%",
            maxWidth: "600px",
            display: "inline-block",
            paddingTop: 40,
          }}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.buyPack();
            }}
          >
            <Grid
              container
              justify="center"
              alignItems="stretch"
              direction="column"
              spacing={24}
              className="boxed"
            >
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="display2">Buy a pack of cards</Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h6">Pay with GIFTZ</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Number of packs"
                  value={this.state.noPacks}
                  onChange={(event) => this.handleNoPacksChange(event)}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  error={this.state.noPacksStatus.invalid}
                  helperText={this.state.noPacksStatus.error}
                />
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body1">
                  Total: {totalPrice} GIFTZ
                </Typography>
              </Grid>
              <Grid item>
                <DangerWarning active={this.state.submitStatus.invalid}>
                  {this.state.submitStatus.error}
                </DangerWarning>
              </Grid>
              <Grid item>
                <SignActionField
                  {...this.props}
                  {...this.state}
                  gameaction="buypack" 
                  handlePassphraseChange={this.handlePassphraseChange}
                  action={this.buyPack}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      );
    }
  }
}
