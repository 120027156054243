import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { fetchCard } from '../common/common';
import { TARASCACARDASSET } from '../common/constants';
import { Ledger } from './ledger';



export class TarascaPage extends Component {
    constructor(props){
      super(props);
      this.state = {
        card:{quantityQNT:0},
        selectedChannel:"all",
        gotTarasca:false
      }
      this.refresh = this.refresh.bind(this); 
    }
  
    refresh(){
      var self = this;
      console.log("Tarasca: refresh");
      fetchCard(this.props.nodeurl,this.props.user.accountRs,TARASCACARDASSET)
      .then(function(response){
        self.setState({card:response});
        let gotTarasca = response.quantityQNT > 0;
        self.setState({gotTarasca:gotTarasca})        
      })
      .catch(function(error) {console.log(error)});
    }
  
    componentDidMount(){
      this.refresh()
      this.timer = setInterval(this.refresh, 12000)
    }

    componentWillUnmount(){
        console.log("Tarasca: stop refresh");
        clearInterval(this.timer);    
    }

    render(){
        return(
            <div style={{textAlign:"center", padding:20, width:"90%", display:"inline-block"}}>  
              
                <Grid container spacing={24}
                        justify="center"
                        alignItems="stretch"
                        direction="column">
                 
                    <Grid item>
                        <Ledger nodeurl={this.props.nodeurl} user={this.props.user} epoch_beginning={this.props.epoch_beginning}/>
                    </Grid>                 
                </Grid>
                       
            </div>
        )
    }
}



export const NoTarascaCard = (props) => {
    return (
        <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block"}}>
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={24}
                style={{maxWidth:540}}
            >
                <Grid item>
                    <Typography variant="body2">
                        You don't posess a Tarasca Card.
                    </Typography>
                    <Typography variant="body2">
                        This is a special card that you receive when you participated in the jackpot. It is very rare! 
                        Alternatively you can bid for one on the <Link style={{color:"inherit"}} to="/game/exchange">Exchange</Link>.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}


export const TarascaCard = (props) => {
    return (
        <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block"}}>
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={24}
                style={{maxWidth:540}}
            >
                <Grid item>
                    <Typography variant="body2">
                        You posess a Tarasca Card! It is a very rare card, and every holder is receiving a share of the card sales (10% of Pack Sales are distributed to all Tarasca Cards every week).
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
/*
class DividendData extends Component {
    constructor(props) {
        super(props);
        this.state={
          dividends:[]
        }
        this.refresh = this.refresh.bind(this);
    }

    refresh(){
        var self = this;
        // TODO get dividends from chain
        getAssetDividends(self.props.nodeurl,2,TARASCACARDASSET)
        .then((response) => {
            //console.log(response);
            if (response !== undefined) {
                self.setState({dividends:response.dividends});
            }
        })
        .catch(function(error) {console.log(error)});
      }
    
      componentDidMount(){
        this.refresh();
        this.timer = setInterval(this.refresh,30000);
      }
    
      componentWillUnmount(){
        clearInterval(this.timer);
      }
    
      render() {
          const num_tx = this.state.dividends.length;
          let last_pay_total = 0;
          let last_pay_share = 0;
          let last_num_accounts = 0;
          let total_payout = 0;
          
          if (num_tx >0){
            last_pay_total = this.state.dividends[0].totalDividend;
            last_pay_share = this.state.dividends[0].amountNQTPerShare;
            last_num_accounts = this.state.dividends[0].numberOfAccounts;
            
            total_payout = this.state.dividends.reduce(function (accumulator, currentValue) {
                return parseInt(accumulator) + parseInt(currentValue.totalDividend);
              },0);
          }
          return(              
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={8}                
            >
                <Grid item>
                     <Typography variant={'h6'}>Dividend history</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'body1'}>Last payment</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'body1'}>Total {last_pay_total/NQTDIVIDER} Ignis • Per share: {last_pay_share/NQTDIVIDER} Ignis</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'body1'}>Payout to {last_num_accounts} accounts</Typography>
                </Grid>
                <Grid item><Typography variant={'body1'}>&nbsp;</Typography></Grid>
                <Grid item>
                    <Typography variant={'body1'}>Total payouts</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'body1'}>{(total_payout/NQTDIVIDER).toFixed(2)} Ignis  • {num_tx} distributions</Typography>
                </Grid>
            </Grid>
            )
      }
}


class ExchangeData extends Component {
    constructor(props) {
      super(props);
      this.state={
        modalSend:false,
        modalTrade:false,
        bidOrders:[],
        askOrders:[]
      }
      this.refresh = this.refresh.bind(this);
    }
  
    refresh(){
      var self = this;
      getAskOrders(self.props.nodeurl,TARASCACARDASSET)
      .then((response) => {
        self.setState({askOrders:response.askOrders});
      });
      getBidOrders(self.props.nodeurl,TARASCACARDASSET)
      .then((response) => {
        self.setState({bidOrders:response.bidOrders});
      });
    }
  
    componentDidMount(){
      this.refresh();
      this.timer = setInterval(this.refresh,5000);
    }
  
    componentWillUnmount(){
      clearInterval(this.timer);
    }
  
    render() {
        //const numColumnStyle = { width: 12};
        const ask = this.state.askOrders.length === 0 ? "/" 
                                : this.state.askOrders[0].priceNQTPerShare/NQTDIVIDER;
        const bid = this.state.bidOrders.length === 0 ? "/" 
                                : this.state.bidOrders[0].priceNQTPerShare/NQTDIVIDER;
        //const quant = this.props.card.quantityQNT > this.props.card.unconfirmedQuantityQNT ? this.props.card.quantityQNT 
        //                        : this.props.card.unconfirmedQuantityQNT;
        return (
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={24}
                >

                <Grid item>
                    <Typography variant='h4'>
                        Current ASK 
                    </Typography>
                    <Typography variant='h6'>
                        {ask} • IGNIS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h4'>
                        Current BID
                    </Typography>
                    <Typography variant='h6'>
                        {bid} • IGNIS
                    </Typography>
                </Grid>
            </Grid>
        );
    }
  }
*/