// landing for a new user.

import React from 'react';
import {ShowQrCode} from './showqrcode';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import BuildArdor from '../common/images/built-on-ardor-blockchain.png'
import Welcome from '../common/images/welcome.png'


export const NewUserLanding = (props) => {
    
    return (
        <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block"}}>
            <Grid container
                justify="center"
                direction="column"
                alignItems="stretch"
                spacing={24}
                style={{maxWidth:540}}
            >
                <Grid item>


                    <Typography> <img src={Welcome} alt="Mythical Beings" />   </Typography>
                    <Typography>&nbsp;</Typography>
                    <Typography variant="display1">
                        Welcome to Mythical Beings
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        You seem to have no funds nor cards yet.
                    </Typography>
                    <Typography variant="body2">
                        Fund your account using: <br></br>
                         <Link style={{color:"inherit"}} to="/game/fundaccount">1) FIAT or CRYPTO</Link>
            
                    </Typography>
                    <Typography variant="body2">
                        2) or let some friends send you some IGNIS, they need to scan your address:
                    </Typography>
                </Grid>
                
                <Grid item>
                    <ShowQrCode {...props}/>    
                </Grid>        

                <Grid item>
                <img src={BuildArdor} alt="Build on Ardor Blockchain" />    
                </Grid>        



            </Grid>
        </div>
    )
}