// tradehistory

import React, { Fragment } from 'react';
import {CSSTransitionGroup} from 'react-transition-group';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';

import '../common/txhistory.css';
import {parseAccount} from '../common/txhistory';
import { NQTDIVIDER, GEMASSET} from '../common/constants';

import { FiMinusCircle } from 'react-icons/fi';
import { FiPlusCircle } from 'react-icons/fi';
import { GiTwoCoins } from 'react-icons/gi';

import Divider from '@material-ui/core/Divider';
import Gem from './images/gems.svg';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1.5,
          width: '70%'
      }}
  />
);



function TimeStamp({tx,eb}){
  var txstamp = new Date(eb.getTime());
  txstamp.setSeconds(+txstamp.getSeconds()+tx.timestamp);
  
  const Month = txstamp.getMonth()+1;
  const datestring = txstamp.getFullYear().toString()+"-"+ Month.toString().padStart(2,"0") + "-"+txstamp.getDate().toString().padStart(2,"0");
  const timestring = txstamp.getHours().toString().padStart(2,"0") + ":" + txstamp.getMinutes().toString().padStart(2,"0")+ ":" + txstamp.getSeconds().toString().padStart(2,"0");


  return(
    <Fragment>
      <Typography> {datestring} {timestring}</Typography>
    </Fragment>
  )
}



function IncomingTrade({trade,card,eb,badge}){
  return (
   
    <div style={{textAlign:"center", width:"90%", display:"inline-block",   justifyContent:'center', alignItems:'center'}}>
<Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          {(badge===true) ? (
            <Badge color="secondary">
              <Typography variant="h6" align="left"><FiPlusCircle style={{ color: '#228C22' }}/> {card.name} </Typography>                
            </Badge>
          ):(
              <Typography variant="h6" align="left"><FiPlusCircle style={{ color: '#228C22' }}/> {card.name}</Typography>                
          )}          
        </Grid>
        <Grid item xs={4}>
           <img src={card.cardImgUrl} alt="card img" width="100px" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1', 
               
              }}/>   
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
          <Typography><GiTwoCoins style={{ color: '#FFD700' }}/> - {(trade.priceNQTPerShare/NQTDIVIDER)*trade.quantityQNT} IGNIS</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={trade} eb={eb}/>
          </Grid>
          <Grid item>
            <Typography>Quantity • {trade.quantityQNT}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Rarity • {card.rarity}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Seller • {parseAccount(trade.sellerRS)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
</div>
  )
}

function IncomingTradeGEM({trade,card,eb,badge}){
  return (
   
    <div style={{textAlign:"center", width:"90%", display:"inline-block",   justifyContent:'center', alignItems:'center'}}>
<Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          {(badge===true) ? (
            <Badge color="secondary">
              <Typography variant="h6" align="left"><FiPlusCircle style={{ color: '#228C22' }}/> {card.name} </Typography>                
            </Badge>
          ):(
              <Typography variant="h6" align="left"><FiPlusCircle style={{ color: '#228C22' }}/> {card.name}</Typography>                
          )}          
        </Grid>
        <Grid item xs={4}>
        <img src={Gem} alt="Gem" width="50px" />
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
          <Grid item>
          <Typography><GiTwoCoins style={{ color: '#FFD700' }}/> - {(trade.priceNQTPerShare/NQTDIVIDER)*trade.quantityQNT} IGNIS</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={trade} eb={eb}/>
          </Grid>
          <Grid item>
            <Typography>Amount • {trade.quantityQNT}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Currency • {card.rarity}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Seller • {parseAccount(trade.sellerRS)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
</div>
  )
}

function OutgoingTrade({trade,card,eb,badge}){
  //console.log(card);
  return (
    
<div style={{textAlign:"center", width:"90%", display:"inline-block",   justifyContent:'center', alignItems:'center'}}>
<Grid container
      justify="flex-start"
      alignItems="center"
      direction="row"
      spacing={8}>
        <Grid item xs={12}>
          {(badge===true) ? (
            <Badge color="secondary">
              <Typography variant="h6" align="left"><FiMinusCircle style={{ color: '#9E1A1A' }}/> {card.name}</Typography>                
            </Badge>
          ):(
              <Typography variant="h6" align="left"><FiMinusCircle style={{ color: '#9E1A1A' }}/> {card.name} </Typography>                
          )}          
        </Grid>
        <Grid item xs={4}>
              <img src={card.cardImgUrl} alt="card img" width="100px" 
              style={{
                borderStyle: 'solid',
                borderWidth: 3,
                borderImage: card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'special' ? 'linear-gradient(60deg, #53AF53, #2D5B53) 1' : card.rarity === 'very rare' ? 'linear-gradient(45deg, #F09819, #EDDE5D) 1': card.rarity === 'rare' ? 'linear-gradient(45deg, #2F80ED, #56CCF2) 1' : 'linear-gradient(45deg, #8e9eab, #eef2f3) 1', 
               
              }}/>                  
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start" spacing={8}>
        <Grid item>
          <Typography><GiTwoCoins style={{ color: '#FFD700' }}/> + {(trade.priceNQTPerShare/NQTDIVIDER)*trade.quantityQNT} IGNIS</Typography>
          </Grid>
          <Grid item> 
            <TimeStamp tx={trade} eb={eb}/>
          </Grid>
          <Grid item>
            <Typography>Quantity • {trade.quantityQNT}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Rarity • {card.rarity}</Typography>
          </Grid>
          <Grid item>            
            <Typography align="left">Buyer • {parseAccount(trade.buyerRS)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
</div>

  )
}


export function TradesHistory (props) {
  const displayed = props.trades.slice(0,30);
  //console.log(props);
  const trades = displayed.map((trade,index) => {
    const badge = trade.timestamp > props.lastUpdate.timestampTrades;
    //console.log("current trade:"+trade.timestamp+" storedTimestamp:"+props.lastUpdate.timestampTrades+" badge:"+badge);
    
    const card = props.collectionCardsStatic.find((card)=>(card.asset === trade.asset))
    if (card) {
      if (trade.sellerRS === props.user.accountRs){
        return (
          <div className="transaction" key={trade.askOrderFullHash.toString()+trade.bidOrderFullHash.toString()}>
            <OutgoingTrade trade={trade} card={card} eb={props.epoch_beginning} badge={badge}/>
          </div>
        )  
      }
      else {
        return (
          <div className="transaction" key={trade.askOrderFullHash.toString()+trade.bidOrderFullHash.toString()}>
            <IncomingTrade trade={trade} card={card} eb={props.epoch_beginning} badge={badge}/>
          </div>
        )  
      }
    }

    //new added, not working (18.12.2023)
    else if (card) {
      if (trade.asset === GEMASSET){
      <div className="transaction" key={trade.askOrderFullHash.toString()+trade.bidOrderFullHash.toString()}>
      <IncomingTradeGEM trade={trade} card={GEMASSET} eb={props.epoch_beginning} badge={badge}/>
    </div>
      }
    }
    else return null;
  });

  return (
    <div style={{textAlign:"center",  padding:5, width:"90%", display:"inline-block",   justifyContent:'center', alignItems:'center'}}>

    <Grid container
      justify="center"
      alignItems="stretch"
      direction="column"
      spacing={8}
    >
    <Typography>&nbsp;</Typography>  
    <Typography variant="h3" style={{marginTop:10}}>YOUR TRADES</Typography>
    <ColoredLine color="white"/>
    <Grid item >
          <Grid container
            justify="center"
            alignItems="flex-start"
            direction="row"
            spacing={0}
          >

   

            <Grid item className="boxed" style={{backgroundColor: '#1D1D1D', marginTop: 1, border:'1px solid', borderColor:'#ffffff10',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}>
    <CSSTransitionGroup
      transitionName="example"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}
    >
                

      {trades}
    </CSSTransitionGroup>
        </Grid>
        </Grid>
        </Grid>
      </Grid>

  </div>
  )    
}
