
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {getAccountAssets, transferAsset} from '../common/ardorinterface';
import {validateAddress,validatePassPhrase} from '../common/validators';

import {SignActionField} from '../common/signactionfield';
import {QrAccountField} from '../common/accountfield';
import { checkWhitelistFromProperty, InvitedWarning, InvitePossible } from '../common/common';
import { Typography } from '@material-ui/core';
import { REFERRALASSET, REFERRALCONTRACTACCOUNT } from '../common/constants';
import Spinner from 'react-bootstrap/Spinner';
import {Link} from 'react-router-dom';


export class SubmitRefs extends Component {
  constructor (props){
    //console.log(props);
    super(props);
    this.state = {
      asset:{},
      noCards:1,
      noCardsStatus:{invalid:false,error:''},
      passPhrase:"",
      passPhraseStatus:{invalid:undefined,error:''},
      referralRS:"",
      referralRsStatus:{invalid:undefined,error:''},
      AccountAlreadyInvited:false,
      AccountCanBeInvited:false,
      displayQrReader:false
    };
    this.sendAsset = this.sendAsset.bind(this);
    this.handleReferralRsChange = this.handleReferralRsChange.bind(this);
    this.handlePassphraseChange = this.handlePassphraseChange.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  refresh(){
    var self = this;
    getAccountAssets(self.props.nodeurl,self.props.user.accountRs,REFERRALASSET)
    .then(function(response){
      if (Object.keys(response).length===0) {//empty object -> asset not in account
          self.setState({asset:{quantityQNT:0,unconfirmedQuantityQNT:0}});
      }
      else {
          self.setState({asset:response});
      }
    });
  }

  componentDidMount(){
    this.refresh();
    this.timer = setInterval(this.refresh,9000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }


  sendAsset(event) {
      var self = this;
      console.log(self);
      const message = JSON.stringify({contract:"ReferralsSimple",invitedAccount:self.state.referralRS});
      //goodMessage.put("contract","ReferralsSimple");
      //goodMessage.put("invitedAccount",CHUCK.getRsAccount());
      let phraseValidated = validatePassPhrase(self.state.passPhrase,self.state.passPhraseStatus,self.props.user.accountRs);
      if  (phraseValidated.invalid){
        console.log("sendAsset(): passphrase invalid, exiting.")
        self.setState({passPhraseStatus:phraseValidated},this.validateForm);
        return;
      }
      console.log("sendAsset(): passphrase ok.")
      console.log('send '+self.state.noCards+' cards.');
      console.log('get publicKey');
      
      transferAsset(self.props.nodeurl,self.state.asset.asset,self.state.noCards,REFERRALCONTRACTACCOUNT,self.state.passPhrase,message,true)
      .then(function(response) {
        self.setState({response:response,responseTime:response.data.requestProcessingTime,bought:true,status:"success"});
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  validateForm() {
    this.setState({formValid: (this.state.passPhraseStatus.invalid===false) && (this.state.noCardsStatus.invalid===false) && (this.state.referralRsStatus.invalid===false)});
  }

  handlePassphraseChange(event){
    let value = event;
    this.setState(
      {passPhrase:value},
      ()=>{let fieldStatus = validatePassPhrase(value,this.state.passPhraseStatus);
            this.setState({passPhraseStatus:fieldStatus},this.validateForm);}
    );
  }


  handleReferralRsChange(value){
      var self = this;  
      //let value = event.target.value;
      //console.log(this.state,value);
      const addressValid = validateAddress(value,this.state.referralRsStatus);
      if (addressValid.invalid === false){
        checkWhitelistFromProperty(this.props.nodeurl,value)
        .then((response)=>{
          //console.log("property check for address: ",response);
          if (response.invited) {
            self.setState({AccountCanBeInvited:false,AccountAlreadyInvited:response.invited ? response.invited : false});
          }
          else {
            self.setState({AccountCanBeInvited:true,AccountAlreadyInvited:false});
          }          
        })
      }
      else {
        self.setState({AccountAlreadyInvited:false,AccountCanBeInvited:false});
      }
      this.setState(
          {referralRS:value},
          ()=>{let fieldStatus = validateAddress(value,this.state.referralRsStatus);
              this.setState({referralRsStatus:fieldStatus},this.validateForm);}
      );
  }

//   toggler(props){
//     this.setState({bought:false});
//     this.toggle(!this.props.modalOpen);
//   }

  render(){
    //console.log(this.state);
    const output = this.state.bought ? (
    //const output = true ? (   
              
                <Grid item xs={12}>             
                  <Spinner animation="border" role="status" variant="light">
                  
                  </Spinner>
                  <Typography variant="body1">Sending, please be patient. Use the back button to return. <Link to="/game/settings">Check User Info</Link> </Typography>
                  <Typography variant="caption">
                    Balances and invitations take a few minutes to update. 
                    
                  </Typography>

                </Grid>
            ):(
                <form onSubmit={(event)=>{event.preventDefault();this.sendAsset()}}>
                    <Grid container
                    justify="center"
                    alignItems="stretch"
                    direction="column" 
                    spacing={24} 
                    >
                    <Grid item>
                        <QrAccountField value={this.state.referralRS} 
                                        status={this.state.referralRsStatus} 
                                        onChange={this.handleReferralRsChange}>
                        Invite this account
                        </QrAccountField>             
                    </Grid>
                    <Grid item>
                        <InvitedWarning isInvited={this.state.AccountAlreadyInvited}/>
                        <InvitePossible isPossible={this.state.AccountCanBeInvited && !this.state.AccountAlreadyInvited}/>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">NOTE: Tarasca Card holders do not need to be invited. It is not possible to activate your provision for a Tarasca Card owner.</Typography>
                    </Grid>
                    <Grid item>
                        <SignActionField {...this.props} {...this.state} gameaction="submitrefs"
                                        action={this.sendAsset} 
                                        handlePassphraseChange={this.handlePassphraseChange}
                                        />
                    </Grid>      
                    </Grid>
                </form>
            )                
    return(
          output              
    )
  }
}

