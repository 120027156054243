import React, { useState } from 'react';

import { processUnwrapsForAccount } from '../common/gameinterface';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Spinner from 'react-bootstrap/Spinner';

import { useSnackbar } from 'notistack';

export const BridgeToArdorDisplay = ({user,ethDepositAddress}) => {
  //console.log(props);
  const [sending, setSending] = useState({ inProgress: false, error: "" });
  //const [starts, setStarts] = useState({ started: 0, message: "" });
  const { enqueueSnackbar } = useSnackbar();

  const submit = async () => {
    console.log("1, set sending..");
    setSending({ inProgress: true, error: "" });
    console.log("2, sending with params:");
    console.log(user.accountRs);
    let response = {};
    try {
      response = await processUnwrapsForAccount(user.accountRs);
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 500)
      );
      console.log(response);
    }
    catch (exception) {
      console.log(exception);
      setSending({ inProgress: false, error: "error submitting your request" });
      return false;
    }
    console.log("3, submit done");
    setSending({ inProgress: false, error: "" });
    if (response.data && response.data.starts){
        const message = response.data.starts + " transfers started.";
        enqueueSnackbar(message, { 
            variant: 'success',
        });
    }
    else if(response.data && response.data.requestProcessingTime) {
        const message = "No transfer started.";
        enqueueSnackbar(message, { 
            variant: 'error',
        });
    }

  };

  return(
    <div style={{textAlign:"center", padding:20, width:"90%", maxWidth:"540px", display:"inline-block", paddingTop:40, paddingBottom:40}}>
        <Grid container
            justify="center"
            alignItems="stretch"
            direction="column"
            spacing={24}
            className="boxed"
            style={{backgroundColor: '#1D1D1D', marginTop: 5, border:'0px solid', borderColor:'#ffffff3b',boxShadow: "5px 5px 8px rgba(5, 5, 5, 0.32)"}}
        >
            <Grid item>
                <Typography variant="h5">Import Cards to Mythical Beings</Typography>                
                
            </Grid>
            <Grid item>
                    
                <Typography variant="h6" align="left">
                    1. In Opensea: Send the Assets to your personal deposit address:
                </Typography>
                  <TextField
                      disabled
                      id="game"
                      fullWidth
                      label="Ethereum Deposit Address"
                      helperText=""
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        readOnly: true,
                        shrink: true,
                      }}
                      value={ethDepositAddress}
                    />

                
            </Grid>
            <Grid item>
                <Typography variant="h6" align="left">
                    2. Wait until transaction is confirmed on the Polygon network
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6" align="left">
                    3. Initiate swap:
                </Typography>

                {sending.inProgress && 
                    (<>
                        <Spinner animation="border" role="status" variant="light">
                        <span className="sr-only">Transferring..</span>
                        </Spinner>
                        <Typography variant="body2">Transferring assets</Typography>
                    </>)
                }

                <Typography variant="body2" align='center'>
                    <Button variant="outlined" 
                        disabled={sending.inProgress} 
                        onClick={(event)=>submit(event)}
                    >
                      Initiate Swap
                    </Button>
                </Typography>
            </Grid>
        </Grid> 
    </div>
  );
}
