// countdown

import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {getBlockchainStatus} from '../common/ardorinterface';
import {FREQUENCY, BLOCKTIME} from '../common/constants';


const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 0.1,
            width: "90%",
            opacity:"30%"
        }}
    />
  );
  


export class CountDown extends Component{
    constructor(props){
        super(props);
        this.state = {
            blockChainStatus:{numberOfBlocks:FREQUENCY},
            timer:BLOCKTIME,
            days: undefined,
            hours: undefined,
            minutes: undefined,
            seconds: undefined
        }
        this.refresh=this.refresh.bind(this);
    }

    componentDidMount(){
        var self = this;
        self.refresh();
        console.log("CountDown: start refresh");
        self.timer = setInterval(self.refresh,1000);  
        this.interval = setInterval(() => {
            const modulo = this.state.blockChainStatus.numberOfBlocks%FREQUENCY;
            //console.log('modulo: '+modulo);
            const remainingBlocks = FREQUENCY - modulo;
            //console.log('remainingBlocks: '+remainingBlocks);
            const remainingSecs = remainingBlocks * BLOCKTIME;
            //console.log('remainingSecs: '+remainingSecs);


            const delta = remainingSecs-(BLOCKTIME - this.state.timer); 
            //console.log('delta: '+delta);


            const countdown = Number(delta);
            //console.log('countdown: '+countdown);
            const days = Math.floor(countdown / (3600*24));
            //console.log('days: '+days);
            const hours = Math.floor(countdown % (3600*24) / 3600);
            //console.log('hours: '+hours);
            const minutes = Math.floor(countdown % 3600 / 60);
            //console.log('minutes: '+minutes);
            const seconds = Math.floor(countdown % 60);     
            //console.log('seconds: '+seconds);
            
            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount(){
        console.log("CountDown: stop refresh");
        clearInterval(this.timer);
        clearInterval(this.timerTimer);
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    refresh(){
        var self = this;

        let prev_timer = self.state.timer;
        self.setState({timer:prev_timer-1});

        getBlockchainStatus(this.props.nodeurl)
        .then((response)=>{
            let prev_height = self.state.blockChainStatus.numberOfBlocks;
            if (prev_height !== response.data.numberOfBlocks) {
                self.setState({timer:BLOCKTIME})                
            }
            self.setState({blockChainStatus:response.data});
        })
        .catch((error)=>{console.log(error);})

    }

    

    render(){ 

        const modulo = this.state.blockChainStatus.numberOfBlocks%FREQUENCY;
        //console.log('modulo: '+modulo);
        const remainingBlocks = FREQUENCY - modulo;
        //console.log('remainingBlocks: '+remainingBlocks);
        const remainingSecs = remainingBlocks * BLOCKTIME;
        //console.log('remainingSecs: '+remainingSecs);


        const delta = remainingSecs-(BLOCKTIME - this.state.timer); 
        //console.log('delta: '+delta);


        const countdown = Number(delta);

        const days = Math.floor(countdown / (3600*24));

        const hours = Math.floor(countdown % (3600*24) / 3600);

        const minutes = Math.floor(countdown % 3600 / 60);

        const seconds = Math.floor(countdown % 60);     

        


        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

        var date = new Date(null);
        date.setSeconds(delta); // specify value for SECONDS here
        //var result = date.toISOString().substr(11, 8);

        return (
            

            

            <Grid container spacing={8}
                justify="center"
                alignItems="center"
                direction="column"
                className="boxed"
                style={{border:'0px solid', borderColor:'#ffffff3b'}}
            >
                <Grid container direction="row" justify="space-between" alignItems="center">
               
                <Grid item xs={12}>
                <div>
                <Typography><br></br></Typography>
                <div className="countdown-wrapper">
                    {days && (
                        <div className="countdown-item">
                            <SVGCircle radius={daysRadius} />
                            {days}
                            <span>days</span>
                        </div>
                    )}
                    {hours && (
                        <div className="countdown-item">
                            <SVGCircle radius={hoursRadius} />
                            {hours}
                            <span>hours</span>
                        </div>
                    )}
                    {minutes && (
                        <div className="countdown-item">
                            <SVGCircle radius={minutesRadius} />
                            {minutes}
                            <span>minutes</span>
                        </div>
                    )}
                    {seconds && (
                        <div className="countdown-item">
                            <SVGCircle radius={secondsRadius} />
                            {seconds}
                            <span>seconds</span>
                        </div>
                    )}
                   
                </div>

            </div> 

                </Grid>
                <ColoredLine color="white" />
                <Grid item xs={3}>       
                    <Typography variant="overline" style={{textAlign:'center'}}>Remaining Blocks:<Typography variant="caption">{remainingBlocks}</Typography></Typography>

                </Grid>

                <Grid item xs={3}> 
                    <Typography variant="overline" style={{textAlign:'center'}}>Next Block in:<Typography variant="caption">{this.state.timer} secs</Typography></Typography> 

                </Grid>

                <Grid item xs={3}> 
                    <Typography variant="overline" style={{textAlign:'center'}}>Jackpot Block: <Typography variant="caption">{this.state.blockChainStatus.numberOfBlocks+remainingBlocks}</Typography></Typography>
                    
                </Grid>

                <Grid item xs={3}> 
                    <Typography variant="overline" style={{textAlign:'center'}}>Current Block: <Typography variant="caption">{this.state.blockChainStatus.numberOfBlocks}</Typography></Typography>
                </Grid>
            </Grid><br></br>






            </Grid>
        )
    }
}

const SVGCircle = ({ radius }) => (
    <svg className="countdown-svg">
        <path
            fill="none"
            stroke="#fff"
            strokeWidth="4"
            d={describeArc(50, 50, 48, 0, radius)}
        />
    </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

