import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { getAskOrders } from '../common/ardorinterface';
import { GoPrimitiveDot } from "react-icons/go";
import { NQTDIVIDER } from './constants';
import { round } from '../common/common';


const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  image: {
    height:450,
    width:300,
    display:"block",
    zIndex:-1,
    borderRadius: 6,
    boxShadow: '4px 4px 3px rgba(5, 5, 5, 0.32)',
  },
  container:{
    position:"relative",
    maxWidth:300,
    height:450,
    width:300,
  },
  overlay: {
    opacity: 0.9,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    position:"absolute",
    zIndex:1000,
    backgroundColor:"black",
    padding: "150px 0"
  },
  media: {
    height: 450,
    width:300,
  },
  card: {
    maxWidth:300,
  }
});


// cardinfo
function CardImage_({card, classes}) {
  const posess = card.quantityQNT > 0;
  if (posess)
    {
      return (<CardMedia className={classes.image} image={card.cardImgUrl} title={card.name}/>)
    }
  else {
    return (
      <div className={classes.container}>
        <CardMedia className={classes.image} image={card.cardImgUrl} title={card.name}/>
        <div className={classes.overlay}>
          <Typography>You don't own this card</Typography>
          <Link to={"/exchange/card/"+card.asset}>
            <Button variant="outlined">Look for Offers</Button>
          </Link>
        </div>
      </div>
    )
  }
}
export const CardImage = withStyles(styles)(CardImage_);


function CardImageNoButton_({card, classes}) {
  const posess = card.quantityQNT > 0;
  if (posess)
    {
      return (<CardMedia className={classes.image} image={card.cardImgUrl} title={card.name}/>)
    }
  else {
    return (
      <div className={classes.container}>
        <CardMedia className={classes.image} image={card.cardImgUrl} title={card.name}/>
        <div className={classes.overlay}>
          <Typography>You don't own this card</Typography>
        </div>
      </div>
    )
  }
}
export const CardImageNoButton = withStyles(styles)(CardImageNoButton_);



export function CardInfo({card}) {
  return(
    <Fragment>
         <Typography gutterBottom style={{color: card.rarity === 'very rare' ? 'gold' : card.rarity === 'rare' ? '#009ad1' : 'grey'}}>
           <GoPrimitiveDot style={{fontSize:'24'}}/>
         </Typography>
          <Typography variant="h5" gutterBottom>{card.name} </Typography>
          <Typography variant="body2" gutterBottom>{card.channel} • {card.rarity === 'very rare' ? 'epic' : card.rarity}</Typography>
          <Typography variant="body2" gutterBottom>Quantity • {card.quantityQNT}</Typography>
          <Typography variant="body2" gutterBottom>AssetID • {card.asset}</Typography>
    </Fragment>
  )
}

export function CurrencyInfo({card}) {
  return(
    <Fragment>
       
          <Typography variant="h5" gutterBottom>GEM </Typography>
         
          <Typography variant="body2" gutterBottom>Amount • {round(Math.min(card.quantityQNT/NQTDIVIDER),2)}</Typography>
          <Typography variant="body2" gutterBottom>AssetID • {card.asset}</Typography>
    </Fragment>
  )
}


export function CardInfoLearn({card}) {
  return(
    <Fragment>
        <Typography variant="h4">{card.name}</Typography>
        <Typography variant="body1">{card.channel}</Typography>
        <Typography>Rarity : {card.rarity}</Typography>
        <Typography>AssetID : {card.asset}</Typography>
        <Typography>Amount : {card.quantityQNT}</Typography>
    </Fragment>
  )
}




export function CardInfoSlim({card}) {
  const cardAvailableBalance = Math.min(Number(card.quantityQNT), Number(card.unconfirmedQuantityQNT));
  return(
    <Fragment>
      <Typography variant="h6">{card.name}</Typography>
      <Typography variant="body1">Continent : {card.channel}</Typography>
      <Typography>Rarity : {card.rarity}</Typography>
      <Typography>Quantity : {card.quantityQNT} ({cardAvailableBalance})</Typography>
    </Fragment>
  )
}




export function CardInfoSlimJackpot({card}) {

  const ask = getAskOrders.length === 0 ? "/" 
                      : getAskOrders[0];
  return(
    <Fragment>
      <Typography style={{ marginLeft:160}} align="justify" variant="h6">{card.name}</Typography>
      <Typography style={{ marginLeft:160}} align="justify" variant="body1">Continent • {card.channel}</Typography>
      <Typography style={{ marginLeft:160}} align="justify">Rarity • {card.rarity === "very rare" ? "epic" : card.rarity}</Typography>
      <Typography style={{ marginLeft:160, marginBottom:5}} align="justify">Asset ID • {card.asset} &nbsp; &nbsp;</Typography>
      <Link to={"/game/place/bid/"+card.asset}><Button variant="outlined">Buy{ask}</Button></Link>
      
    </Fragment>
  )
}